import React, { useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { Container } from "react-bootstrap";

import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ContactFooter } from "Footer/Contact";
import "./Root.css";

export const IndexPage = ({ route }) => {
  const location = useLocation();
  const isHome = location.pathname === "/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  return (
    <div
      className="root-container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Header />
      <Container fluid={"sm"} className="py-2">
        {renderRoutes(route.routes)}
      </Container>
      <Footer />
      <ContactFooter />
    </div>
  );
};

import { useRef, useEffect, useState } from "react";

export const useImgaeLoadObserver = ({
  rootEle = document,
  objectSelector = "",
  dep = [],
}) => {
  useEffect(() => {
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        // 如果監聽的元件目前在視野範圍內
        if (entry.isIntersecting) {
          const image = entry.target; // 取得 <Image />
          image.setAttribute("src", image.dataset.src); // 將 data-src 塞入 src 讓瀏覽器觸發下載資源
          image.removeAttribute("data-src"); // 移除 data-src
          observer.unobserve(image); // 取消監聽 <Image />
        }
      });
    };

    if (!rootEle) return;

    const observer = new IntersectionObserver(callback);

    const lazyImages = rootEle.querySelectorAll(objectSelector);

    // 取得所有 <Image className="lazy-image" /> 並讓 observer 開始監聽
    lazyImages.forEach((image) => observer.observe(image));

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, dep);
};

export const useIntersectionObserver = (
  elementRef,
  options = { rootMargin: "0px 0px 300px 0px", freezeOnceVisible: false },
  showRef = false
) => {
  const [entry, setEntry] = useState(null);
  const [ob, setOb] = useState(null);

  const frozen = entry?.isIntersecting && options.freezeOnceVisible;

  const updateEntry = ([entry]) => setEntry(entry);

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref

    if (showRef) console.log(node);

    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observer = new IntersectionObserver(updateEntry, options);

    observer.observe(node);

    setOb(observer);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { entry, ob };
};

export const useIO = (
  options = { rootMargin: "0px 0px 300px 0px", freezeOnceVisible: false }
) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      if (entry.isIntersecting) observer.disconnect();
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return [targetRef, isIntersecting];
};

import { useState } from "react";
import { Alert } from "react-bootstrap";

export const useErrorMsg = () => {
  const [msg, setMsg] = useState("");

  const MsgAlert = ({ variant }) => {
    if (!msg) return;
    return <Alert variant={variant || "danger"}>{msg}</Alert>;
  };

  const FormTextAlert = ({ variant }) => {
    if (!msg) return;
    return <strong className={variant || "text-danger"}>{msg}</strong>;
  };

  return {
    msg,
    setMsg,
    MsgAlert,
    FormTextAlert,
  };
};

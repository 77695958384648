import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  Row,
  Col,
  Spinner,
  Stack,
  Form,
  InputGroup,
  Button,
  Placeholder,
  ProgressBar,
  Dropdown,
  DropdownButton,
  Card,
} from "react-bootstrap";

import { EmptyPage } from "../../Components/EmptyPage";
import {
  ProductSearchItem,
  ScrapeProductSearchResult,
} from "Components/Product/component";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
  faFilter,
  faSort,
  faSearch,
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useErrorMsg } from "Components/ErrorMsg/ErrorMsg";
import {
  fetchProduct,
  searchProduct,
  scrapeProduct,
} from "Components/Product/utils";
import { useImgaeLoadObserver } from "Hook/useIntersectionObs";
import { sleep } from "Components/Sleep";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "./component.css";
import { momoData, pinkoiData } from "User/Search/SearchPage";
import {
  SwipeScrollContainer,
  SwipeScrollItem,
} from "Components/ScrollController";

export const SearchCard = () => {
  const [q, setQ] = useState("");
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!q.trim()) return;
    history.push(`/search?q=${q}`);
  };
  return (
    <Card className="my-2 shadow">
      <Card.Body>
        <Stack className="text-center" gap={2}>
          <h4 className="text-primary">
            <strong>找不到想要的商品嗎？</strong>
          </h4>
          <small className="text-muted">支援: Momo，Pinkoi，Line Gift</small>
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Form.Control
                value={q}
                onChange={(e) => setQ(e.target.value)}
                placeholder="今天想送什麼禮物呢"
              />
              <Button type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Form>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export const GiftSearchBar = ({ cb }) => {
  const [typeText, setTypeText] = useState("");
  const errMsg = useErrorMsg();

  const handleSubmit = () => {
    if (!typeText.trim()) return;
    cb(typeText);
  };

  return (
    <Stack>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <small></small>
        <InputGroup>
          <Form.Control
            size="lg"
            type="search"
            autoComplete="off"
            placeholder="搜尋你想要商品"
            onChange={(e) => setTypeText(e.target.value)}
            value={typeText}
            id="search-input"
            className="shadow border border-white"
            style={{
              fontSize: "14px",
              color: "black",
              // borderRadius: "18px",
            }}
          />
          <Button
            type="submit"
            variant="primary"
            className="search-btn product-search-btn mx-1"
            id="product-search-btn"
            // style={{ borderRadius: "20px" }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ color: "#fff" }} />
          </Button>
        </InputGroup>
        {/* <Form.Text>支援：Momo，Pinkoi，Line Gift</Form.Text>
        <br />
        <Form.Text>
          搜尋：{`耳飾、https://www.pinkoi.com/product/WuUPB5Fz`}
        </Form.Text>
        <section className="my-2">
          <errMsg.FormTextAlert />
        </section> */}
      </Form>
    </Stack>
  );
};

export const ProductSearchBar = ({ cb }) => {
  const [typeText, setTypeText] = useState("");
  const errMsg = useErrorMsg();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/search")) return;
    const qText = new URLSearchParams(location.search).get("q");
    if (qText?.trim()) setTypeText(qText);
  }, [location]);

  const handleSubmit = () => {
    if (!typeText.trim()) return;
    cb(typeText);
  };

  return (
    <Stack>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <InputGroup>
          <Form.Control
            size="lg"
            type="search"
            autoComplete="off"
            placeholder="今天想送什麼禮物呢"
            onChange={(e) => setTypeText(e.target.value)}
            value={typeText}
            id="search-input"
            className="shadow border border-white"
            style={{
              fontSize: "14px",
              color: "black",
              //   borderRadius: "18px",
            }}
          />
          <Button
            type="submit"
            variant="primary"
            className="search-btn product-search-btn"
            id="product-search-btn"
            // style={{ borderRadius: "20px" }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ color: "#fff" }} />
          </Button>
        </InputGroup>
        {/* <Form.Text>支援：Momo，Pinkoi，Line Gift</Form.Text>
        <br />
        <Form.Text>
          搜尋：{`耳飾、https://www.pinkoi.com/product/WuUPB5Fz`}
        </Form.Text> */}
        <section className="my-2">
          <errMsg.FormTextAlert />
        </section>
      </Form>
    </Stack>
  );
};

export const SearchProductList = ({
  qText,
  pId,
  morePage,
  horizontal = false,
  pFilter = true,
  productCB = () => {},
  giftSpreadDiscount = 0,
}) => {
  const searchPlatform = ["momo", "pinkoi", "linegift"];
  const [products, setProducts] = useState({});
  const [targetProduct, setTargetProduct] = useState(null);
  // const [searchQueue, setSearchQueue] = useState([]);
  const searchQueue = useRef([]);
  const [pending, setPending] = useState(false);
  const [morePending, setMorePending] = useState(false);
  const [productFiler, setProductFiler] = useState({
    platform: ["momo", "pinkoi", "linegift"],
  });
  const [sortType, setSortType] = useState("auto");
  const [isRecover, setIsRecover] = useState(false);
  const [page, setPage] = useState(1);
  const imgsContainerRef = useRef(null);
  const history = useHistory();
  const errMsg = useErrorMsg();

  const handlePrev = useCallback(() => {
    if (!imgsContainerRef.current) return;
    imgsContainerRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!imgsContainerRef.current) return;
    imgsContainerRef.current.swiper.slideNext();
  }, []);

  const handleProductsSort = useCallback((newList) => {
    let existP = new Set(newList.map((e) => e.platform));

    let sortList = [];

    for (let i in newList) {
      existP.forEach((e) => {
        let fIndex = newList.findIndex((p) => p?.platform === e);

        if (fIndex < 0) return;

        sortList.push(newList[fIndex]);

        newList[fIndex] = null;
      });
    }
    return sortList;
  }, []);

  const handleProductsDeduplicate = useCallback((products) => {
    products = products.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.productLink === obj.productLink)
    );
    return products;
  }, []);

  const Filter = () => {
    return (
      <Row className="justify-content-end my-2">
        <Col xs="auto">
          <DropdownButton
            variant="outline-primary"
            title={
              <Stack direction="horizontal" gap={1}>
                篩選
                <FontAwesomeIcon icon={faFilter} />
              </Stack>
            }
            size="sm"
          >
            {[
              { title: "Line Gift", value: "linegift" },
              { title: "Momo", value: "momo" },
              { title: "Pinkoi", value: "pinkoi" },
            ].map((e) => (
              <Dropdown.Item
                key={e.value}
                onClick={() => {
                  productFiler.platform.includes(e.value)
                    ? setProductFiler({
                        platform: productFiler.platform.filter(
                          (p) => p != e.value
                        ),
                      })
                    : setProductFiler({
                        platform: productFiler.platform.concat(e.value),
                      });
                }}
              >
                <Stack direction="horizontal">
                  <strong>{e.title}</strong>
                  {productFiler.platform.includes(e.value) && (
                    <FontAwesomeIcon
                      className="ms-auto text-primary"
                      icon={faCheck}
                    />
                  )}
                </Stack>
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        <Col xs="auto">
          <DropdownButton
            variant="outline-primary"
            title={
              <Stack direction="horizontal" gap={1}>
                排序
                <FontAwesomeIcon icon={faSort} />
              </Stack>
            }
            size="sm"
          >
            {[
              { title: "自動", value: "auto" },
              { title: "價錢高至低", value: "priceDesc" },
              { title: "價錢低至高", value: "priceAsc" },
            ].map((e) => (
              <Dropdown.Item key={e.value} onClick={() => setSortType(e.value)}>
                <Stack direction="horizontal">
                  <strong>{e.title}</strong>
                  {sortType === e.value && (
                    <FontAwesomeIcon
                      className="ms-auto text-primary"
                      icon={faCheck}
                    />
                  )}
                </Stack>
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
    );
  };

  const filtedProduct = useMemo(() => {
    let newList = [];

    let pageValue = Object.values(products);

    pageValue.forEach((pageData) => {
      let platformValue = Object.values(pageData);
      let pageSort = [];
      platformValue.forEach((platformData) => {
        pageSort = [...pageSort, ...platformData];
      });
      pageSort = handleProductsSort(pageSort);
      pageSort = handleProductsDeduplicate(pageSort);

      if (sortType === "priceDesc") {
        pageSort = pageSort.sort((a, b) => b.price - a.price);
      }

      if (sortType === "priceAsc") {
        pageSort = pageSort.sort((a, b) => a.price - b.price);
      }

      newList = [...newList, ...pageSort];
    });

    newList = newList.filter((e) => {
      return productFiler.platform.includes(e.platform);
    });

    newList = handleProductsDeduplicate(newList);

    return newList;
  }, [productFiler, products, sortType]);

  const isScrape = useMemo(
    () => /[a-zA-Z0-9]{24}/.test(qText) || /https?:\/\/\S+/.test(qText),
    [qText]
  );

  // More fetch
  const sentinelRef = useRef(null);

  useEffect(() => {
    // 定義 IntersectionObserver
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !morePending) {
          const productValues = Object.values(products);
          const lastValue = productValues[productValues.length - 1];
          if (lastValue) {
            // All search result is empty
            const isEmpty = Object.values(lastValue).every(
              (e) => e?.length === 0
            );
            if (isEmpty) {
              observer.unobserve(sentinelRef.current);
              return;
            }
          }
          // 如果 Sentinel 元素進入視窗，加載更多商品
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1, // 監測視窗與 Sentinel 元素相交程度
      }
    );

    // 將 Sentinel 元素綁定到 Observer
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    // 清除 Observer
    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [sentinelRef, products]);

  useEffect(() => {
    if (page <= 1 || !morePage) return;

    setMorePending(true);

    // sleep(3000).finally(() => setMorePending(false));

    const queryArray = searchPlatform.map((e) => {
      return searchProduct(qText, e, page)
        .then((data) => {
          setProducts((pre) => {
            return {
              ...pre,
              [`page${page}`]: {
                ...pre[`page${page}`],
                [e]: data,
              },
            };
          });
        })
        .catch((err) => console.log(err.message))
        .finally(() => {
          searchQueue.current = searchQueue.current.filter(
            (qTitle) => qTitle != e
          );
        });
    });

    Promise.all(queryArray).finally(() => setMorePending(false));
  }, [page]);

  // handle init products setting
  useEffect(() => {
    if (!qText.trim()) return;

    errMsg.setMsg("");

    let lastSearch = JSON.parse(sessionStorage.getItem("last_search"));

    if (qText === lastSearch?.qText && lastSearch?.products) {
      console.log("into session");
      setProducts((_) => lastSearch.products);
      if (lastSearch?.page) setPage(lastSearch.page);
      setIsRecover(true);
      return;
    }

    handleSearch(qText);
  }, [qText]);

  useEffect(() => {
    if (!isRecover) return;
    let lastSearch = JSON.parse(sessionStorage.getItem("last_search"));
    if (lastSearch.lastY) window.scrollTo(0, parseInt(lastSearch.lastY));
    setIsRecover(false);
  }, [products]);

  const handleViewRecords = (lastViewLink) => {
    const lastY = window.scrollY;
    let sd = { qText, products, lastViewLink, lastY, page };
    sessionStorage.setItem("last_search", JSON.stringify(sd));
  };

  const handleSearch = async (searchText, page = 1) => {
    try {
      if (pending) return;

      setTargetProduct(null);

      errMsg.setMsg("");

      if (!searchText) throw new Error("請輸入搜尋內容");

      setPending(true);

      if (/[a-zA-Z0-9]{24}/.test(searchText)) {
        let data = await fetchProduct(searchText);
        setTargetProduct(data);
        return;
      }

      if (/https?:\/\/\S+/.test(searchText)) {
        console.log(`into scrape`);
        let data = await scrapeProduct(searchText);
        setTargetProduct(data);
        return;
      }

      setProducts({});

      // setSearchQueue(searchPlatform);
      searchQueue.current = searchPlatform;

      const queryArray = searchPlatform.map((e) => {
        // let sec = 2000;
        // if (e == "momo") sec = 3000;
        // if (e == "pinkoi") sec = 1000;
        // return sleep(sec).then(() => {
        //   searchQueue.current = searchQueue.current.filter(
        //     (qTitle) => qTitle != e
        //   );

        //   let pD =
        //     e == "momo" ? momoData : e == "pinkoi" ? pinkoiData : linegiftData;
        //   pD = pD.filter(
        //     (obj, index, self) =>
        //       index === self.findIndex((t) => t.productLink === obj.productLink)
        //   );

        //   setProducts((pre) => [...pre, ...pD]);
        // });
        // console.log("into api search products");
        // setProducts({
        //   page1: {
        //     pinkoi: pinkoiData,
        //   },
        // });
        // return;
        return searchProduct(searchText, e, page)
          .then((data) => {
            setProducts((pre) => {
              return {
                ...pre,
                [`page${page}`]: {
                  ...pre[`page${page}`],
                  [e]: data,
                },
              };
            });
          })
          .catch((err) => console.log(err.message))
          .finally(() => {
            searchQueue.current = searchQueue.current.filter(
              (qTitle) => qTitle != e
            );
          });
      });

      // await Promise.all(queryArray);
    } catch (err) {
      errMsg.setMsg(err.message);
    } finally {
      setPending(false);
    }
  };

  if (errMsg.msg)
    return (
      <Stack className="text-center" gap={2}>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="2x"
          className="text-primary mx-auto"
        />
        <strong>{errMsg.msg}</strong>
      </Stack>
    );

  // if (isScrape)
  //   return (
  //     <>
  //       {pending ? (
  //         <Stack className="text-center" gap={2}>
  //           <FontAwesomeIcon
  //             className="mx-auto text-primary"
  //             icon={faSearch}
  //             size="2x"
  //           />
  //           <h4 className="text-muted">即時搜尋中</h4>
  //           <Spinner className="mx-auto" animation="grow" />
  //         </Stack>
  //       ) : targetProduct ? (
  //         <ScrapeProductSearchResult
  //           data={targetProduct}
  //           handlePay={(info) => {
  //             const { productData, pickSpec, amount } = info;
  //             const qObj = {
  //               productId: productData._id,
  //               spec: pickSpec,
  //               amount,
  //             };
  //             let q = new URLSearchParams(qObj);

  //             history.push(`/user/payment/scrapeOrder/request?${q.toString()}`);
  //           }}
  //         />
  //       ) : (
  //         <></>
  //       )}
  //     </>
  //   );

  if (horizontal)
    return (
      <Stack className={`${qText}`}>
        {/* Filter & sorter */}
        {pFilter && Object.keys(products).length > 0 && <Filter />}

        {/* Progress Pending */}
        {(pending || searchQueue.current.length > 0) && (
          <Stack>
            <ProgressBar
              style={{ height: "5px" }}
              now={
                ((searchPlatform.length - searchQueue.current.length) /
                  searchPlatform.length) *
                100
              }
              className="my-1"
            />
          </Stack>
        )}

        {/* List Product Result */}
        {!pending &&
          Object.keys(products?.page1 || {}).length === searchPlatform.length &&
          filtedProduct.length === 0 && (
            <EmptyPage title={"找不到商品，試試其他關鍵字吧"} h="60vh" />
          )}

        {
          <Row>
            <Col>
              <Swiper
                className="search-hor-swiper-container"
                slidesPerView={"auto"}
                spaceBetween={20}
                pagination={{ dynamicBullets: true, clickable: true }}
                ref={imgsContainerRef}
                modules={[Pagination, Navigation]}
              >
                {filtedProduct.map((e) => (
                  <SwiperSlide
                    key={e.productLink}
                    className="search-hor-swipeitem"
                  >
                    {/* <Link
                      to={`search?q=${e.productLink}`}
                      style={{ textDecoration: "none" }}
                    > */}
                    <ProductSearchItem
                      giftSpreadDiscount={giftSpreadDiscount}
                      data={e}
                      key={e.productLink}
                      cb={(data) => {
                        productCB(data);
                        handleViewRecords(data.productLink);
                      }}
                    />
                    {/* </Link> */}
                  </SwiperSlide>
                ))}
                {/* Pending placehorlder */}
                {searchQueue.current.length > 0 && (
                  <React.Fragment>
                    {[1, 2, 3].map((e) => (
                      <SwiperSlide key={e}>
                        <div
                          xs="auto"
                          className="mx-2"
                          style={{ width: "180px", height: "180px" }}
                        >
                          <div className="search-place-img" />
                        </div>
                        <Placeholder as="h4" animation="glow">
                          <Placeholder xs={3} />
                        </Placeholder>
                        <Placeholder as="h4" animation="glow">
                          <Placeholder xs={8} />
                        </Placeholder>
                        <Placeholder as="h4" animation="glow">
                          <Placeholder xs={3} />
                        </Placeholder>
                      </SwiperSlide>
                    ))}
                  </React.Fragment>
                )}
                <section className="search-swiper-prev-container">
                  <FontAwesomeIcon
                    onClick={handlePrev}
                    className="prev-btn"
                    icon={faChevronCircleLeft}
                    size="2x"
                  />
                </section>
                <section className="search-swiper-next-container">
                  <FontAwesomeIcon
                    onClick={handleNext}
                    icon={faChevronCircleRight}
                    className="next-btn"
                    size="2x"
                  />
                </section>
              </Swiper>
            </Col>
          </Row>
        }
      </Stack>
    );

  return (
    <Stack>
      {/* Filter & sorter */}
      {pFilter && Object.keys(products).length > 0 && <Filter />}
      {/* Progress Pending */}
      {(pending || searchQueue.current.length > 0) && (
        <Stack>
          <ProgressBar
            style={{ height: "5px" }}
            now={
              ((searchPlatform.length - searchQueue.current.length) /
                searchPlatform.length) *
              100
            }
            className="my-1"
          />
        </Stack>
      )}

      {/* List Product Result */}
      {!pending &&
        !errMsg.msg &&
        Object.keys(products?.page1 || {}).length === searchPlatform.length &&
        filtedProduct.length === 0 && (
          <EmptyPage title={"找不到商品，試試其他關鍵字吧"} h="60vh" />
        )}
      {!pending && !errMsg.msg && filtedProduct.length > 0 && (
        <Stack ref={imgsContainerRef}>
          <Row xs={2} md={3}>
            {filtedProduct.map((e) => (
              <Col key={e.productLink} className="my-1 p-1">
                {/* <Link
                  to={`search?q=${e.productLink}`}
                  style={{ textDecoration: "none" }}
                > */}
                <ProductSearchItem
                  giftSpreadDiscount={giftSpreadDiscount}
                  data={e}
                  key={e.productLink}
                  cb={(data) => {
                    productCB(data);
                    handleViewRecords(data.productLink);
                  }}
                />
                {/* </Link> */}
              </Col>
            ))}
          </Row>
          <div ref={sentinelRef} />
          {page > 1 && morePending && <Spinner className="mx-auto" />}
        </Stack>
      )}

      {/* Pending placehorlder */}
      {searchQueue.current.length > 0 && (
        <Stack>
          <Row xs={2} md={3}>
            {[1, 2, 3].map((e) => (
              <Col key={e}>
                <div
                  xs="auto"
                  className="mx-2"
                  style={{ width: "180px", height: "180px" }}
                >
                  <div className="search-place-img" />
                </div>
                <Placeholder as="h6" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as="h4" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
                <Placeholder as="h4" animation="glow">
                  <Placeholder xs={1} /> <Placeholder xs={4} />{" "}
                </Placeholder>
              </Col>
            ))}
          </Row>
        </Stack>
      )}
    </Stack>
  );
};

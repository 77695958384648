import {
  APIEcpayReq,
  APIFetchOrderById,
  APISOEcpayReq,
  APIFetchScrapeOrderById,
  APIMarkupCb,
  APIFetchGiftById,
  APISOCal,
} from "API/order";
import { APIFetchProduct, APIFetchScrapeProduct } from "API/product";
import { Http_request_get, Http_request_post } from "../../Service/HttpService";

export const handleCustomerDataChanged = (event, data, setData) => {
  let target = event.target;

  let newData = { ...data };

  newData.customerData[target.name] = target.value;

  setData(newData);
};

export const isValid = (paymentInfo) => {
  let errMsg = [];

  if (paymentInfo.identifier && !paymentInfo.address) errMsg.push("地址未輸入");

  if (!paymentInfo?.customerData?.email) errMsg.push("缺少email");

  return errMsg;
};

export const fetchPayment = async (id) => {
  let result = await Http_request_get(APIFetchScrapeOrderById + `/${id}`);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchMarkup = async (id) => {
  let result = await Http_request_get(APIFetchGiftById + `/${id}`);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchProduct = async (id) => {
  let result = await Http_request_get(APIFetchScrapeProduct + `/${id}`);
  if (!result.data) throw new Error("查無商品");
  return result.data;
};

export const ecpayReq = async (paymentInfo) => {
  let result = await Http_request_post(APIEcpayReq, {
    paymentInfo,
    provider: "ecpay",
  });
  if (!result.data) throw new Error(result.message);
  return result.data;
};

export const scrapeOrderEcpayReq = async (paymentInfo) => {
  let result = await Http_request_post(APISOEcpayReq, {
    paymentInfo,
  });
  if (!result.data) throw new Error(result.message);
  return result.data;
};

export const markupCallback = async (orderId) => {
  let result = await Http_request_post(APIMarkupCb, { orderId });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

// export const calFee = async (productId, amount, specIndex) => {
//   let result = await Http_request_post(APISOCal, {
//     calParam: {
//       productId,
//       amount,
//       specIndex
//     },
//   });

//   if (!result.data) throw new Error(result.message);

//   return result.data;
// };

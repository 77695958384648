const { APIUpdateUserInfo } = require("API/user");
const { Http_request_post } = require("Service/HttpService");

export const updateBasicInfo = async (updateData) => {
  let result = await Http_request_post(APIUpdateUserInfo, updateData);

  if (!result.data) throw new Error(result.message)

  return result.data;
};

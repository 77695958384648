import { Badge, Card, Stack, Image } from "react-bootstrap";

export const GiftResponseNote = () => {
  return (
    <Card
      className="shadow-sm"
      style={{
        borderTop: "0",
        borderLeft: "3px solid green",
        borderBottom: "0",
        borderRight: "0",
        borderRadius: "6px",
      }}
    >
      <Card.Body className="text-muted" style={{ fontSize: "14px" }}>
        <Stack gap={2}>
          <span>
            <Badge>收下禮物</Badge>只需填寫收件資訊即可
          </span>
          <span>
            <Badge bg="success">更改禮物</Badge>會根據新選的禮物價格多退少補
          </span>
          <span>-補差額：會由收禮者另外補貼新舊禮物的差額</span>
          <span>-不須補差額：會將多餘的價差退還予送禮者</span>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export const GiftResChangeNote = () => {
  return (
    <Card
      className="shadow-sm"
      style={{
        borderTop: "0",
        borderLeft: "3px solid green",
        borderBottom: "0",
        borderRight: "0",
        borderRadius: "6px",
      }}
    >
      <Card.Body className="text-muted" style={{ fontSize: "14px" }}>
        <Stack gap={2}>
          <span>
            <Badge bg="success">更改禮物</Badge>會根據新選的禮物價格多退少補
          </span>
          <small>-補差額：會由收禮者另外補貼新舊禮物的差額</small>
          <small>-不須補差額：會將多餘的價差退還予送禮者</small>
          {/* <strong className="text-danger">-本平台不支援外島配送.</strong> */}
        </Stack>
      </Card.Body>
    </Card>
  );
};

export const GiftNoteBanner = () => {
  return (
    <Image
      src="https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/gift-note-banner1.jpg"
      width={"100%"}
      rounded
    />
  );
};

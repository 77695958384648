import { useToast } from "Provider/ToastProvider";
import { copyToClipboard } from "Service/CopyBoardService";

export const useClipboard = () => {
  const { addToast } = useToast();

  const copyClipboard = async (data) => {
    try {
      await copyToClipboard(data);

      addToast("成功複製", { appearance: "success" });
    } catch (err) {
      addToast("複製權限未開或此瀏覽器不支援", { appearance: "error" });
    }
  };

  return { copyClipboard };
};

import React, { useEffect, useState } from "react";
import { Login } from "../Login/Login";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { CouponLogin } from "../Components/Coupon/Coupon_template";

const Register_page = ({ match }) => {
  const [share, setshare] = useState(match.params);

  const [coupons, setcoupons] = useState([]);

  useEffect(() => {
    if (share) {
      //   setcoupon_ID();
      setcoupons([]);
    }
  });
  return (
    <div>
      {share === null ? null : <CouponLogin data={coupons} />}
      <Login />
    </div>
  );
};

export { Register_page };

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import {
  Stack,
  Form,
  InputGroup,
  Button,
  Image,
  Tabs,
  Tab,
  Carousel,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faGift,
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useErrorMsg } from "Components/ErrorMsg/ErrorMsg";
import { SearchProductList, SearchCard } from "Components/Search/component";
import { useIntersectionObserver } from "Hook/useIntersectionObs";
import { GiftNoteBanner } from "Components/Note/Gift";
import receviceTutImg from "static/banner/receive-tut.png"
import sendIutImg from "static/banner/send-tut.png"

export const pinkoiData = [
  {
    platform: "pinkoi",
    productName: "龍年小籠包零錢包",
    productId: "2Ex8vwPd",
    productLink: "https://www.pinkoi.com/product/2Ex8vwPd",
    storeName: "NVB",
    price: 1880,
    imgs: ["https://cdn02.pinkoi.com/product/2Ex8vwPd/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "Fuji 真皮斜背水桶包  (富士山) / 棕色 Espresso Brown 焦糖色",
    productId: "DUV2eHnJ",
    productLink: "https://www.pinkoi.com/product/DUV2eHnJ",
    storeName: "JAYCHEWIN",
    price: 3411,
    imgs: ["https://cdn02.pinkoi.com/product/DUV2eHnJ/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "綿羊皮大籠包",
    productId: "AeRmyh5i",
    productLink: "https://www.pinkoi.com/product/AeRmyh5i",
    storeName: "TIPSY Leather Goods",
    price: 2280,
    imgs: ["https://cdn02.pinkoi.com/product/AeRmyh5i/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【免運專區】純脆堅果隨身包_30g",
    productId: "GwaRQxYz",
    productLink: "https://www.pinkoi.com/product/GwaRQxYz",
    storeName: "松誠雜糧 Energrain",
    price: 899,
    imgs: ["https://cdn02.pinkoi.com/product/GwaRQxYz/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【台客藍】小籠包調味罐2入組 **蒸籠+花布包裝(顏色隨機)",
    productId: "6bWkxc6D",
    productLink: "https://www.pinkoi.com/product/6bWkxc6D",
    storeName: "台客藍 Hakka-blue",
    price: 1110,
    imgs: ["https://cdn02.pinkoi.com/product/6bWkxc6D/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "真皮 包, 斜肩包, 斜挎包, 斜背包, 真皮手袋, 真皮 托特, 真皮托",
    productId: "PkjEBbnP",
    productLink: "https://www.pinkoi.com/product/PkjEBbnP",
    storeName: "Youngbags.ua",
    price: 1404,
    imgs: ["https://cdn02.pinkoi.com/product/PkjEBbnP/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【免運組】【胡同李-東北大餡水餃】鮮蝦大餛飩or鮮肉小餛飩3袋入",
    productId: "RN69B43N",
    productLink: "https://www.pinkoi.com/product/RN69B43N",
    storeName: "胡同李-東北大餡水餃",
    price: 759,
    imgs: ["https://cdn02.pinkoi.com/product/RN69B43N/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "sobag原宿風文藝灰色帆布手拎水桶包女 小眾簡約復古單肩斜跨包包",
    productId: "jAftsntc",
    productLink: "https://www.pinkoi.com/product/jAftsntc",
    storeName: "Sobag独立手作",
    price: 1109,
    imgs: ["https://cdn02.pinkoi.com/product/jAftsntc/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【好好生醫】燕窩酸益生菌・30包/盒【養顏美容x排便順暢】",
    productId: "V67mrR5x",
    productLink: "https://www.pinkoi.com/product/V67mrR5x",
    storeName: "好好生醫 Better Biosciences",
    price: 1210,
    imgs: ["https://cdn02.pinkoi.com/product/V67mrR5x/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【杜邦益菌】顧消化 x 消化益生菌(20包/盒)",
    productId: "H57STQ8e",
    productLink: "https://www.pinkoi.com/product/H57STQ8e",
    storeName: "Ruijia 露奇亞",
    price: 800,
    imgs: ["https://cdn02.pinkoi.com/product/H57STQ8e/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "FRUDIA 望莓止渴 藍莓蜂蜜緊好眠晚安凍膜 20 份裝",
    productId: "B4BuXYBm",
    productLink: "https://www.pinkoi.com/product/B4BuXYBm",
    storeName: "FRUDIA",
    price: 490,
    imgs: ["https://cdn02.pinkoi.com/product/B4BuXYBm/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "頭等艙鞋履 小籠包寬楦帆布餅乾鞋 小紅帽大野狼 女鞋",
    productId: "KcDCq5C9",
    productLink: "https://www.pinkoi.com/product/KcDCq5C9",
    storeName: "BoingBoing故事鞋與童畫包",
    price: 1580,
    imgs: ["https://cdn02.pinkoi.com/product/KcDCq5C9/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【夜太High首選】金放鬆 x 晚安益生菌(20包/盒)",
    productId: "pdHEKx7y",
    productLink: "https://www.pinkoi.com/product/pdHEKx7y",
    storeName: "Ruijia 露奇亞",
    price: 880,
    imgs: ["https://cdn02.pinkoi.com/product/pdHEKx7y/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "中蠟包 - 日本帆布 / 托特包 / 手提 / 斜揹",
    productId: "ZaJJNKJn",
    productLink: "https://www.pinkoi.com/product/ZaJJNKJn",
    storeName: "quoi quoi 布知道",
    price: 1580,
    imgs: ["https://cdn02.pinkoi.com/product/ZaJJNKJn/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName:
      "【 日本直送　名牌中古包 】Tiffany & Co. ティファニー リターントゥ ブレスレット シルバー vintage ヴィンテージ オールド zbwc3y",
    productId: "9UZEmh3j",
    productLink: "https://www.pinkoi.com/product/9UZEmh3j",
    storeName: "solo-vintage",
    price: 7601,
    imgs: ["https://cdn02.pinkoi.com/product/9UZEmh3j/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【新潤昌堂養生號】八珍茶 10入 養生茶包",
    productId: "XwkeyR7a",
    productLink: "https://www.pinkoi.com/product/XwkeyR7a",
    storeName: "新潤昌堂養生號",
    price: 175,
    imgs: ["https://cdn02.pinkoi.com/product/XwkeyR7a/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "帆布經典後背包",
    productId: "KAYfRW6C",
    productLink: "https://www.pinkoi.com/product/KAYfRW6C",
    storeName: "一帆布包",
    price: 1500,
    imgs: ["https://cdn02.pinkoi.com/product/KAYfRW6C/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "Stan&Co 帆布包/單肩包/托特包/A-Z字母可選 包包",
    productId: "BbAjCe7G",
    productLink: "https://www.pinkoi.com/product/BbAjCe7G",
    storeName: "Stan&Co",
    price: 1003,
    imgs: ["https://cdn02.pinkoi.com/product/BbAjCe7G/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【新潤昌堂養生號】響聲茶 10入 養生茶包",
    productId: "UgaXwgBy",
    productLink: "https://www.pinkoi.com/product/UgaXwgBy",
    storeName: "新潤昌堂養生號",
    price: 150,
    imgs: ["https://cdn02.pinkoi.com/product/UgaXwgBy/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "韓國製 MUR Bonnet Bag Vegan Leather 包包 (Black)",
    productId: "28JQc8GC",
    productLink: "https://www.pinkoi.com/product/28JQc8GC",
    storeName: "MUR",
    price: 2439,
    imgs: ["https://cdn02.pinkoi.com/product/28JQc8GC/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName:
      "Semi circle cross body handbag or shoulder bag with shrimp paste",
    productId: "bhhaeQCm",
    productLink: "https://www.pinkoi.com/product/bhhaeQCm",
    storeName: "THEOREM",
    price: 1505,
    imgs: ["https://cdn02.pinkoi.com/product/bhhaeQCm/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【換季必備】調體質 x 優舒益生菌(20包/盒)",
    productId: "dNZ3SwnE",
    productLink: "https://www.pinkoi.com/product/dNZ3SwnE",
    storeName: "Ruijia 露奇亞",
    price: 800,
    imgs: ["https://cdn02.pinkoi.com/product/dNZ3SwnE/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName:
      "紅茶セット　白うさぎのティーバッグ　アールグレイ　アップルティー　アプリコットティー　3包入",
    productId: "QmENqeJb",
    productLink: "https://www.pinkoi.com/product/QmENqeJb",
    storeName: "海洋茶包",
    price: 279,
    imgs: ["https://cdn02.pinkoi.com/product/QmENqeJb/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "Fuji 真皮斜背水桶包  (富士山) / 棕色 Espresso Brown 焦糖色",
    productId: "DUV2eHnJ",
    productLink: "https://www.pinkoi.com/product/DUV2eHnJ",
    storeName: "JAYCHEWIN",
    price: 3411,
    imgs: ["https://cdn02.pinkoi.com/product/DUV2eHnJ/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "茶點禮盒 | 茶餅 x 茶包16入 金萱曲奇&包種戀人餅&紅玉茶餅",
    productId: "i22JDHEC",
    productLink: "https://www.pinkoi.com/product/i22JDHEC",
    storeName: "心茶 Xin Tea – 台灣茶 X 日本茶專門",
    price: 836,
    imgs: ["https://cdn02.pinkoi.com/product/i22JDHEC/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "MINE'VIE 韓國製 ALIN Bag 包包",
    productId: "VcX46DvE",
    productLink: "https://www.pinkoi.com/product/VcX46DvE",
    storeName: "Minevie",
    price: 2643,
    imgs: ["https://cdn02.pinkoi.com/product/VcX46DvE/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "Dumpling_自由組裝植鞣皮革小籠包 手提包 水桶包【自組裝品】",
    productId: "7KcwhnNG",
    productLink: "https://www.pinkoi.com/product/7KcwhnNG",
    storeName: "Studio Smoll",
    price: 4980,
    imgs: ["https://cdn02.pinkoi.com/product/7KcwhnNG/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "巨型小籠包燈飾",
    productId: "qK2u9UHY",
    productLink: "https://www.pinkoi.com/product/qK2u9UHY",
    storeName: "smoko Inc.",
    price: 2800,
    imgs: ["https://cdn02.pinkoi.com/product/qK2u9UHY/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "LIMITED EDITION Mappy tote bag-cream 包包 2in1",
    productId: "zGEiTERd",
    productLink: "https://www.pinkoi.com/product/zGEiTERd",
    storeName: "embroidererbangkok",
    price: 1075,
    imgs: ["https://cdn02.pinkoi.com/product/zGEiTERd/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "秋冬系列手提包 斜挎包可愛雲朵包蓬蓬手機包 通勤羽絨包 豆腐白",
    productId: "XRCRDjxi",
    productLink: "https://www.pinkoi.com/product/XRCRDjxi",
    storeName: "製包事多 Sumayzoy Store",
    price: 779,
    imgs: ["https://cdn02.pinkoi.com/product/XRCRDjxi/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【新潤昌堂養生號】 生化茶 10入 養生茶包",
    productId: "z2ZgdSfJ",
    productLink: "https://www.pinkoi.com/product/z2ZgdSfJ",
    storeName: "新潤昌堂養生號",
    price: 150,
    imgs: ["https://cdn02.pinkoi.com/product/z2ZgdSfJ/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "文山包種茶・簡單茶包・清花",
    productId: "KtnbxnqM",
    productLink: "https://www.pinkoi.com/product/KtnbxnqM",
    storeName: "山不枯 Sanpuku - 自然安心茶",
    price: 240,
    imgs: ["https://cdn02.pinkoi.com/product/KtnbxnqM/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【免費刻字燙金】Wàndering皮革帆布肩背包/米白(客製化禮物)",
    productId: "JDFjB9mJ",
    productLink: "https://www.pinkoi.com/product/JDFjB9mJ",
    storeName: "ADOLE",
    price: 1692,
    imgs: ["https://cdn02.pinkoi.com/product/JDFjB9mJ/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "調整體質【明星熱銷組/漢方茶30入】女中醫師推薦無咖啡因漢方茶",
    productId: "Ep3Arbjx",
    productLink: "https://www.pinkoi.com/product/Ep3Arbjx",
    storeName: "漢方保養 | 樂木集 LOMOJI",
    price: 1110,
    imgs: ["https://cdn02.pinkoi.com/product/Ep3Arbjx/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【PAVI STUDIO】100%泰國直送設計肩背包 - 黑色",
    productId: "7LR6T5N8",
    productLink: "https://www.pinkoi.com/product/7LR6T5N8",
    storeName: "Pavi studio",
    price: 1680,
    imgs: ["https://cdn02.pinkoi.com/product/7LR6T5N8/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "厚燒茶酥餅(抹茶/焙茶/蜜香紅茶/包種茶)6入/綜合",
    productId: "2vuHkrBp",
    productLink: "https://www.pinkoi.com/product/2vuHkrBp",
    storeName: "一茶軒甜點One Tea House",
    price: 480,
    imgs: ["https://cdn02.pinkoi.com/product/2vuHkrBp/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "茶點禮盒 | 茶餅x茶包8入 金萱曲奇&包種戀人餅&阿薩姆奶茶夾心",
    productId: "GrLHZgVT",
    productLink: "https://www.pinkoi.com/product/GrLHZgVT",
    storeName: "心茶 Xin Tea – 台灣茶 X 日本茶專門",
    price: 385,
    imgs: ["https://cdn02.pinkoi.com/product/GrLHZgVT/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "三明治隨身側背包 - 淺灰色",
    productId: "1KISJJBO",
    productLink: "https://www.pinkoi.com/product/1KISJJBO",
    storeName: "whiteoakfactory",
    price: 489,
    imgs: ["https://cdn02.pinkoi.com/product/1KISJJBO/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "黑色帆布包 旅行肩背包   日常斜肩包 大容量購物袋 - 101-號蕾妮",
    productId: "9TPtKLjQ",
    productLink: "https://www.pinkoi.com/product/9TPtKLjQ",
    storeName: "christystudio柯思",
    price: 1691,
    imgs: ["https://cdn02.pinkoi.com/product/9TPtKLjQ/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "小籠包蒸籠禮物盒 Xiaolongbao Gift Box 長襪|中筒襪|男女同款",
    productId: "pBWEMjsM",
    productLink: "https://www.pinkoi.com/product/pBWEMjsM",
    storeName: "木森好襪 Musen SOCKS",
    price: 380,
    imgs: ["https://cdn02.pinkoi.com/product/pBWEMjsM/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "雙肩包 真皮革 日本直送 迷你 時尚 女性包包 收納力強 經典黑",
    productId: "UiZnx4ee",
    productLink: "https://www.pinkoi.com/product/UiZnx4ee",
    storeName: "Leather Goods Shop Hallelujah",
    price: 2955,
    imgs: ["https://cdn02.pinkoi.com/product/UiZnx4ee/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName:
      "巾着バッグ 【 シュリンクレザー 】 本革 レザー 姫路レザー バッグ 鞄 HS79K",
    productId: "Z9bmyCMs",
    productLink: "https://www.pinkoi.com/product/Z9bmyCMs",
    storeName: "kamome-studio",
    price: 1640,
    imgs: ["https://cdn02.pinkoi.com/product/Z9bmyCMs/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【新潤昌堂養生號】魚腥草茶 10入 養生茶包",
    productId: "UrZPCG63",
    productLink: "https://www.pinkoi.com/product/UrZPCG63",
    storeName: "新潤昌堂養生號",
    price: 110,
    imgs: ["https://cdn02.pinkoi.com/product/UrZPCG63/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "富士山包包 / 霧灰藍",
    productId: "6JhgRMa5",
    productLink: "https://www.pinkoi.com/product/6JhgRMa5",
    storeName: "| 偷偷喊我 | 手作裁縫室",
    price: 780,
    imgs: ["https://cdn02.pinkoi.com/product/6JhgRMa5/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【改版出清SALE】小巧口金包 免費客製金屬名字 水蠟黑牛皮",
    productId: "48Nh7FGX",
    productLink: "https://www.pinkoi.com/product/48Nh7FGX",
    storeName: "BU",
    price: 790,
    imgs: ["https://cdn02.pinkoi.com/product/48Nh7FGX/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "斜挎包  WHITEOAKFACTORY Denise bag - Light beige",
    productId: "ZJpJ3bPy",
    productLink: "https://www.pinkoi.com/product/ZJpJ3bPy",
    storeName: "whiteoakfactory",
    price: 919,
    imgs: ["https://cdn02.pinkoi.com/product/ZJpJ3bPy/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "Mustard Canvas Cozy Bag / Shoulder bag / 泰國包包 /泰國設計",
    productId: "eUNXJVJu",
    productLink: "https://www.pinkoi.com/product/eUNXJVJu",
    storeName: "underlinebagsandmore",
    price: 675,
    imgs: ["https://cdn02.pinkoi.com/product/eUNXJVJu/0/1/220x220.jpg"],
  },
  {
    platform: "pinkoi",
    productName: "【台北手作課程】傳統美食體驗A -小籠包/麻油麵線/涼拌干絲/珍奶",
    productId: "ve8agSc6",
    productLink: "https://www.pinkoi.com/product/ve8agSc6",
    storeName: "CookingFunTaiwan 暖心廚房",
    price: 2300,
    imgs: ["https://cdn02.pinkoi.com/product/ve8agSc6/0/1/220x220.jpg"],
  },
];

export const momoData = [
  {
    platform: "momo",
    productName: "【COACH】MICHAEL KORS 斜背包/肩背包/兩用包(多款多色任選)",
    productId: "",
    storeName: "",
    price: 3799,
    imgs: [
      "https://i4.momoshop.com.tw/1711083805/goodsimg/0010/839/402/10839402_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=10839402&mdiv=searchEngine&oid=1_1&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【COACH】MICHAEL KORS 斜背包/肩背包/兩用包/禮盒組(多款多色任選/交換禮物)",
    productId: "",
    storeName: "",
    price: 2999,
    imgs: [
      "https://i3.momoshop.com.tw/1712120547/goodsimg/0010/630/111/10630111_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=10630111&mdiv=searchEngine&oid=1_2&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【ELLE & 英國小獅 & KANGOL】買一送一。買包送正貨小獅包│經典簡約帆布托特包/水餃包/肩背包(多款任選)",
    productId: "",
    storeName: "",
    price: 1199,
    imgs: [
      "https://i2.momoshop.com.tw/1712034381/goodsimg/0010/821/578/10821578_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=10821578&mdiv=searchEngine&oid=1_3&kw=包包",
  },
  {
    platform: "momo",
    productName: "【NINE WEST官方直營】肩背包/長短夾均一價(限量絕版品)",
    productId: "",
    storeName: "",
    price: 990,
    imgs: [
      "https://i1.momoshop.com.tw/1712572332/goodsimg/0008/453/035/8453035_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=8453035&mdiv=searchEngine&oid=1_4&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【PLAYBOY】馬鞍包/斜背包/手提包/腰包/後背包/長夾/托特包(多款任選)",
    productId: "",
    storeName: "",
    price: 1350,
    imgs: [
      "https://i3.momoshop.com.tw/1712207024/goodsimg/0011/544/783/11544783_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=11544783&mdiv=searchEngine&oid=1_5&kw=包包",
  },
  {
    platform: "momo",
    productName: "【CHENSON】輕量3夾層4口袋 托特包 斜背包(CG83811)",
    productId: "",
    storeName: "",
    price: 1488,
    imgs: [
      "https://i4.momoshop.com.tw/1712291105/goodsimg/0011/096/332/11096332_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=11096332&mdiv=searchEngine&oid=1_6&kw=包包",
  },
  {
    platform: "momo",
    productName: "【RH】耐用大容量多層外出斜背包(乙多色任選百搭多層收納好搭配)",
    productId: "",
    storeName: "",
    price: 599,
    imgs: [
      "https://i4.momoshop.com.tw/1693065333/goodsimg/0008/798/059/8798059_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=8798059&mdiv=searchEngine&oid=1_7&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【LONSDALE 英國小獅】經典簡約旅行多用途包款 / 肩背包 / 胸包 / 腰包(多款任選)",
    productId: "",
    storeName: "",
    price: 1280,
    imgs: [
      "https://i4.momoshop.com.tw/1712207190/goodsimg/0012/661/889/12661889_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=12661889&mdiv=searchEngine&oid=1_8&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【LONGCHAMP】Michael Kors&COACH水餃包/漁網包/斜背包/圍巾(多款選)",
    productId: "",
    storeName: "",
    price: 3533,
    imgs: [
      "https://i2.momoshop.com.tw/1712546399/goodsimg/0011/931/330/11931330_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=11931330&mdiv=searchEngine&oid=1_9&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【L’ANDYA☆台灣好購】真皮女用寬肩帶斜背包(頭層牛皮/手拿包/側背包/單肩包/軟皮休閒包/寬版撞色肩帶)",
    productId: "",
    storeName: "",
    price: 768,
    imgs: [
      "https://i4.momoshop.com.tw/1693548420/goodsimg/0011/648/128/11648128_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=11648128&mdiv=searchEngine&oid=1_10&kw=包包",
  },
  {
    platform: "momo",
    productName: "【COACH】斜背包/肩背包/兩用包(多款多色任選)",
    productId: "",
    storeName: "",
    price: 6899,
    imgs: [
      "https://i2.momoshop.com.tw/1712572158/goodsimg/0011/621/813/11621813_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=11621813&mdiv=searchEngine&oid=1_11&kw=包包",
  },
  {
    platform: "momo",
    productName: "【吉豐洋行】時尚圖騰揹帶多隔層防潑水斜背包/側背包(多色可選)",
    productId: "",
    storeName: "",
    price: 499,
    imgs: [
      "https://i1.momoshop.com.tw/1704364754/goodsimg/0010/103/522/10103522_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=10103522&mdiv=searchEngine&oid=1_12&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【Bliss BKK】復古幾何拼接皮革手提斜跨包 美拉德 手提包 斜背包(4色可選)",
    productId: "",
    storeName: "",
    price: 359,
    imgs: [
      "https://i2.momoshop.com.tw/1703667875/goodsimg/0012/264/871/12264871_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=12264871&mdiv=searchEngine&oid=1_13&kw=包包",
  },
  {
    platform: "momo",
    productName:
      "【TRAILOS 翠樂絲】韓版觸控時尚手機包(手機側背包/斜背包/側背包)",
    productId: "",
    storeName: "",
    price: 204,
    imgs: [
      "https://i3.momoshop.com.tw/1692353095/goodsimg/0011/747/751/11747751_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=11747751&mdiv=searchEngine&oid=1_14&kw=包包",
  },
  {
    platform: "momo",
    productName: "【COACH】斜背包/肩背包/兩用包(多款多色任選)",
    productId: "",
    storeName: "",
    price: 4888,
    imgs: [
      "https://i1.momoshop.com.tw/1712120482/goodsimg/0010/929/390/10929390_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=10929390&mdiv=searchEngine&oid=1_15&kw=包包",
  },
  {
    platform: "momo",
    productName: "【COACH】DEMPSEY 緹花/皮革抽繩水桶斜背包(多色選)",
    productId: "",
    storeName: "",
    price: 5680,
    imgs: [
      "https://i2.momoshop.com.tw/1706585497/goodsimg/0012/264/581/12264581_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=12264581&mdiv=searchEngine&oid=1_16&kw=包包",
  },
  {
    platform: "momo",
    productName: "【EZlife】時尚炫彩大容量多格側背包(贈袖套)",
    productId: "",
    storeName: "",
    price: 439,
    imgs: [
      "https://i2.momoshop.com.tw/1692268401/goodsimg/0010/221/113/10221113_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=10221113&mdiv=searchEngine&oid=1_17&kw=包包",
  },
  {
    platform: "momo",
    productName: "【MUJI 無印良品】撥水加工尼龍附筆套肩背包(黑色)",
    productId: "",
    storeName: "",
    price: 750,
    imgs: [
      "https://i3.momoshop.com.tw/1702976481/goodsimg/0012/231/174/12231174_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=12231174&mdiv=searchEngine&oid=1_18&kw=包包",
  },
  {
    platform: "momo",
    productName: "【吉豐洋行】休閒時尚多隔層防潑水斜背包/側背包(搭配圖騰背帶)",
    productId: "",
    storeName: "",
    price: 498,
    imgs: [
      "https://i1.momoshop.com.tw/1694685727/goodsimg/0009/391/905/9391905_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=9391905&mdiv=searchEngine&oid=1_19&kw=包包",
  },
  {
    platform: "momo",
    productName: "【Michael Kors】JET SET荔枝皮壓釦寬背袋WOC斜背包(多色選)",
    productId: "",
    storeName: "",
    price: 5080,
    imgs: [
      "https://i4.momoshop.com.tw/1699002373/goodsimg/0012/057/267/12057267_OL_m.webp",
    ],
    productLink:
      "https://m.momoshop.com.tw/goods.momo?i_code=12057267&mdiv=searchEngine&oid=1_20&kw=包包",
  },
];

export const linegiftData = [
  {
    platform: "linegift",
    productName: "【日本 BALLON】鞋子造型擴香石 - 兩款任選",
    productId: "",
    storeName: "",
    price: 1880,
    imgs: [
      "https://obs.line-scdn.net/0hVEAw2z22CWp8Dhtp0s12PS1TBRtPfw1iFXYUV1gPK1lYPRw_SCguVy0NAiwMfDVjPGAWUykNLAUxNiVOK2gnaS0KBS81dR5KIzU4eSUKPBEhYh5ZQS4/f300",
    ],
    productLink: "https://giftshop-tw.line.me/shipping/321969945",
  },
  {
    platform: "linegift",
    productName: "【J'code真愛密碼銀飾】 花兒小鞋子純銀墜子 送白鋼項鍊",
    productId: "",
    storeName: "",
    price: 2990,
    imgs: [
      "https://d.line-scdn.net/lcp-prod-photo/20220724_110/1658646022652JFoXr_JPEG/8295590900195186_1957278669.jpg?type=f344",
    ],
    productLink: "https://giftshop-tw.line.me/shipping/321740366",
  },
  {
    platform: "linegift",
    productName: "【J'code真愛密碼銀飾】 公主小鞋子純銀墜子 送白鋼項鍊",
    productId: "",
    storeName: "",
    price: 2990,
    imgs: [
      "https://obs.line-scdn.net/0hW7qDKLz1CBhMPRyfWVJ3Tx9gBGl_TAwQJUUVJWh4KXQZCDMoJRwXJRo9PU0KVyQ8GxAmGCh7KXAKDC4ocQcSG2h0KXcVCR8SFwY5Cxl0BF4zSzQSKlM4Dg/f300",
    ],
    productLink: "https://giftshop-tw.line.me/shipping/321741957",
  },
  {
    platform: "linegift",
    productName:
      "暖心暖腳選擇【美家家電】美家家電 智能烘鞋器 伸縮烘鞋機 恆溫烘鞋機 鞋子烘乾機",
    productId: "",
    storeName: "",
    price: 799,
    imgs: [
      "https://obs.line-scdn.net/0hHgp-NEeVF0hNVASrtrhoHxwJGzl-JRNAJCwKdWlWGiRhYQBCKGsKSwsfNnsmZCtBDToIcRhXMicAbDtsGjI5Sx8MHCQ5JwNBBnMnWxRQNQ0UOAB7cHQ/f300",
    ],
    productLink: "https://giftshop-tw.line.me/shipping/322098931",
  },
  {
    platform: "linegift",
    productName:
      "【ibits】迷你鞋子除臭器 除異味 鞋櫃 運動鞋 皮鞋 球鞋 除鞋臭 腳氣 除菌 小巧便攜 臭氧除臭 Type-C充電",
    productId: "",
    storeName: "",
    price: 329,
    imgs: [
      "https://obs.line-scdn.net/0hv8hidlPXKRdVEDrUjfxWQARNJWZmYS0fPGg0KnFVJVIbYwYaCi8NFwxYCEEiaxUeFX42LgATDHgYKAUzAnYHFAdLCCQPYwVCK3UOOgwSC1IIfD4kaDA/f300",
    ],
    productLink: "https://giftshop-tw.line.me/shipping/322109005",
  },
];

export const ProductSearchBar = ({ cb }) => {
  const [typeText, setTypeText] = useState("");
  const errMsg = useErrorMsg();

  const handleSubmit = () => {
    if (!typeText.trim()) return;
    cb(typeText);
  };

  return (
    <Stack>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <small></small>
        <InputGroup>
          <Form.Control
            size="lg"
            type="search"
            autoComplete="off"
            placeholder="搜尋商品或貼上商品連結"
            onChange={(e) => setTypeText(e.target.value)}
            value={typeText}
            id="search-input"
            className="shadow border border-white"
            style={{
              fontSize: "14px",
              color: "black",
              // borderRadius: "18px",
            }}
          />
          <Button
            type="submit"
            variant="primary"
            className="search-btn product-search-btn mx-1"
            id="product-search-btn"
            // style={{ borderRadius: "20px" }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ color: "#fff" }} />
          </Button>
        </InputGroup>
        <Form.Text>支援：Momo，Pinkoi，Line Gift</Form.Text>
        <br />
        <Form.Text>
          搜尋：{`耳飾、https://www.pinkoi.com/product/WuUPB5Fz`}
        </Form.Text>
        <section className="my-2">
          <errMsg.FormTextAlert />
        </section>
      </Form>
    </Stack>
  );
};

export const SearchHomePage = () => {
  const history = useHistory();
  const recommendWords = [
    { title: "聖誕特輯", keyword: "聖誕" },
    { title: "新年特輯", keyword: "新年" },
    { title: "耳飾", keyword: "耳飾" },
    { title: "項鍊", keyword: "項鍊" },
    { title: "巧克力", keyword: "巧克力" },
    { title: "手錶", keyword: "手錶" },
    { title: "行李箱", keyword: "行李箱" },
    { title: "保健食品", keyword: "人參液" },
  ];

  const HomeRecommend = ({ title, qWord }) => {
    const elementRef = useRef(null);
    const { entry, ob } = useIntersectionObserver(elementRef, {
      rootMargin: "0px 0px 720px 0px",
      freezeOnceVisible: false,
    });

    useEffect(() => {
      if (entry && entry.isIntersecting) ob.disconnect();
    }, [entry]);

    return (
      <section ref={elementRef} style={{ minHeight: "360px" }}>
        <h5>
          <Link
            className="text-decoration-none text-dark"
            to={`/search?q=${qWord}`}
          >
            {title}
          </Link>
          <FontAwesomeIcon icon={faGift} className="text-primary" />
        </h5>
        {entry?.isIntersecting && (
          <SearchProductList
            qText={qWord}
            horizontal
            morePage={false}
            pFilter={false}
            productCB={(product) =>
              // history.push(`/search?q=${product.productLink}`)
              history.push(`/product/${encodeURIComponent(product.productLink)}`)
            }
          />
        )}
      </section>
    );
  };

  return (
    <div>
      <Stack className="col-md-8 mx-auto">
        <Carousel
          indicators={false}
          prevIcon={
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="text-dark bg-white"
              style={{ borderRadius: "50%" }}
              size="2x"
            />
          }
          nextIcon={
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="text-dark bg-white"
              style={{ borderRadius: "50%" }}
              size="2x"
            />
          }
        >
          {[
            // {
            //   link: "https://blog.nearmespecials.com",
            //   image:
            //     "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner01.png",
            // },
            // {
            //   link: "https://blog.nearmespecials.com",
            //   image:
            //     // "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/receive-gift-tut.png",
            //     "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02.png",
            // },
            // {
            //   link: "https://blog.nearmespecials.com",
            //   image:
            //     "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner03.png",
            // },
            {
              link: '',
              image: sendIutImg
            },
            {
              link: '',
              image: receviceTutImg
            }
          ].map((e) => {
            return (
              <Carousel.Item key={e.image}>
                <Image
                  src={e.image}
                  onClick={() => {
                    if (e.link) window.open(e.link);
                  }}
                  alt="NearMe"
                  key={e.image}
                  width={"100%"}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "12px",
                    objectFit: "cover",
                  }}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Stack>
      {/* <br /> */}

      <Stack className="text-center d-none" gap={2}>
        <h2
          style={{
            fontSize: "3.75rem",
          }}
        >
          <strong className="search-text-gradient">NearMe</strong>
        </h2>
        {/* <h2 style={{ fontWeight: 700 }}>買錯禮物？？別擔心</h2> */}
        <h2 style={{ fontWeight: 700 }} className="search-text-gradient">
          各大電商商品，<span className="text-primary">輕鬆換</span>
        </h2>
        {/* <strong>
          搜尋或貼上商品連結(https://www.pinkoi.com/product/WuUPB5Fz)
        </strong> */}

        <Link to="/FAQ" className="mx-auto">
          <Button size="sm" variant="outline-dark">
            <strong>深入了解 {">"}</strong>
          </Button>
        </Link>
      </Stack>
      <br />
      <hr />
      {recommendWords.map((e, idx) => (
        <section key={e.title}>
          <HomeRecommend title={e.title} qWord={e.keyword} />
          {idx === 4 && (
            <Stack className="my-5 col-md-8 mx-auto">
              <SearchCard />
            </Stack>
          )}

          {idx === 1 && (
            <Stack className="my-5 col-md-8 mx-auto">
              <GiftNoteBanner />
            </Stack>
          )}
        </section>
      ))}
    </div>
  );
};

export const SearchProductPage = () => {
  const [qText, setQText] = useState("");
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const q = new URLSearchParams(location.search).get("q");
    if (q === qText) return;
    setQText(q);
  }, [location]);

  const pId = useMemo(
    () => new URLSearchParams(location.search).get("p"),
    [location]
  );

  if (!qText?.trim()) return <SearchHomePage />;

  return (
    <>
      <Stack className="col-md-8 mx-auto">
        {/* <Tabs defaultActiveKey={"send"} fill justify>
          <Tab title="發送禮物" eventKey={"send"}>
            <Image
              src={
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02.png"
              }
              rounded
              width={"100%"}
            />
          </Tab>
          <Tab title="接收禮物" eventKey={"accept"}>
            <Image
              src={
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner03.png"
              }
              rounded
              width={"100%"}
            />
          </Tab>
        </Tabs>
        <br /> */}
        <SearchProductList
          qText={qText}
          pId={pId}
          morePage={true}
          productCB={(product) =>
            // history.push(`/search?q=${product.productLink}`)
            history.push(`/product/${encodeURIComponent(product.productLink)}`)
          }
        />
      </Stack>
    </>
  );
};

import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import own_coupon from "../../static/classification/own_coupon.png";
import collection from "../../static/classification/collection.jpg";
import favorite from "../../static/classification/favorite.jpg";
import near from "../../static/classification/near.jpg";
import { ScrollController } from "../../Components/ScrollController";

/*------------------ classsification type image  ------------------------*/
import 早午餐 from "../../static/classification/food/早午餐.jpg";
import 酒吧 from "../../static/classification/food/酒吧.jpg";
import 咖啡廳 from "../../static/classification/food/咖啡廳.jpg";
import 手搖飲 from "../../static/classification/food/手搖飲.jpg";
import 水果 from "../../static/classification/food/水果.jpg";
import 食品糕點 from "../../static/classification/food/食品糕點.jpg";
import 餐廳 from "../../static/classification/food/餐廳.jpg";

import 包包 from "../../static/classification/cloth/包包.png";
import 珠寶 from "../../static/classification/cloth/珠寶.jpg";
import 鞋帽配件 from "../../static/classification/cloth/鞋帽配件.jpg";
import 衣服 from "../../static/classification/cloth/衣服.jpg";
import 眼鏡 from "../../static/classification/cloth/眼鏡.jpg";

import barber from "../../static/classification/salon/barber.jpg";
import hair from "../../static/classification/salon/hair.jpg";
import skin from "../../static/classification/salon/skin.jpg";

import 汽車 from "../../static/classification/life/汽車.jpg";
import 汽車美容 from "../../static/classification/life/汽車美容.jpg";
import 課程 from "../../static/classification/life/課程.jpg";
import 小物 from "../../static/classification/life/小物.jpg";
import 有機食品 from "../../static/classification/life/有機食品.jpg";
import 洋酒 from "../../static/classification/life/洋酒.jpg";
import 傢俱 from "../../static/classification/life/傢俱.jpg";
import 電氣 from "../../static/classification/life/電氣.jpg";
import 寵物水族 from "../../static/classification/life/寵物水族.jpg";
import 體育用品 from "../../static/classification/life/體育用品.jpg";
import 房屋修繕 from "../../static/classification/life/房屋修繕.jpg";

import "./Classification_page.css";

const user_habit = [
  { name: "持券店", background: own_coupon },
  { name: "珍藏店", background: collection },
  { name: "最近的店", background: near },
  { name: "常去店", background: favorite },
];

const classification_type = [
  {
    title: "美食",
    list: [
      { name: "咖啡廳", background: 咖啡廳, link: "" },
      { name: "餐廳", background: 餐廳, link: "" },
      { name: "早午餐", background: 早午餐, link: "" },
      { name: "食品糕點", background: 食品糕點, link: "" },
      { name: "手搖飲", background: 手搖飲, link: "" },
      { name: "水果", background: 水果, link: "" },
      { name: "酒吧", background: 酒吧, link: "" },
    ],
  },
  {
    title: "穿搭",
    list: [
      { name: "衣服", background: 衣服, link: "" },
      { name: "鞋類配件", background: 鞋帽配件, link: "" },
      { name: "包包", background: 包包, link: "" },
      { name: "珠寶", background: 珠寶, link: "" },
      { name: "眼鏡", background: 眼鏡, link: "" },
    ],
  },
  {
    title: "美容",
    list: [
      { name: "理容", background: barber, link: "" },
      { name: "理髮", background: hair, link: "" },
      { name: "護膚按摩", background: skin, link: "" },
    ],
  },
  {
    title: "生活",
    list: [
      { name: "汽車", background: 汽車, link: "" },
      { name: "汽車美容", background: 汽車美容, link: "" },
      { name: "課程", background: 課程, link: "" },
      { name: "小物", background: 小物, link: "" },
      { name: "有機食品", background: 有機食品, link: "" },
      { name: "房屋修繕", background: 房屋修繕, link: "" },
      { name: "洋酒", background: 洋酒, link: "" },
      { name: "傢俱", background: 傢俱, link: "" },
      { name: "電氣", background: 電氣, link: "" },
      { name: "寵物水族", background: 寵物水族, link: "" },
      { name: "體育用品", background: 體育用品, link: "" },
    ],
  },
];

const ClassificationCard = ({ type, background, isHabit }) => {
  return (
    <Col style={{ scrollSnapAlign: "start", scrollSnapStop: "always" }}>
      <Link to={isHabit ? `userQuery/${type}` : `search?industrys=${type}`}>
        <Card className="classification-icon">
          <Card.Img
            src={background}
            style={{ width: "100%", height: "100%" }}
            className="classification-icon-fill"
          />
          <Card.ImgOverlay className="text-white d-flex ">
            <h4 className="mx-auto align-self-center">{type}</h4>
          </Card.ImgOverlay>
        </Card>
      </Link>
    </Col>
  );
};

const Classification = () => {
  const habit = user_habit.map((e) => {
    return (
      <ClassificationCard
        isHabit={true}
        type={e.name}
        background={e.background}
        key={e.name}
      />
    );
  });
  const classifications = classification_type.map((e) => {
    return (
      <Row key={e.title}>
        <Col>
          <h2>{e.title}</h2>
          <hr />
          <ScrollController>
            {e.list.map((industry) => {
              return (
                <ClassificationCard
                  isHabit={false}
                  type={industry.name}
                  background={industry.background}
                  key={industry.name}
                />
              );
            })}
          </ScrollController>
          <hr />
        </Col>
      </Row>
    );
  });

  return (
    <Row className="justify-content-center">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Col>
        <ScrollController>{habit}</ScrollController>
      </Col>
      <hr />
      <Col>{classifications}</Col>
      <hr />
      <Col>
        <Link to="/example">商家範例區</Link>
      </Col>
    </Row>
  );
};

export { Classification };

import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { useUser } from "../../Provider/UserProvider";

const labels = [
  { date: "日期時間" },
  { plus: "新增點數" },
  { description: "說明" },
  { minus: "減少點數" },
  { remark: "備註" },
  { accumulated: "累積點數" },
];

const Records = () => {
  const label_template = labels.map((e) => {
    const key = Object.keys(e);
    return <Col xs={6}>{e[key]}</Col>;
  });

  return (
    <Card.Body>
      <Row>{label_template}</Row>
    </Card.Body>
  );
};

const Point = () => {
  const { user } = useUser();

  return (
    <Card>
      <Card.Body className="text-center text-primary">
        <Row className="justify-content-center m-2">
          <h2 className="mr-5">
            <b>{user.point || 0}</b>
          </h2>
          <Button as={Col} variant="outline-primary rounded-pill" xs={5}>
            <b>轉送</b>
          </Button>
        </Row>
      </Card.Body>
      <Records />
    </Card>
  );
};

export { Point };

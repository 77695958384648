import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  ListGroup,
  Button,
  Col,
  Form,
  Alert,
  Nav,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory, Link, useRouteMatch } from "react-router-dom";
import { useToast } from "Provider/ToastProvider";
import "./BasicForm.css";
import { renderRoutes } from "react-router-config";
import {
  Http_request_post,
  Http_request_post_form_data,
} from "../../Service/HttpService";
import { useUser } from "../../Provider/UserProvider";

const Contract = () => {
  return (
    <div>
      <ListGroup variant="flush" className="justify-content-between">
        <ListGroup.Item>簽署合約 {">"}</ListGroup.Item>
      </ListGroup>
      <hr />
      <Col className="text-center" xs={12}>
        <Link to="">
          <Button block>下一步</Button>
        </Link>
      </Col>
    </div>
  );
};

const BuyBuildService = () => {
  return (
    <div>
      <ListGroup variant="flush" className="justify-content-between">
        <ListGroup.Item>購買建置服務 {">"}</ListGroup.Item>
      </ListGroup>
      <hr />
      <Alert variant="warning">溫馨提醒</Alert>
      <p>先瀏覽特約工作室，再選擇一家購買建置網頁服務。</p>
      <p>
        第一次建置網頁需交由合格特約工作室執行，費用一律是3800元，建議先跟工作室確認交件時間。
      </p>
      <Col className="text-center" xs={12}>
        <Link to="Contract">
          <Button block>下一步</Button>
        </Link>
      </Col>
    </div>
  );
};

const CertificateForm = () => {
  const { setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const certificateElement = useRef(null);
  const passbookElement = useRef(null);

  const formData = new FormData();

  const fileMaxSize = 2*1024*1024;

  const handleInputFile = (event) => {
    event.preventDefault();

    const target = event.target;

    const file = target.files[0];

    if (file.size > fileMaxSize) {
      target.value = "";
      addToast("檔案超過最大限制!!", { appearance: "error" });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const certificateFile = certificateElement.current.files[0];

    const passbookFile = passbookElement.current.files[0];

    if (!certificateFile) {
      addToast("營業登記證尚未上傳", { appearance: "error" });
      return;
    }

    if (!passbookFile) {
      addToast("轉帳存摺尚未上傳", { appearance: "error" });
      return;
    }

    let infoData = JSON.parse(localStorage.getItem("infoData"));

    if (!infoData) {
      addToast("基本資料尚未填寫", { appearance: "error" });
      return;
    }

    let result = null;

    //假如已登記過基本資料則跳過
    if (!localStorage.getItem("currentShopID")) {
      result = await Http_request_post("register/shop/infoData", infoData);

      if (!result.data) {
        addToast(result.message, { appearance: result.toast });
        return;
      }

      setUser(result.data.user);

      localStorage.setItem("currentShopID", result.data.shop._id);
    }

    let shopID = localStorage.getItem("currentShopID");

    formData.set("registrationCertificate", certificateFile);

    formData.set("passbook", passbookFile);

    result = await Http_request_post_form_data(
      `register/shop/${shopID}/certificateFile`,
      formData
    );

    addToast(result.message, { appearance: result.toast });

    setLoading(false);

    if (result.data) {
      localStorage.removeItem("infoData");
      history.push("/BasicForm/BuyBuildService");
    }
  };

  return (
    <ListGroup variant="flush" className="justify-content-between">
      <ListGroup.Item>
        上傳營業登記證 :{" "}
        <input
          type="file"
          name="registrationCertificate"
          onChange={handleInputFile}
          accept="image/png, image/jpeg, image/jpg"
          capture
          ref={certificateElement}
        />
      </ListGroup.Item>
      <ListGroup.Item>
        上傳轉帳存摺 :{" "}
        <input
          type="file"
          name="passbook"
          onChange={handleInputFile}
          accept="image/png, image/jpeg, image/jpg"
          ref={passbookElement}
        />
      </ListGroup.Item>
      <br />
      <Col className="text-center" xs={12}>
        {loading ? (
          <Spinner as="span" animation="border" />
        ) : (
          <Button
            as={Col}
            xs={10}
            onClick={handleSubmit}
            disabled={!loading}
          >下一步</Button>
        )}
      </Col>
    </ListGroup>
  );
};

const formItem = [
  {
    title: "負責人姓名",
    name: "name",
    require: true,
  },
  {
    title: "手機",
    name: "phone",
    require: true,
  },
  {
    title: "E-mail",
    name: "email",
    require: true,
  },
  {
    title: "店名",
    name: "storeName",
    require: true,
  },
  {
    title: "統一編號",
    name: "storeNumber",
    require: true,
  },
  {
    title: "行業別",
    name: "industry",
    require: true,
  },
  {
    title: "城市",
    name: "city",
    require: true,
  },
  {
    title: "區",
    name: "district",
    require: true,
  },
  {
    title: "地址",
    name: "road",
    require: true,
  },
];

const InfoForm = () => {
  const [infoData, setInfoData] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const { addToast } = useToast();

  useEffect(() => {
    const existform = JSON.parse(localStorage.getItem("infoData"));

    if (existform) {
      setInfoData(existform);
    } else {
      setInfoData({ ...infoData, role: match.params.storeType });
    }

  }, []);

  const handleSubmit = async (event) => {
    //store data to localStorage

    setLoading(true);

    event.preventDefault();

    let result = await Http_request_post(
      "register/shop/checkInfoData",
      infoData
    );

    setLoading(false);

    addToast(result.message, { appearance: result.toast });

    if (result.data) {
      localStorage.setItem("infoData", JSON.stringify(infoData));

      //防止讀取之前的資料
      localStorage.removeItem("currentShopID");

      history.replace("/BasicForm/CertificateForm");
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;

    const name = target.name;

    setInfoData((pre) => ({ ...pre, [name]: target.value }));
  };

  const formItemTemplate = formItem.map((item) => {
    return (
      <ListGroup.Item key={item.name}>
        {item.title} :{" "}
        <input
          type="text"
          name={item.name}
          value={infoData[item.name] || ""}
          onChange={handleInputChange}
          required={item.require}
        />
      </ListGroup.Item>
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      <ListGroup variant="flush" className="justify-content-between">
        {formItemTemplate}
      </ListGroup>
      <Col className="text-center" xs={12}>
        {loading ? (
          <Spinner as="span" animation="border" />
        ) : (
          <Button block as="input" type="submit" value="Next" />
        )}
      </Col>
    </Form>
  );
};

const BasicForm = ({ route, location }) => {
  let breadcrumbs = [];

  for (let step of route.routes) {
    breadcrumbs.push(step);
    if (location.pathname === step.path) {
      break;
    }
  }

  const breadcrumbsTemplate = breadcrumbs.map((route, idx) => {
    return (
      <Breadcrumb.Item key={route.breadcrumbName}>
        <Link to={route.path}>{route.breadcrumbName}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <Container>
      <Nav fill className="text-center text-primary">
        <Nav.Item>
          <h3>店家申請表</h3>
        </Nav.Item>
      </Nav>
      <Breadcrumb as={Nav}>{breadcrumbsTemplate}</Breadcrumb>
      {renderRoutes(route.routes)}
    </Container>
  );
};

export { BasicForm, InfoForm, CertificateForm, BuyBuildService, Contract };

import { initializeApp } from 'firebase/app';
import {getAuth,GoogleAuthProvider} from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCaCASTQhs52dXT8vdYIVkVsF4wIOoXF0Y",
    authDomain: "nearme-web-push.firebaseapp.com",
    projectId: "nearme-web-push",
    storageBucket: "nearme-web-push.appspot.com",
    messagingSenderId: "474258249547",
    appId: "1:474258249547:web:610429f550abc101a33ace",
    measurementId: "G-WVL6RGYB7D"
};

export const initFirebase = () => {
    console.log('init firebase');
    return initializeApp(firebaseConfig);
}
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
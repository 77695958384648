import { APIFetchReview } from "API/order";
import { APIStoreCollec } from "API/user";
import { Http_request_post, Http_request_get } from "../../Service/HttpService";

/*----------------------------------------------------------------------API Req ----------------------------------------------------------------------*/
export const qrcodeCheckin = async (shopID) => {
  let api = `user/qrcodeCheckin/checkin`;
  let result = await Http_request_post(api, { shopID });
  return result;
};

export const fetchStoreCredit = async (shopID) => {
  let api = `area/searchStore/storeCredit?shopID=${shopID}`;

  let result = await Http_request_get(api);

  if (!result.data && result.data != 0)
    throw new Error("Occurred error when fetch store credit.");

  return parseInt(result.data);
};

export const fetchProductReview = async (productID) => {
  let query = new URLSearchParams({ productID });

  let result = await Http_request_get(APIFetchReview + `?${query}`);

  if (!result.data && result.data != 0) throw new Error(result.message);

  return result.data;
};

export const fetchStoreReview = async (shopID) => {};

/*----------------------------------------------------------------------API Req ----------------------------------------------------------------------*/

export const productPopupTag = (periodType, timeFormat) => {
  let { days, hours, minutes, seconds } = timeFormat;
  let result;
  let daysTemp = days > 0 ? `${days}天` : ``;
  let hoursTemp = hours > 0 ? `${hours}時` : `00hr`;

  switch (periodType) {
    case "day":
      result = `${daysTemp} ${hoursTemp} ${minutes || "00"}:${seconds}`;
      break;
  }

  return result;
};

export const isValidSold = (product) => {
  let isValid = true;
  const fullN = /^\d+$/;

  let { originalPrice, specialPrice } = product.price;

  let { specList, noNeedSpecification, noNeedInventory, productInventory } =
    product.specification;

  /*----------------------------------------------------------------------Price--------------------------------------------------------------------------------------- */
  if (!originalPrice && !specialPrice) isValid = false;

  /*----------------------------------------------------------------------Specification & ProductInventory------------------------------------------------------------ */
  if (!noNeedSpecification) {
    let withoutTitle = specList.find((e) => !e?.title?.trim());

    if (withoutTitle) isValid = false;

    if (specList.length === 0) isValid = false;
  }

  if (!noNeedInventory) {
    let withoutInventory = specList.find(
      (e) => e?.productInventory < 0 || !fullN.test(e?.productInventory)
    );

    if (withoutInventory) isValid = false;
  } else {
    if (productInventory < 0 || !fullN.test(productInventory)) isValid = false;
  }

  return isValid;
};

export const isSoldOut = (product) => {
  let { noNeedInventory, noNeedSpecification, productInventory, specList } =
    product.specification;
  if (noNeedInventory) return false;
  if (noNeedSpecification) return productInventory === 0;
  return specList.every((e) => e.productInventory === 0);
};

export const isValidCatchCoupon = (product, user, coupons) => {
  if (!product.couponName) return "noCoupon";

  let captureStatus = "valid";

  let existCoupon = coupons.some(
    (e) => e.coupon.productID === product._id && e.getway !== "pay"
  );

  if (existCoupon) captureStatus = "exist";

  if (!product.couponTotalAmount) captureStatus = "soleOut";

  if (user?._id) {
    //只計算優惠券的部分
    let records = user.couponRecords.filter(
      (e) => e.coupon.productID === product._id && e.getway !== "pay"
    );

    if (records.length >= product.ownLimit) captureStatus = "creditOut";

    let isSameDay = records.find((e) => {
      return Date.now() - new Date(e.createdAt).getTime() < 24 * 60 * 60 * 1000;
    });

    if (isSameDay) captureStatus = "exist";
  }
};

//For coupon
export const couponValid = (user, product, ownCoupons) => {
  /*-------------------------------------------If own or catch over limit-----------------------------------------------------------*/

  let captureStatus = "valid";

  let currentShopCoupon = ownCoupons.filter(
    (coupon) => coupon.sellerData.seller === product.shopID
  );

  let existCoupon = currentShopCoupon.some(
    (e) => e.coupon?.productID === product._id && e.getway !== "pay"
  );

  if (existCoupon) captureStatus = "exist";

  if (!product.couponTotalAmount) captureStatus = "soleOut";

  if (user?._id) {
    //只計算優惠券的部分
    let records = currentShopCoupon.filter(
      (e) => e.coupon?.productID === product._id && e.getway !== "pay"
    );

    if (records.length >= product.ownLimit) captureStatus = "creditOut";

    let isSameDay = records.find((e) => {
      return Date.now() - new Date(e.createdAt).getTime() < 24 * 60 * 60 * 1000;
    });

    if (isSameDay) captureStatus = "exist";
  }

  if (!product.couponName) captureStatus = "noCoupon";
};

export const isOverCredit = (
  originalPrice,
  specialPrice,
  paymentCreditSurplus
) => {
  if (specialPrice) return paymentCreditSurplus - specialPrice <= 0;
  return paymentCreditSurplus - originalPrice <= 0;
};

export const collectionStore = async (shopID) => {
  let result = await Http_request_post(APIStoreCollec, { shopID });
  if (!result.data) throw new Error(result.message);
  return result.data;
};

export const loadImage = (entry) => {
  let images = entry.target.querySelectorAll("[data-src]");

  images.forEach((image) => {
    let src = image.getAttribute("data-src");

    if (!src) return;
    // 設定圖片的 src 並放入圖片位址讓瀏覽器載入
    image.src = src;
  });
};

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  ListGroup,
  Row,
  Button,
  Alert,
  Modal,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faHandsHelping,
  faQuestionCircle,
  faStore,
  faTag,
  faUser,
  faHandPointUp,
  faReceipt,
  faSignOutAlt,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/ToastProvider";

import { useUser } from "Provider/UserProvider";
import "./UserInfoHome.css";
import { FAQModal } from "Components/FAQ/FAQ";

const SelectModal = ({ show, onHide, ownStores }) => {
  const [ownS, setOwnS] = useState(ownStores);
  const [errMsg, setErrMsg] = useState("");
  const { fetchOwnStores } = useUser();
  let domainName = window.location.hostname.includes("devp")
    ? `devp.manage.nearmespecials.com`
    : `manage.nearmespecials.com/`;
  let token = localStorage.getItem("token");

  const shop_selector_list = ownS?.map((e) => {
    let shopID = e.shopID;

    let url = `https://${domainName}/shop/${shopID}`;
    return (
      <ListGroup.Item
        key={e.shopID}
        className="my-2 text-muted"
        onClick={() => window.open(url)}
      >
        <strong>{e.shopName}</strong>
      </ListGroup.Item>
    );
  });

  // useEffect(() => {
  //   fStores();
  // }, []);

  const fStores = async () => {
    setErrMsg("");
    try {
      let s = await fetchOwnStores();
      s = s.map((e) => {
        return {
          shopID: e._id,
          shopName: e.shopData.storeName,
        };
      });

      setOwnS(s);
    } catch (err) {
      setErrMsg(err.message);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="d-flex align-items-end"
      scrollable
      onEnter={fStores}
    >
      <Modal.Header closeButton>選擇商店</Modal.Header>
      <Modal.Body>
        {errMsg && <Alert variant="danger">{errMsg}</Alert>}
        <ListGroup variant="flush">{shop_selector_list}</ListGroup>
      </Modal.Body>
    </Modal>
  );
};

const WebPushAlert = () => {
  const [webPushPermission, setWebPushPermission] = useState("");

  useEffect(() => {
    try {
      if (Notification) setWebPushPermission(Notification.permission);
    } catch (_) {}
  }, []);

  const { getWebPushToken } = useUser();
  const { addToast } = useToast();

  const handleClick = async () => {
    try {
      let result = await getWebPushToken();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  // if (webPushPermission !== "default") return null;

  return (
    <Alert variant="info" className="m-2">
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <h6>我想收到最新消息!</h6>
        </Col>
        <Col xs="auto">
          <Button onClick={handleClick}>開啟通知</Button>
        </Col>
      </Row>
    </Alert>
  );
};

export const UserMenuMobile = () => {
  const { user, handleLogout, isLogin, redirectAfterLogin } = useUser();
  const [show, setShow] = useState(false);
  const [faqShow, setFaqShow] = useState(false);

  const internalLink = [
    // { title: "客服", link: "userInfo/customer_service", icon: faHandsHelping },
    { title: "帳戶", link: "/user/userInfo/account_information", icon: faUser },
    { title: "禮物", link: "/user/order", icon: faReceipt },

    {
      title: "如何使用",
      link: "/FAQ",
      icon: faQuestionCircle,
    },
  ];

  const externalLink = [
    {
      title: "聯繫客服",
      link: `https://docs.google.com/forms/d/e/1FAIpQLSeHHyH3NmrIXkJdKVi0MkMw9I1ScDrIrckHhwG2LIFnBH7Pew/viewform?usp=sf_link`,
      icon: faHandsHelping,
    },
    {
      title: "我想加入",
      link: `https://docs.google.com/forms/d/e/1FAIpQLSf8J-LLBOnCnJAvVqmNhh9s_Tue5ciZE67U-aE72lErfq1SZg/viewform?usp=sf_link`,
      icon: faHandPointUp,
    },
  ];

  const tutorial = [
    // {
    //   content: "如何送禮",
    //   image:
    //     "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/banner-tutorial-gift.png",
    //   link: "https://blog.nearmespecials.com/tutorial-gift",
    // },
    // {
    //   content: "商店合作",
    //   image:
    //     "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/banner-tutorial-parner.png",
    //   link: "https://blog.nearmespecials.com/giftbiz/",
    // },
  ];

  const InternalList = ({ items }) => {
    return (
      <ListGroup variant="flush">
        {items.map((item) => {
          return (
            <ListGroup.Item
              className="my-2"
              key={item.title}
              as={Link}
              to={item.link}
            >
              <Stack gap={3} direction="horizontal">
                <FontAwesomeIcon icon={item.icon} className="text-primary" />
                {item.title}
                <FontAwesomeIcon icon={faChevronRight} className="ms-auto" />
              </Stack>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const ExternalList = ({ items }) => {
    return (
      <ListGroup variant="flush">
        {items.map((item) => {
          return (
            <ListGroup.Item
              key={item.title}
              onClick={() => window.open(item.link)}
              className="my-2"
              as="a"
              target="_blank"
            >
              <Stack gap={3} direction="horizontal">
                <FontAwesomeIcon icon={item.icon} className="text-primary" />
                {item.title}
                <FontAwesomeIcon icon={faChevronRight} className="ms-auto" />
              </Stack>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  return (
    <div>
      {/* <WebPushAlert /> */}
      {tutorial.map((e) => (
        <section
          className="tutorial-container my-2"
          onClick={() => window.open(e.link)}
          key={e.link}
        >
          <img src={e.image} className="tutorial-img"></img>
          <h4 className="tutorial-content">{e.content}</h4>
        </section>
      ))}
      <div>
        <InternalList items={internalLink} />
        <hr />
        <ExternalList items={externalLink} />

        <Stack className="mt-3">
          {isLogin ? (
            <Button variant="outline-danger" onClick={() => handleLogout()}>
              登出
            </Button>
          ) : (
            <Button onClick={redirectAfterLogin} className="mt-3">
              登入
            </Button>
          )}
        </Stack>
        <SelectModal
          show={show}
          onHide={() => setShow(false)}
          ownStores={user?.shops}
        />
      </div>
      {/* {isLogin ? (
        <div>
          <InternalList items={internalLink} />
          <hr />
          <ExternalList items={externalLink} />

          <Stack className="mt-3">
            <Button variant="outline-danger" onClick={() => handleLogout()}>
              登出
            </Button>
          </Stack>
          <SelectModal
            show={show}
            onHide={() => setShow(false)}
            ownStores={user?.shops}
          />
        </div>
      ) : (
        <Stack>
          <ExternalList items={externalLink} />
          <hr />
        </Stack>
      )} */}
    </div>
  );
};

export const UserMenuDesktop = () => {
  const { user, handleLogout } = useUser();

  const options = [
    // { title: "客服", link: "userInfo/customer_service", icon: faHandsHelping },
    { title: "帳戶", link: "/user/userInfo/account_information", icon: faUser },
    { title: "禮物", link: "/user/order", icon: faReceipt },
    {
      title: "如何使用",
      link: "/FAQ",
      icon: faQuestionCircle,
    },
  ];

  const outsideBlock = [
    {
      title: "聯繫客服",
      link: `https://docs.google.com/forms/d/e/1FAIpQLSeHHyH3NmrIXkJdKVi0MkMw9I1ScDrIrckHhwG2LIFnBH7Pew/viewform?usp=sf_link`,
      icon: faHandsHelping,
    },
    {
      title: "我想加入",
      link: `https://docs.google.com/forms/d/e/1FAIpQLSf8J-LLBOnCnJAvVqmNhh9s_Tue5ciZE67U-aE72lErfq1SZg/viewform?usp=sf_link`,
      icon: faHandPointUp,
    },
  ];

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-primary"
    >
      {children}
    </span>
  ));

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="user-menu-dropdown-toggle">
          <FontAwesomeIcon
            icon={faBars}
            style={{ width: "20px", height: "20px" }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ width: "300px", borderRadius: "20px" }}
          className="shadow-sm px-2 py-3"
        >
          {options.map((item) => (
            <Dropdown.Item as={Link} to={item.link} key={item.title}>
              <Stack gap={3} direction="horizontal">
                <FontAwesomeIcon icon={item.icon} className="text-primary" />
                {item.title}
                <FontAwesomeIcon icon={faChevronRight} className="ms-auto" />
              </Stack>
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />

          {outsideBlock.map((item) => (
            <Dropdown.Item
              as={"a"}
              target="_blank"
              href={item.link}
              key={item.title}
            >
              <Stack gap={3} direction="horizontal">
                <FontAwesomeIcon icon={item.icon} className="text-primary" />
                {item.title}
                <FontAwesomeIcon icon={faChevronRight} className="ms-auto" />
              </Stack>
            </Dropdown.Item>
          ))}
          <Dropdown.Item onClick={() => handleLogout()}>
            <Stack gap={3} direction="horizontal">
              <FontAwesomeIcon icon={faSignOutAlt} className="text-primary" />
              {"登出"}
              <FontAwesomeIcon icon={faChevronRight} className="ms-auto" />
            </Stack>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <SelectModal
        show={show}
        onHide={() => setShow(false)}
        ownStores={user?.shops}
      /> */}
    </div>
  );
};

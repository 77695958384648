import React, { useState } from "react";
import { Form, Row, Col, Spinner, Button, InputGroup } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useToast } from "Provider/ToastProvider";

import { Http_request_post } from "Service/HttpService";
import { APILogin } from "API/user";

export const PasswordLogin = ({ callback }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwShow, setPwShow] = useState("");
  const [pending, setPending] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPending(true);

    try {
      let result = await Http_request_post(APILogin, { email, password });

      if (!result.data) throw new Error(result.message);

      let { user, token } = result.data;

      callback(user, token);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {/* <Row> */}
        <Form.Control
          type="email"
          placeholder="信箱"
          className="my-2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {/* </Row> */}
        {/* <Row> */}
        <InputGroup>
          <Form.Control
            type={pwShow ? "text" : "password"}
            placeholder="密碼"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button variant="outline-primary" onClick={() => setPwShow(!pwShow)}>
            <FontAwesomeIcon icon={pwShow ? faEye : faEyeSlash} />
          </Button>
        </InputGroup>
        {/* </Row> */}
        <Row className="justify-content-center my-3">
          <Col>
            <Button
              type="submit"
              variant="outline-secondary"
              className="w-100"
              disabled={pending}
            >
              {pending ? <Spinner animation="border" /> : "登入"}
            </Button>
          </Col>
          <Button
            as={Col}
            variant="secondary"
            className="mx-2"
            onClick={() => history.push("/login/register")}
          >
            註冊
          </Button>
        </Row>
      </Form>
    </div>
  );
};

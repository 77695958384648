import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { renderRoutes } from "react-router-config";
// import { DefaultToastContainer, ToastProvider } from "react-toast-notifications";
import './App.css';

import routes from './route/routers';
import ErrorBoundary from "./Valid_middleware/ErrorBoundary";
import { LoadingProvider, UserProvider } from "./Provider/UserProvider";
import { BrowserDetectProvider } from "./Valid_middleware/BrowserDetect";
// import { onMessageListener } from "./Firebase";
import { GeoLocationProvider } from 'Provider/LocationProvider';
import { ToastProvider } from 'Provider/ToastProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// //override the container to cover footer
// const ToastContainer = props => (
//   <DefaultToastContainer style={{ zIndex: 2000 }} {...props} />
// )

function App() {
  // const [notification, setNotification] = useState();

  // if ("Notification" in window) {
  //   onMessageListener().then(payload => {
  //     setNotification(payload.notification);
  //   }).catch(err => console.log(err))
  // }

  // useEffect(() => {
  //   if (!notification?.title) return;

  //   const { title, ...options } = notification;

  //   navigator.serviceWorker.ready.then(registration => {
  //     registration.showNotification(title, options);
  //   });

  //   return () => setNotification(null);
  // }, [notification])

  const queryClient = new QueryClient();

  return (
    <React.Fragment>
      <BrowserDetectProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <LoadingProvider>
              <UserProvider>
                <ToastProvider>
                  {/* <ToastProvider autoDismiss="true" autoDismissTimeout="3000" components={{ ToastContainer }}> */}
                  {/* <GeoLocationProvider> */}
                  {renderRoutes(routes)}
                  {/* </GeoLocationProvider> */}
                  {/* </ToastProvider> */}
                </ToastProvider>
              </UserProvider>
            </LoadingProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </BrowserDetectProvider>
    </React.Fragment>
  );
}

export default App;

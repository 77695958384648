import React, { useState } from 'react';
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/ToastProvider";

import { Http_request_post } from "../../Service/HttpService";

export const gradeStore = (shopID, score, message) => {
    return Http_request_post('user/grade/store', { shopID, score, message });
}

export const ScoreTemp = ({ color, callback }) => {
    return (
        <span>
            <FontAwesomeIcon icon={faStar} style={{ color }} onClick={callback} />
        </span>
    )
}

export const GradeStore = ({ shopID }) => {
    const [data, setData] = useState({ score: 0, message: "" });
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const { addToast } = useToast();

    const handleGrade = async () => {
        setLoading(true);

        let result = await gradeStore(shopID, data.score, data.message);

        setLoading(false);

        if (!result.data) {
            addToast(result.message, { appearance: result.toast });
            return;
        }

        setIsDone(true);
    }

    return (
        <div className='text-center'>
            {isDone ? <h6>感謝評分</h6> : <h6>給店家一個評價吧😊</h6>}
            <section className={isDone ? "d-none" : ""}>
                <Row className={`justify-content-around`}>
                    {[1, 2, 3, 4, 5].map((e, idx) => {
                        let color = idx >= data.score ? "gray" : "gold"
                        return <Col xs="auto" key={e}><ScoreTemp color={color} callback={() => setData({ ...data, score: parseInt(e) })} /></Col>
                    })}
                </Row>
                <br />
                <Form.Control type='text' value={data.message || ""} onChange={(e) => setData({ ...data, message: e.target.value })} />
                <br />
                <Row className='justify-content-center'>
                    <Col xs="auto">
                        <Button onClick={handleGrade}>{loading ? <Spinner animation='border' /> : "提交"}</Button>
                    </Col>
                </Row>
            </section>
        </div>
    )
}
import React from "react";
import { Row, Col, Spinner, Stack } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const LoadingPage = ({ title }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center bg-light sticky-top"
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        opacity: "0.8",
        top: 0,
        left: 0,
      }}
    >
      <h4 className="text-muted">{title}</h4>
      <Spinner animation="grow" />
    </div>
  );
};

export const LoadingSection = () => {
  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <Spinner animation="border" />
      </Col>
    </Row>
  );
};

export const ScrapeLoading = () => {
  return (
    <Stack gap={3} className="text-primary">
      <strong className="text-muted text-center">即時搜尋需要1~5秒</strong>
      <Spinner className="mx-auto" />
    </Stack>
  );
};

import React from "react";
import { Row, Col } from "react-bootstrap";

export const GiftTutorial = () => {
  const steps = [
    {
      imgUrl:
        "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/user-guide/gift-req-tutorial-1.png",
      title: "搜尋商品",
      content: "搜尋商品",
    },
    {
      imgUrl:
        "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/user-guide/gift-req-tutorial-2.png",
      title: "完成結帳",
      content: "在NearMe完成結帳，就可獲得禮物連結",
    },
    {
      imgUrl:
        "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/user-guide/gift-req-tutorial-3.png",
      title: "發送禮物",
      content: "將禮物連結發送給收禮者，由收禮者決定是否更改或收下禮物",
    },
  ];

  const Item = ({ imgUrl, title, content }) => {
    return (
      <Row xs={2} md={1} className="align-items-center">
        <Col>
          <img
            src={imgUrl}
            style={{ width: "100%", maxWidth: "360px" }}
            className="my-2"
          />
        </Col>
        <Col>
          <section>
            <p className="gift-req-tutorial-title">{title}</p>
            <p className="gift-req-tutorial-content">{content}</p>
          </section>
        </Col>
      </Row>
    );
  };
  return (
    <div>
      <section className="text-center">
        <h1 className="text-primary">
          <strong>如何送禮</strong>
        </h1>
        <strong>快速又方便的網路送禮方式</strong>
      </section>

      <br />
      <Row xs={1} md={3} className="text-center">
        {steps.map((e) => (
          <Col key={e.title}>
            <Item imgUrl={e.imgUrl} title={e.title} content={e.content} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

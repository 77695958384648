import React from "react";
import {
  ListGroup,
  Row,
  Col,
  Container,
  Button,
  Accordion,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import {ReactComponent as ChevronDownIcon} from "../../svg/link_icon/chevron-down-solid.svg"
import { Link } from 'react-router-dom';

const QAData = [
  {
    title: "為何要加入NearMe",
    content: "因為...",
  },
  {
    title: "我適合加入嗎？",
    content: "可以...",
  },
  {
    title: "NearMe如何收費",
    content: "收費...",
  },
];

const QAListTemplate = ({ data }) => {
  return (
    <ListGroup.Item>
      <Accordion>
        <Row>
          <h5>{data.title}</h5>
          <Accordion.Toggle as={Button} variant="link" eventKey={data.title}>
            <ChevronDownIcon />
          </Accordion.Toggle>
        </Row>
        <Accordion.Collapse eventKey={data.title}>
          <Row>
            <h5>{data.content}</h5>
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </ListGroup.Item>
  );
};

const QAS = QAData.map((e) => {
  return <QAListTemplate data={e} key={e.title} />;
});

const RegisterStore = () => {
  return (
    <Container>
      <ListGroup variant="flush">{QAS}</ListGroup>
      <br />
      <Col xs={12} className="text-center">
      <Link to={'/BasicForm/InfoForm/' + process.env["REACT_APP_SHOP_ROLE_GENERAL"]}><Button as={Col} xs={10}>申請</Button></Link>
      </Col>
    </Container>
  );
};

export { RegisterStore };

import React, { useState } from "react";
import { Card, ListGroup, Button} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const FakeData = {
  session: [
    {
      location: "台北",
      time: ["10AM"],
    },
    {
      location: "桃園",
      time: ["1PM"],
    },
  ],
  industry: [
    {
      type: "餐飲業",
      time: "",
    },
    {
      type: "服飾業",
      time: "",
    },
  ],
  localtion: [
    {
      city: "台北市",
      block: ["大安"],
    },
    {
      city: "桃園市",
      block: ["桃園"],
    },
  ],
};

const Location_Dropdown = ({data}) => {
    const [city, setCity] = useState("");

  const list_city = data.map((element) => {
    return <option key={element.city}>{element.city}</option>;
  });

  const list_block = (city) => {
    if (!city || city === "") {
      return <option>請選取地區</option>;
    }
    let item = data.find((element) => {
      return element.city === city;
    });

    return item.block.map((element) => {
      return <option key={element}>{element}</option>;
    });
  };

  return (
    <React.Fragment>
      <Button as="select" variant="outline-primary rounded-pill" onChange={e => setCity(e.target.value)}>
        <option hidden>城市</option>
        {list_city}
      </Button>

      <Button as="select" variant="outline-primary rounded-pill">
        <option hidden>地區</option>
        {list_block(city)}
      </Button>
    </React.Fragment>
  );
};

const Industry_Dropdown = ({data}) => {
    return (
        <React.Fragment>
          <Button as="select" variant="outline-primary rounded-pill">
            <option hidden>行業別</option>
          </Button>
    
          <Button as="select" variant="outline-primary rounded-pill">
            <option hidden>時間</option>
          </Button>
        </React.Fragment>
      );
}

const Session_Dropdown = ({ data }) => {
  const [location, setLocation] = useState("");

  const session_location = data.map((element) => {
    return <option key={element.location}>{element.location}</option>;
  });

  const list_time = (location) => {
    if (!location || location === "") {
      return <option>請選取地區</option>;
    }
    let item = data.find((element) => {
      return element.location === location;
    });

    return item.time.map((element) => {
      return <option key={element}>{element}</option>;
    });
  };

  return (
    <React.Fragment>
      <Button
        as="select"
        variant="outline-primary rounded-pill"
        onChange={(e) => {
          setLocation(e.target.value);
        }}
      >
        <option hidden>地區</option>
        {session_location}
      </Button>

      <Button as="select" variant="outline-primary rounded-pill">
        <option hidden>時間</option>
        {list_time(location)}
      </Button>
    </React.Fragment>
  );
};

const handle_submit = ({ data }) => {};

const Registration_Form = () => {
  return (
    <Card className="text-primary">
      <Card.Body>
        <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between">
            說明會場次{"  "} <Session_Dropdown data={FakeData.session} />
          </ListGroup.Item>
          <ListGroup.Item>
            姓名{"  "}
            <input
              type="text"
              className="border-0"
              placeholder="輸入"
              required
            />
          </ListGroup.Item>
          <ListGroup.Item>
            手機{"  "}
            <input
              type="text"
              className="border-0"
              placeholder="輸入"
              required
            />
          </ListGroup.Item>
          <ListGroup.Item>
            E-mail{"  "}
            <input type="email" className="border-0" placeholder="輸入" />
          </ListGroup.Item>
          <ListGroup.Item>
            店名{"  "}
            <input
              type="text"
              className="border-0"
              placeholder="輸入"
              required
            />
          </ListGroup.Item>
          <ListGroup.Item>
            統一編號{"  "}
            <input
              type="text"
              className="border-0"
              placeholder="輸入"
              required
            />
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            行業別 {"  "}
          </ListGroup.Item>
          <ListGroup.Item>
            城市 {"  "} <Location_Dropdown data={FakeData.localtion} />
          </ListGroup.Item>
          <ListGroup.Item>地址</ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export { Registration_Form };

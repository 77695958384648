import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGifts,
  faHeart,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import "./About.css";

const About = () => {
  return (
    <div className="about-container">
      <h1>關於 NearMe</h1>

      <div className="story-section">
        <h2>我們的故事</h2>
        <p>NearMe 的誕生源自於一次貼心卻尷尬的送禮經歷。</p>
        <p>
          創辦人J先生曾收到妻子精心挑選的一隻價值3萬元的SEIKO手錶，卻因為不習慣戴手錶，這份禮物最終被冷落在抽屜裡。
        </p>
        <p>
          這樣的故事讓J先生意識到，許多人都有送錯禮物的困擾，這也啟發了他創建NearMe的想法——幫助每一個送禮人確保對方能收到真正喜愛的禮物。
        </p>
      </div>

      <div className="mission-section">
        <h2>我們的使命</h2>
        <div className="mission-icons">
          <div className="icon-item">
            <FontAwesomeIcon icon={faGifts} className="icon" />
            <p>靈活送禮</p>
          </div>
          <div className="icon-item">
            <FontAwesomeIcon icon={faHeart} className="icon" />
            <p>傳遞情感</p>
          </div>
          <div className="icon-item">
            <FontAwesomeIcon icon={faHandshake} className="icon" />
            <p>滿足需求</p>
          </div>
        </div>
        <p>
          NearMe的使命是通過靈活的送禮模式，讓收禮人能夠自由選擇或更換禮物，從而確保每一份心意都能被準確傳遞。
        </p>
        <p>我們相信，送禮不僅僅是一次物品的交換，而是一份情感的傳遞。</p>
      </div>

      <div className="journey-section">
        <h2>我們的旅程</h2>
        <p>
          自2017年構思以來，NearMe團隊一路跌跌撞撞，面對了許多意想不到的挑戰，包括法規瓶頸和不斷調整的商業模式。
        </p>
        <p>
          儘管過程艱辛，我們始終堅持初心，最終在2024年成功推出了NearMe送禮平台。
        </p>
      </div>

      <div className="vision-section">
        <h2>我們的願景</h2>
        <p>
          在NearMe，我們致力於解決禮物與需求不匹配的問題，讓每一次送禮都充滿溫暖與滿足感。
        </p>
        <p>我們期待與您一同創造更多美好的送禮體驗。</p>
      </div>
    </div>
  );
};

export default About;

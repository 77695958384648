import { Card } from "react-bootstrap";

export const RefundNotice = () => {
  return (
    <Card
      className="shadow-sm"
      style={{
        borderTop: "0",
        borderLeft: "3px solid green",
        borderBottom: "0",
        borderRight: "0",
        borderRadius: "6px",
      }}
    >
      <Card.Body className="text-muted" style={{ fontSize: "14px" }}>
        <p>-如果收禮人10天內未選擇收下或更改，則禮物連結將自動失效(不扣款).</p>
        <p>-如果更改金額較高的禮物，則由收禮人刷卡補差額.</p>
        <p>-完成收件資訊後才啟動扣款，及發送電子發票.</p>
      </Card.Body>
    </Card>
  );
};

export const ProductContentDisclaimer = () => {
  return (
    <Card>
      <Card.Body className="text-muted" style={{ fontSize: "14px" }}>
        <small>
          商品資訊內容僅供參考,包括包裝/交期/運費/折扣優惠...等,如果有誤,以NearMe客服通知內容為準
        </small>
      </Card.Body>
    </Card>
  );
};

import React, { useContext, createContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const addToast = (msg, options = { appearance: "primary" }) => {
    let { appearance } = options;
    switch (appearance) {
      case "error":
        toast.error(msg);
        break;
      case "success":
        toast.success(msg);
        break;
      default:
        toast.info(msg);
    }
  };

  const addAwaitToast = (fn) => {
    toast.promise(fn, {
      pending: "處理中",
      success: "成功",
      error: {
        render(err) {
          return err.message;
        },
      },
    });
  };

  return (
    <ToastContext.Provider value={{ addToast, addAwaitToast }}>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} closeOnClick={false} />
      {children}
    </ToastContext.Provider>
  );
};

import React from "react";
import { Col, Row, Stack } from "react-bootstrap";

const EmptyPage = ({ children, title, h = "80vh" }) => {
  return (
    <Row
      className="align-items-center justify-content-center text-center"
      style={{ height: h }}
      xs={1}
    >
      <Col xs="auto">
        <h2 style={{ opacity: ".5" }}>{title || "查無資料"}</h2>
        {children}
      </Col>
    </Row>
  );
};

export { EmptyPage };

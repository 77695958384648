import { APIGradeReview } from "API/order";
import { Http_request_post } from "Service/HttpService";

/**
 * @param {String} orderID - target product of order
 * @param {Object} reviewData - review detail, include { score, message }
 */
export const gradeStore = async (id, { score, message }) => {
  let result = await Http_request_post(APIGradeReview, { orderId: id, score, message });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  CouponCheckModal,
  CouponList,
} from "../../Components/Coupon/Coupon_template";
import { useUser } from "Provider/UserProvider";
import { CashOrder } from "User/Payment/PaymentPage";

export const OrderStorage = () => {
  // const [coupons, setCoupons] = useState([]);
  const [show, setShow] = useState(false);
  const [targetOrder, setTargetOrder] = useState({});
  const location = useLocation();
  const { fetchOrder, ownOrder } = useUser();

  const sortOrder = useMemo(() => {
    return ownOrder
      .filter((e) => e.status === process.env["REACT_APP_COUPON_STATUS_CATCH"])
      .sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
  }, [ownOrder]);

  //Fetch coupon
  useEffect(() => {
    fetchOrder();
  }, []);
  //Move to sepcificate coupon position if and .
  useEffect(() => {
    let moveTo = new URLSearchParams(location.search).get("couponID");

    if (!moveTo) return;

    let ele = document.getElementById(`${moveTo}`);

    if (ele) ele.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [location]);

  const modal_handle = (orderContent) => {
    setTargetOrder(orderContent);
    setShow(true);
  };

  const EmptyPage = () => {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "90vh" }}
      >
        <h2 style={{ opacity: ".5" }}>目前沒有優惠券</h2>
      </div>
    );
  };

  return (
    <section>
      {ownOrder.length === 0 && <EmptyPage />}
      {sortOrder.map((coupon) => (
        <CouponList
          data={coupon}
          key={coupon._id}
          show_modal={() => modal_handle(coupon)}
        />
      ))}
      {/* {targetOrder?.orderType === "product" && (
        <CouponCheckModal
          data={targetOrder}
          show={show}
          onHide={() => setShow(false)}
        />
      )}
      {}
      <CashOrder /> */}
    </section>
  );
};

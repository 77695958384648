import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const useUrlQuery = () => {
  const history = useHistory();
  const location = useLocation()
  const updateQueryParams = (newParams) => {
    const searchParams = new URLSearchParams(location.search);
    for (const [key, value] of Object.entries(newParams)) {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    }
    history.push({ search: searchParams.toString() });
  };
  return { updateQueryParams };
};

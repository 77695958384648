import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Card,
  Badge,
  Button,
  Row,
  Col,
  Image,
  Modal,
  Spinner,
  Table,
  Alert,
  Form,
  ListGroup,
  Stack,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useToast } from "Provider/ToastProvider";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faPlusCircle,
  faMinusCircle,
  faGift,
  faCopy,
  faMinus,
  faPlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as CheckCircleIcon } from "../../svg/check-circle-regular.svg";
import { useUser } from "../../Provider/UserProvider";
import { isInRange } from "../../Service/timeUtils";
import "./Coupon_template.css";
import { GradeStore } from "../Grade/GradeTemplate";
import {
  refundCoupon,
  fetchShopDataByshopID,
  requestGiftUrl,
  catchCoupon,
  checkOrder,
  getCatchStatusMessage,
  refundCash,
  checkCash,
  queryCOstore,
  fetchGiftInfo,
  checkGift,
} from "./CouponUtils";
import { OverflowText } from "Components/Text/OverflowText";
import { copyShare } from "../../Service/CopyBoardService";
import { useClipboard } from "Hook/Clipboard/useClipboard";
import { ScrollController } from "Components/ScrollController";
import { useDebounce } from "Hook/useDebounce";

const ShopBookingWays = ({ shopID }) => {
  const [shopData, setShopData] = useState("init");

  useEffect(() => {
    fetchShopDataByshopID(shopID)
      .then((res) => setShopData(res))
      .catch((err) => {
        setShopData(null);
      });
  }, []);

  const getHref = (data) => {
    if (/[0-9]{9}/g.test(data)) return `tel:+886-${data}`;

    if (/[0-9]{10}/g.test(data)) return `tel:+886-${data}`;

    return data;
  };

  return (
    <details className="general-details text-muted">
      <summary>
        <Row>
          <Col>預約方式</Col>
          <Col xs="auto">
            <FontAwesomeIcon icon={faPlus} />
          </Col>
        </Row>
      </summary>
      <Row className="my-3">
        <Col>
          {shopData === "init" && <Spinner animation="grow" />}
          {/* {shopData || <h5>無法獲取店家資訊</h5>} */}
          {shopData?.bookingWays?.custom?.content}
        </Col>
      </Row>
    </details>
  );
};

const ShopCardDistribute = ({ data, handleInc, handleDec }) => {
  return (
    <ListGroup.Item>
      <Row className="align-items-center">
        <Col>{data.storeName || "-"}</Col>
        <Col xs="auto">
          <Row className="align-items-center justify-content-bwtween text-center">
            <Col>
              <FontAwesomeIcon icon={faMinusCircle} onClick={handleDec} />
            </Col>
            <Col>
              <strong>
                {data.point}/{data.ownPoint}
              </strong>
            </Col>
            <Col>
              <FontAwesomeIcon icon={faPlusCircle} onClick={handleInc} />
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

const CouponLogin = ({ data }) => {
  const Coupons = data.map((item, idx) => {
    return (
      <Col className="p-3 w-100">
        <Image
          src={item.shop_product[0].product_img}
          style={{ height: "50vh" }}
        />
        <h3>{item.shop_name}</h3>
        <p>{item.shop_product[0].product_name}</p>
      </Col>
    );
  });

  return (
    <div className="row flex-row flex-nowrap overflow-auto">{Coupons}</div>
  );
};

const CouponSmall = ({ order }) => {
  const [show, setShow] = useState(false);

  let productData = order.products[0];

  return (
    <Col
      className=" product-order-small p-1"
      style={{
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        width: "200px",
      }}
      xs="auto"
      onClick={() => setShow(true)}
    >
      <Card className=" border-0">
        <div className="coupon-list-card-container">
          <Card.Img
            src={productData.images[0]}
            className="coupon-list-card-img"
          />
        </div>
        <strong style={{ textOverflow: "ellipsis",overflow: 'hidden', whiteSpace: "nowrap" }}>
          {productData.productName}
        </strong>
        {/* <OverflowText text={productData.productName} max="12" /> */}
      </Card>
      {order.orderType === "product" && (
        <CouponCheckModal
          show={show}
          onHide={() => setShow(false)}
          data={order}
        />
      )}
      {order.orderType === "cash" && (
        <CashCheckModal
          show={show}
          onHide={() => setShow(false)}
          pickOrder={order}
        />
      )}
    </Col>
  );
};

const SearchStore = ({ clickCB }) => {
  const [typeSearch, setTypeSearch] = useState("");
  const [searchStore, setSearchStore] = useState([]);
  const [searching, setSearching] = useState(false);

  const debounceTypeSearch = useDebounce(typeSearch);

  useEffect(() => {
    if (!debounceTypeSearch.trim()) {
      setSearchStore([]);
      return;
    }
    setSearching(true);
    queryCOstore(typeSearch)
      .then((res) => setSearchStore(res))
      .catch((err) => console.log(err.message))
      .finally(() => setSearching(false));
  }, [debounceTypeSearch]);

  return (
    <div>
      <Form.Control
        placeholder="輸入店名"
        value={typeSearch}
        onChange={(e) => setTypeSearch(e.target.value)}
      />
      {/* <div className="location-dropdown-container">
        <ul className="location-dropdown" > */}
      {!searching && typeSearch.trim() && searchStore.length === 0 && (
        <div className="location-dropdown-container">
          <ul className="location-dropdown">
            <li>
              找不到 <strong>{typeSearch}</strong> 店名
            </li>
          </ul>
        </div>
      )}
      {searchStore.length > 0 && (
        <div className="location-dropdown-container">
          <ul className="location-dropdown">
            {searchStore.map((e) => {
              let { storeName, address } = e.shopData;
              address = Object.values(address).reduce(
                (pre, cur) => (pre += cur),
                ""
              );
              return (
                <li key={e._id} onClick={() => clickCB(e)}>
                  <Stack>
                    <strong className="text-primary">{storeName}</strong>
                    <small>{address}</small>
                  </Stack>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {/* </ul>
      </div> */}
    </div>
  );
};

const CashCheckModal = ({ show, onHide, pickStore, pickOrder }) => {
  const [targetStore, setTargetStore] = useState(pickStore);
  const [targetOrder, setTargetOrder] = useState(pickOrder);
  const [at, setAt] = useState("home");
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();
  const { user, ownOrder, setOwnOrder } = useUser();

  const fAddress = useMemo(() => {
    if (!targetStore?._id) return "";

    let { city, district, road } = targetStore?.shopData?.address;

    return `${city} ${district} ${road}`;
  }, [targetStore]);

  const aliveCashOrder = useMemo(() => {
    if (pickOrder) return [pickOrder];
    return ownOrder.filter((e) => e.orderType === "cash" && e.status === "get");
  }, [ownOrder]);

  const isValid = useMemo(() => {
    if (!targetOrder?._id) return false;

    if (!targetStore?._id) return false;

    return true;
  }, [targetOrder, targetStore]);

  const refundValid = useMemo(() => {
    // If got store ,then disable refund
    if (targetStore?._id) return false;

    // If not got target order,then disable refund
    if (!targetOrder?._id) return false;
    return true;
  });

  useEffect(() => {
    if (targetOrder?._id) return;
    setTargetOrder(aliveCashOrder[0]);
  }, [aliveCashOrder]);

  const handleAction = async (action) => {
    setPending(true);

    let result;

    try {
      switch (action) {
        case process.env["REACT_APP_COUPON_STATUS_CHECK"]:
          result = await checkCash(
            targetOrder.tradeNo,
            action,
            targetStore._id
          );
          setAt("checked");
          break;
        case process.env["REACT_APP_COUPON_STATUS_REFUND"]:
          result = await refundCash(targetOrder.tradeNo, action);
          setAt("refund");
          break;
        default:
          throw new Error("動作不明");
      }

      setTargetOrder(result);
    } catch (err) {
      console.error(err);
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  const handleTransfer = async (order) => {
    try {
      let url = await requestGiftUrl(order.tradeNo);

      let data = {
        title: `NearMe-來自${user.name}的禮物`,
        text: order.products[0].productName,
        url,
      };

      let result = await copyShare(data);

      if (!result) throw new Error("複製失敗");

      addToast("成功複製連結", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      onClick={(e) => e.stopPropagation()}
      scrollable
      onExit={() => {
        if (!targetOrder?._id) return;
        setOwnOrder((pre) => {
          let data = [...pre];
          let idx = data.findIndex((e) => e._id == targetOrder?._id);
          if (idx !== -1) data[idx] = targetOrder;
          return data;
        });
      }}
    >
      <Modal.Header closeButton>使用現金券</Modal.Header>
      <Modal.Body>
        {at === "home" && (
          <div>
            <h4>付款給</h4>
            {targetStore?._id ? (
              <Card>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <Stack>
                        <strong>{targetStore.shopData.storeName}</strong>
                        <small>{fAddress}</small>
                      </Stack>
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => setTargetStore(null)}
                      >
                        更換
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : (
              <SearchStore clickCB={(store) => setTargetStore(store)} />
            )}
            <hr />
            <h4>現金券</h4>
            {aliveCashOrder?.length === 0 && (
              <h4 className="text-muted text-center">目前沒有可用的現金券</h4>
            )}
            <ScrollController>
              {aliveCashOrder.map((e) => {
                let isPick = targetOrder?._id === e._id;
                return (
                  <Col
                    xs="auto"
                    style={{
                      scrollSnapAlign: "start",
                      scrollSnapStop: "always",
                    }}
                    onClick={() => setTargetOrder(e)}
                    key={e._id}
                    className="px-1"
                  >
                    <Card border={isPick && "success"}>
                      <Card.Body>
                        <Row>
                          <Col xs="auto">
                            <Image
                              width={50}
                              height={50}
                              src={e.products[0].images[0]}
                            />
                          </Col>
                          <Col>
                            <Stack>
                              <strong>${e.cash.credit}</strong>
                              <small>
                                {new Date(
                                  e.cash.expiredDate
                                ).toLocaleDateString()}
                              </small>
                            </Stack>
                          </Col>
                          <Col xs="auto">
                            {isPick && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-success"
                              />
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer
                        className="bg-white text-center text-primary"
                        onClick={() => handleTransfer(e)}
                      >
                        <strong>
                          <FontAwesomeIcon icon={faGift} />
                          送禮
                        </strong>
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
            </ScrollController>
            <hr />
            <Row className="justify-content-center">
              {pending && (
                <Col xs="auto">
                  <Spinner animation="border" />
                </Col>
              )}

              {pending || (
                <React.Fragment>
                  <Col>
                    <Button
                      className="w-100"
                      disabled={!isValid || pending}
                      onClick={() =>
                        handleAction(
                          process.env["REACT_APP_COUPON_STATUS_CHECK"]
                        )
                      }
                    >
                      使用
                      {pending && <Spinner animation="border" />}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="w-100"
                      variant="outline-danger"
                      disabled={!refundValid || pending}
                      onClick={() => setAt("refundCheck")}
                    >
                      退款
                      {pending && <Spinner animation="border" />}
                    </Button>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </div>
        )}

        {at === "refundCheck" && (
          <div>
            {targetStore?.shopData?.storeName}
            <h5>
              <strong>
                確定退款 {targetOrder.products[0].productName} 嗎？
              </strong>
            </h5>
            <Row className="justify-content-center">
              {pending && (
                <Col xs="auto">
                  <Spinner animation="border" />
                </Col>
              )}
              {pending || (
                <React.Fragment>
                  <Col>
                    <Button className="w-100" onClick={() => setAt("home")}>
                      我要保留
                      {pending && <Spinner animation="border" />}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="w-100"
                      variant="outline-danger"
                      disabled={pending}
                      onClick={() =>
                        handleAction(
                          process.env["REACT_APP_COUPON_STATUS_REFUND"]
                        )
                      }
                    >
                      退款
                      {pending && <Spinner animation="border" />}
                    </Button>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </div>
        )}

        {at === "checked" && (
          <Stack>
            <h4 className="text-success text-center">
              <strong>付款成功</strong>
            </h4>
            <Row className="justify-content-center align-items-center">
              <Col xs="auto">
                <strong>{targetOrder.products[0].productName}</strong>
              </Col>
              <Col xs="auto">
                <div className="vr h-100" />
              </Col>
              <Col xs="auto" className="text-muted">
                <h4>
                  <strong>{targetStore.shopData.storeName}</strong>
                </h4>
              </Col>
            </Row>
            <small className="text-center">
              {new Date(targetOrder.checkedDate).toLocaleDateString()}
            </small>
            <hr />
            <Button onClick={() => onHide()}>確認</Button>
          </Stack>
        )}
        {at === "refund" && (
          <Stack>
            <h4 className="text-success text-center">
              <strong>退款成功</strong>
            </h4>
            <hr />
            <Button onClick={() => onHide()}>確認</Button>
          </Stack>
        )}
      </Modal.Body>
    </Modal>
  );
};

const CouponCheckModal = ({ data, show, onHide }) => {
  const { user, setOwnOrder } = useUser();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("get");
  const { copyClipboard } = useClipboard();

  const [couponData, setCouponData] = useState(data);
  const { sellerData, products } = couponData;
  const [refundCheck, setRefundCheck] = useState(false);
  // let product = coupon.coupon;
  // product.description = product.description?.replace(/<br>/g, "\n");

  const isValidDate = useMemo(() => {
    return isInRange(
      new Date(),
      products[0].startDate || new Date(),
      products[0].expiredDate
    );
  }, [couponData]);

  const handleTransfer = async () => {
    try {
      let url = await requestGiftUrl(couponData.tradeNo);

      let data = {
        title: `NearMe-來自${user.name}的禮物`,
        text: products[0].productName,
        url,
      };
      console.log(data);
      let result = await copyShare(data);

      if (!result) throw new Error("複製失敗");

      addToast("成功複製連結", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleCheck = async () => {
    if (couponData.status === process.env["REACT_APP_COUPON_STATUS_CHECK"]) {
      setOwnOrder((pre) => pre.filter((e) => e._id != data._id));
      onHide();
    }

    if (couponData.status !== process.env["REACT_APP_COUPON_STATUS_CATCH"])
      return;

    setLoading(true);

    try {
      let result = await checkOrder(couponData.tradeNo);

      setCouponData(result);

      setStatus("checked");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleRefund = async () => {
    try {
      setLoading(true);

      let result = await refundCoupon(couponData.tradeNo);

      setCouponData(result);

      setStatus("refund");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setLoading(false);
    }
  };

  const ModalFooterButton = () => {
    if (couponData.status === process.env["REACT_APP_COUPON_STATUS_CATCH"]) {
      return (
        <React.Fragment>
          <Button
            variant="primary rounded-pill"
            onClick={handleCheck}
            disabled={loading || !isValidDate}
          >
            馬上兌換
            {loading && <Spinner as="span" animation="border" />}
          </Button>
          <Button
            variant="outline-primary rounded-pill"
            onClick={handleTransfer}
          >
            <FontAwesomeIcon icon={faGift} /> 轉送
          </Button>
        </React.Fragment>
      );
    }

    return (
      <Button variant="primary rounded-pill" onClick={onHide}>
        確認
      </Button>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      onClick={(e) => e.stopPropagation()}
      onExit={() => {
        if (status !== "get")
          setOwnOrder((pre) => {
            pre = pre.filter((e) => e._id !== couponData._id);
            return pre.concat(couponData);
          });
      }}
      scrollable
      className="coupon-check-modal"
    >
      <Modal.Header closeButton className="border-0 text-muted">
        {/* store data */}
        <Row>
          <Col>
            <h4>
              <strong>{sellerData.name}</strong>
            </h4>
            <a
              href={`https://www.google.com.tw/maps/place/${sellerData.address}`}
              target="_blank"
            >
              {sellerData.address}
            </a>
          </Col>
        </Row>
      </Modal.Header>
      {couponData.status === process.env["REACT_APP_COUPON_STATUS_CHECK"] && (
        <Alert variant="success">已核銷</Alert>
      )}
      {couponData.status === process.env["REACT_APP_COUPON_STATUS_REFUND"] && (
        <Alert variant="success">已退款</Alert>
      )}

      <Modal.Body>
        {/* Coupon notice & product image*/}
        <Row>
          <Col>
            <Alert variant="secondary">請先出示給店員</Alert>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Image
              src={products[0].images[0]}
              className="coupon-check-modal-image"
              alt="productImage"
            />
          </Col>
        </Row>
        {status === "grade" ? (
          <GradeStore shopID={sellerData.seller} />
        ) : (
          <div>
            {/* 商品名稱 */}
            <h5>
              <strong>
                {products[0].productName}
                {products[0].specification && `-${products[0]?.specification}`}
              </strong>
            </h5>
            {/* 商品描述 */}
            <OverflowText text={products[0].description} />
            {/* coupon content */}
            <details className="general-details" open>
              <summary className="text-muted">
                <Row>
                  <Col>
                    <h6>內容</h6>
                  </Col>
                  <Col xs="auto">
                    <FontAwesomeIcon icon={faPlus} />
                  </Col>
                </Row>
              </summary>
              <Table borderless size="sm" className="checkCoupon-table">
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}></th>
                    <th style={{ width: "70%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {data.getway ===
                    process.env["REACT_APP_COUPON_GETWAY_CATCH"] && (
                    <React.Fragment>
                      <tr>
                        <td>名稱</td>
                        <td>{products[0].productName}</td>
                      </tr>
                      <tr>
                        <td>優惠券描述</td>
                        <td>{products[0].description}</td>
                      </tr>
                    </React.Fragment>
                  )}

                  <tr>
                    <td>有效期限</td>
                    <td>
                      {products[0].startDate &&
                        new Date(products[0].startDate).toLocaleDateString()}
                      ~{new Date(products[0].expiredDate).toLocaleDateString()}
                      <br />
                      <strong className="text-danger">
                        {isValidDate || "目前不可兌換"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>數量</td>
                    <td>{products[0].amount}</td>
                  </tr>
                  <tr>
                    <td>兌換號碼</td>
                    <td>
                      <small>
                        {couponData.tradeNo}
                        <FontAwesomeIcon
                          onClick={() => copyClipboard(couponData.tradeNo)}
                          icon={faCopy}
                          style={{ color: "#007BFF" }}
                        />
                      </small>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </details>
            {products[0]?.needBooking && (
              <ShopBookingWays shopID={sellerData.seller} />
            )}
            {/* refund action */}
            {couponData.status ===
              process.env["REACT_APP_COUPON_STATUS_CATCH"] && (
              <details className="general-details">
                <summary className="text-muted">
                  <Row>
                    <Col>
                      <h6>我要取消預訂</h6>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faPlus} />
                    </Col>
                  </Row>
                </summary>
                <Row className="text-muted text-center">
                  <Col>
                    <h5>注意！取消預訂將會扣除</h5>

                    <strong>
                      $
                      <span className="large-total-price">
                        {couponData.totalDeposit ?? 0}
                      </span>
                      訂金
                    </strong>
                  </Col>
                </Row>
                {refundCheck || (
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <Button
                        variant="outline-danger"
                        onClick={() => setRefundCheck(true)}
                      >
                        取消預訂
                      </Button>
                    </Col>
                  </Row>
                )}
                {refundCheck && (
                  <Row>
                    <Col>
                      <Button
                        variant="danger w-100"
                        onClick={handleRefund}
                        disabled={loading}
                      >
                        退款
                        {loading && <Spinner as="span" animation="border" />}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="outline-primary w-100"
                        onClick={() => setRefundCheck(false)}
                      >
                        我想保留
                      </Button>
                    </Col>
                  </Row>
                )}
              </details>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <ModalFooterButton />
      </Modal.Footer>
    </Modal>
  );
};

const CouponCatchModal = ({ product, show, onHide, isExample = false }) => {
  const [loading, setLoading] = useState(false);
  const [pw, setPw] = useState("");
  const [catchStatus, setCatchStatus] = useState("enable");
  const { user } = useUser();
  const { addToast } = useToast();
  const { coupon } = product;
  const history = useHistory();
  const submitButton = useMemo(() => getCatchStatusMessage(catchStatus));

  const handle_catch = async (event, product) => {
    event.stopPropagation();

    if (isExample || catchStatus !== "enable") return;

    setLoading(true);

    let res = await catchCoupon(product._id, pw);

    if (res.data) {
      let currentRecords = res.data[0];

      setCatchStatus(currentRecords.status);
    } else {
      addToast(res.message || "發生錯誤", { appearance: res.toast || "error" });
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      onClick={(e) => e.stopPropagation()}
    >
      <Modal.Header className="border-0" closeButton />

      {isExample && <Alert variant="danger">範例區無法操作</Alert>}
      {/* Product image */}
      <Row className="d-flex justify-content-center">
        <Image
          src={product.basic.image}
          className="coupon-check-modal-image"
          alt="productImage"
          rounded
        />
      </Row>
      <Modal.Body>
        <Table borderless size="sm" className="text-muted">
          <thead style={{ borderStyle: "hidden" }}>
            <tr>
              <th style={{ width: "30%" }}></th>
              <th style={{ width: "70%" }}></th>
            </tr>
          </thead>
          <tbody>
            {/* 領券狀態 */}
            <tr>
              <td>狀態</td>
              <td>
                <Badge variant={catchStatus.type}>{catchStatus.msg}</Badge>
                <br />
                {product.isGrabBag && (
                  <small className="text-muted">
                    此為福袋券，領取後會隨機出現其他三張優惠券"
                  </small>
                )}
              </td>
            </tr>
            <tr>
              <td>券名稱</td>
              <td>{coupon.basic.couponName}</td>
            </tr>
            <tr>
              <td>有效期限</td>
              <td>{product.couponExpireDay}</td>
            </tr>
            <tr>
              <td>使用說明</td>
              <td>{coupon.basic.couponDescription}</td>
            </tr>
            {coupon.limitation.password ? (
              <tr>
                <td>密碼🗝</td>
                <td>
                  <Form.Control
                    type="text"
                    value={pw}
                    onChange={(e) => setPw(e.target.value)}
                  />
                </td>
              </tr>
            ) : null}
            {/* {product.reqShopCardPoint > 0 && (
              <tr>
                <td>使用點數</td>
                <td>
                  <ListGroup variant="flush">
                    {pointDistribute.map((e) => (
                      <ShopCardDistribute
                        key={e.shopID}
                        data={e}
                        handleDec={() => handleDistributeChange(e.shopID, -1)}
                        handleInc={() => handleDistributeChange(e.shopID, 1)}
                      />
                    ))}
                  </ListGroup>
                </td>
              </tr>
            )} */}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {user?._id ? (
          <Button
            variant={`${submitButton.variant} rounded-pill`}
            onClick={handle_catch}
            disabled={loading || submitButton.disabled}
          >
            {submitButton.title}
            {loading && <Spinner animation="border" />}
          </Button>
        ) : (
          <Button
            variant="primary rounded-pill"
            onClick={() =>
              history.push(`/login?redirect=/search?shopID=${product.shopID}`)
            }
          >
            {catchStatus.msg}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const GiftModal = ({ giftToken }) => {
  const [loading, setLoading] = useState(false);
  const [checkP, setCheckP] = useState(false);
  const [targetStore, setTargetStore] = useState({});
  const [show, setShow] = useState(true);
  const [pw, setPw] = useState("");
  const [order, setOrder] = useState(null);
  const [from, setFrom] = useState(null);
  const { addToast } = useToast();

  const fAddress = useMemo(() => {
    if (!targetStore?._id) return "";

    let { city, district, road } = targetStore?.shopData?.address;

    return `${city} ${district} ${road}`;
  }, [targetStore]);

  const product = useMemo(() => {
    if (!order?._id) return;
    return order.products[0];
  }, [order]);

  useEffect(() => {
    setLoading(true);
    // decode token
    fetchGiftInfo(giftToken)
      .then((res) => {
        setOrder(res.fromOrder);
        setFrom(res.from);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleCheck = async () => {
    setCheckP(true);
    try {
      let result = await checkGift(giftToken, { sellerID: targetStore?._id });
      setOrder(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setCheckP(false);
    }
  };

  const SuccessPage = () => {
    return (
      <Stack>
        <h4 className="text-success text-center">
          <strong>兌換成功</strong>
        </h4>
        <Row className="justify-content-center align-items-center">
          <Col xs="auto">
            <strong>{product.productName}</strong>
            {/* <h4 className="text-center">${targetOrder.cash.used}</h4> */}
          </Col>
          <Col xs="auto">
            <div className="vr h-100" />
          </Col>
          <Col xs="auto" className="text-muted">
            <h4>
              <strong>{order.sellerData.name}</strong>
            </h4>
          </Col>
        </Row>
        <small className="text-center">
          {new Date(order.checkedDate).toLocaleDateString()}
        </small>
        <hr />
        <Button onClick={onHide}>確認</Button>
      </Stack>
    );
  };

  const CheckPage = () => {
    return (
      <div>
        {order?.orderType === "cash" && (
          <div>
            <h4>付款給</h4>
            {targetStore?._id ? (
              <Card>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <Stack>
                        <strong>{targetStore.shopData.storeName}</strong>
                        <small>{fAddress}</small>
                      </Stack>
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => setTargetStore(null)}
                      >
                        更換
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : (
              <SearchStore clickCB={(store) => setTargetStore(store)} />
            )}
            <hr />
          </div>
        )}
        <Row>
          <Col>
            <Alert variant="secondary">請先出示給店員</Alert>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Image
              src={product.images[0]}
              className="coupon-check-modal-image"
              alt="productImage"
              style={{ borderRadius: "18px" }}
            />
          </Col>
        </Row>
        <div>
          {/* 商品名稱 */}
          <h5>
            <strong>
              {product.productName}
              {product?.specification && `-${product.specification}`}
            </strong>
          </h5>
        </div>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary rounded-pill" onClick={handleCheck}>
            兌換
            {checkP && <Spinner animation="border" />}
          </Button>
        </Modal.Footer>
      </div>
    );
  };

  const onHide = () => setShow(false);

  if (loading) {
    return (
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Spinner animation="border" />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  if (!product) {
    return (
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs="auto">
              <h4>此券無效</h4>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      onClick={(e) => e.stopPropagation()}
    >
      <Modal.Header closeButton>來自-{from.name}的禮物</Modal.Header>
      <Modal.Body>
        {order.status === "checked" && <SuccessPage />}
        {order.status === "get" && <CheckPage />}
      </Modal.Body>
    </Modal>
  );
};

const CouponList = ({ data, show_modal }) => {
  const [show, setShow] = useState(false);
  let coupon = data.products[0];

  coupon.description = coupon.description?.replace(/<br>/g, "\n");

  return (
    <Card
      className="border-0 border-bottom"
      onClick={() => setShow(true)}
      id={coupon._id}
    >
      <Card.Body>
        <Row>
          <Col xs="auto">
            <Image
              src={coupon.images[0]}
              alt="productImage"
              width={120}
              height={120}
              style={{ borderRadius: "15px" }}
            />
          </Col>
          <Col>
            <strong>{coupon.productName}</strong>
            <h6 className="text-muted">{coupon.description}</h6>
            {data?.transfer?.length > 0 && (
              <h6>
                禮物來自:
                <Badge variant="primary">
                  {data.transfer[data.transfer.length - 1].fromName}
                </Badge>
              </h6>
            )}
            <p className="text-primary m-0">
              {new Date(coupon.expiredDate).toLocaleDateString()}
            </p>
          </Col>
        </Row>
      </Card.Body>
      {data.orderType === "product" && (
        <CouponCheckModal
          show={show}
          onHide={() => setShow(false)}
          data={data}
        />
      )}
      {data.orderType === "cash" && (
        <CashCheckModal
          show={show}
          onHide={() => setShow(false)}
          pickOrder={data}
        />
      )}
    </Card>
  );
};

const CouponNotify = () => {
  const [show, setShow] = useState(true);

  const onHide = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={onHide} animation={false} centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <Col className="text-center">
          {/* <img src={circle} alt=""/> */}
          <CheckCircleIcon style={{ height: "100px", width: "100px" }} />
          <h2>領券成功!</h2>
        </Col>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary rounded-pill" onClick={onHide}>
          查看券
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CouponTicket = ({ product }) => {
  return (
    <article className="ticketWrap">
      <div className="d-flex coupon-type">
        {product.password ? (
          <Badge variant="info" className="mx-1">
            <FontAwesomeIcon icon={faLock} />
          </Badge>
        ) : null}
        {product.drawRate < 100 ? (
          <Badge variant="info" className="mx-1">
            抽獎
          </Badge>
        ) : null}
      </div>
      <section className="ticketContain ticketLeft">
        <b>{product.couponName}</b>
      </section>
      <section className="ticketContain ticketRight">
        領取
        {/* <div className={product.isGrabBag ? "grabag" : "finger"} /> */}
        <div className="finger"></div>
      </section>
    </article>
  );
};

export const CouponTicketInvalid = ({ invalidType }) => {
  let title = "無效";

  switch (invalidType) {
    case "exist":
      title = "已領取";
      break;
    case "soleOut":
      title = "領完了";
      break;
    case "creditOut":
      title = "已領取";
      break;
  }
  return (
    <article className="ticketWrap">
      <Badge variant="danger" className="ticket-invalid-badge" pill>
        {title}
      </Badge>
    </article>
  );
};

const ProductAmountPicker = ({ amount, setAmount, validAmount = 1 }) => {
  useEffect(() => {
    if (amount > validAmount) setAmount(validAmount);

    if (amount < 0) setAmount(1);
  }, [amount]);

  return (
    <Row className="justify-content-around my-3 mx-1">
      <Button
        className="rounded-circle"
        variant="outline-secondary"
        onClick={() => setAmount(amount - 1)}
        style={{ height: "38px" }}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Col>
        <Form.Control
          type="number"
          value={amount}
          min="1"
          max={validAmount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <Form.Text>庫存: {validAmount ?? "請選取規格"}</Form.Text>
      </Col>
      <Button
        className="rounded-circle"
        variant="outline-secondary"
        onClick={() => setAmount(amount + 1)}
        style={{ height: "38px" }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Row>
  );
};

export const AddCashOrderModal = ({ show, onHide }) => {
  const [credit, setCredit] = useState(500);
  const history = useHistory();
  const opts = [6, 500, 1000, 1500];

  const handlePointPay = (e) => {
    e.preventDefault();

    let paymentParameter = { credit, orderType: "cash" };

    let queryParameter = new URLSearchParams(paymentParameter).toString();

    onHide();

    history.push(`/payment/order/request?${queryParameter}`);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      onClick={(e) => e.stopPropagation()}
      onExit={() => setCredit(500)}
    >
      <Modal.Header closeButton>現金券</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlePointPay}>
          <Row xs={1} lg={2} className="justify-content-center">
            <Col xs="auto">
              <div style={{ width: "150px" }}>
                <Image
                  src="https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/cashOrder/cashOrder.svg"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "20px",
                  }}
                />
              </div>
            </Col>
            <Col>
              <Stack gap={2}>
                <h4 className="text-primary">
                  <strong>現金券</strong>
                </h4>
                <Form.Select
                  value={credit}
                  onChange={(e) => setCredit(parseInt(e.target.value))}
                >
                  {opts.map((e) => (
                    <option key={e} value={e}>
                      {e} 元
                    </option>
                  ))}
                </Form.Select>

                <Button type="submit">確認</Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const OrderStickyNote = ({ order }) => {
  return (
    <Stack direction="horizontal" className="my-2">
      <Image src={order.products[0].images[0]} className="ownCoupon-image" />
      <div
        className="ownCoupon-content"
        style={{ width: "300px", boxShadow: "5px" }}
      >
        <Row className="align-items-center">
          <Col>
            <strong>{order.products[0].productName}</strong>
            <OverflowText text={order.products[0].description} max={3} />
          </Col>
        </Row>
      </div>
    </Stack>
  );
};

export const OrderCard = ({ order, cb }) => {
  const productData = order.products[0];
  return (
    <Card id={order._id} onClick={() => cb(order)}>
      <Card.Body>
        <Row>
          <Col xs="auto">
            <Image
              src={productData.images[0]}
              alt="productImage"
              width={120}
              height={120}
              style={{ borderRadius: "15px" }}
            />
          </Col>
          <Col>
            <strong>{productData.productName}</strong>
            <h6 className="text-muted">{productData.description}</h6>
            {/* {data?.transfer?.length > 0 && (
              <h6>
                禮物來自:
                <Badge variant="primary">
                  {data.transfer[data.transfer.length - 1].fromName}
                </Badge>
              </h6>
            )} */}
            <p className="text-primary m-0">
              {new Date(order.expiredDate).toLocaleDateString()}
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export {
  CouponLogin,
  CouponSmall,
  CouponList,
  CashCheckModal,
  CouponCatchModal,
  CouponTicket,
  CouponNotify,
  CouponCheckModal,
  ProductAmountPicker,
};

import { APISOCal, APIValidPhoneBarCode } from "API/order";
import { Http_request_post } from "Service/HttpService";

export const validPhoneBarCode = async (phoneBarCode) => {
  let result = await Http_request_post(APIValidPhoneBarCode, { phoneBarCode });

  if (!result.data) throw new Error(result.mssage);

  return result.data;
};

export const calFee = async (calParam) => {
  let result = await Http_request_post(APISOCal, { calParam });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const validBusinessNo = (bNo) => {
  if (!/^\d{8}$/.test(bNo)) return { valid: false, msg: "必須是8位數數字" };
  const cx = [1, 2, 1, 2, 1, 2, 4, 1];
  let splitBNO = bNo.split("");
  let sum = 0;
  splitBNO.forEach((item, index) => {
    let total = item * cx[index];
    if (total > 9) {
      let s = total.toString();
      const n1 = s.substring(0, 1) * 1;
      const n2 = s.substring(1, 2) * 1;
      total = n1 + n2;
    }
    sum += total;
  });

  if (sum % 10 === 0) return { valid: true, msg: "" };
  else if (splitBNO[6] === "7" && (sum + 1) % 10 === 0)
    return { valid: true, msg: "" };
  else return { valid: false, msg: "格式錯誤" };
};

export const convertDiscountType = (code = "") => {
  const discountCal = code[0];
  const discountTarget = code[1];
  const discountValue = code.slice(2);

  let discountTitle = "";
  let discountAmount = "";

  switch (discountCal) {
    case "P":
      discountTitle = "折價";
      break;
    case "A":
      discountTitle = "折";
      break;
    default:
      break;
  }

  return { discountTitle, discountAmount };
};

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Card,
  Row,
  Col,
  Stack,
  Button,
  Form,
  ListGroup,
  Spinner,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faEdit, faPen } from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/ToastProvider";

import { useUser } from "../../../Provider/UserProvider";
import { Http_request_post } from "../../../Service/HttpService";
import { useClipboard } from "Hook/Clipboard/useClipboard";
import { APIUpdateUserInvoiceSetting, APIUpdateUserInfo } from "API/user";
import { updateBasicInfo } from "./AccountInfoUtils";

export const AccountInfo = () => {
  const { user, setUser } = useUser();
  const { addToast } = useToast();
  const { copyClipboard } = useClipboard();

  const basicList = [
    {
      title: "E-mail",
      label: "email",
      modifiable: false,
    },
  ];


  const InfoItem = ({ title, label, modifiable, handleChange }) => {
    const [edit, setEdit] = useState(false);
    const [newV, setNewV] = useState("");
    const [pending, setPending] = useState(false);

    const handleClick = async () => {
      setPending(true);
      try {
        let updateData = {};
        updateData[label] = newV;
        let result = await updateBasicInfo(updateData);
        setUser(result);
        addToast(`成功更新!!`);
      } catch (err) {
        addToast(err.message, { appearance: "error" });
      } finally {
        setPending(false);
      }
    };

    return (
      <ListGroup.Item>
        <h4>
          <strong>{title}</strong>
        </h4>
        <Row>
          <Col>
            {edit ? (
              <Form.Control
                value={user[label]}
                onChange={(e) => setNewV(e.target.value)}
                disabled={!edit}
              />
            ) : (
              <strong>{user[label]}</strong>
            )}
          </Col>
          <Col xs="auto">
            {modifiable && edit && (
              <Button onClick={handleClick} size="sm" variant="outline-primary">
                儲存{pending && <Spinner animation="border" />}
              </Button>
            )}
            {modifiable && !edit && (
              <FontAwesomeIcon icon={faPen} onClick={() => setEdit(true)} />
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <div>
      <Card className="shadow-sm my-3">
        <Card.Body>
          <Row className="justify-content-start align-items-center">
            <Col xs="auto">
              <Image
                width={80}
                height={80}
                src="https://placehold.co/600x600"
                roundedCircle
              />
            </Col>
            <Col>
              <h4>{user.name}</h4>
              <Stack direction="horizontal" gap={2}>
                <small className="text-muted">{user._id}</small>
                <FontAwesomeIcon
                  icon={faClone}
                  className="text-primary"
                  onClick={() => copyClipboard(user._id)}
                />
              </Stack>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <hr />
      <ListGroup variant="flush">
        {basicList.map((e) => (
          <InfoItem key={e.title} title={e.title} label={e.label} modifiable={e.modifiable} />
        ))}
      </ListGroup>
    </div>
  );
};

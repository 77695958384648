import { APIRefundScrape } from "API/order";
import { Http_request_post } from "Service/HttpService";

export const getCouponStatus = (couponStatus) => {
  let result = { title: "", variant: "" };

  switch (couponStatus) {
    case process.env["REACT_APP_COUPON_STATUS_CATCH"]:
      result.title = "可使用";
      result.variant = "success";
      break;
    case process.env["REACT_APP_COUPON_STATUS_EXPIRED"]:
      result.title = "已過期";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_DRAW"]:
      result.title = "未中獎";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_CHECK"]:
      result.title = "已收下";
      result.variant = "primary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_REFUND"]:
      result.title = "已退款";
      result.variant = "danger";
      break;
    case process.env["REACT_APP_COUPON_STATUS_CHANGEGIFT"]:
      result.title = "已收下";
      result.variant = "primary";
      break;
  }
  return result;
};

export const refundOrder = async (orderId) => {
  let result = await Http_request_post(APIRefundScrape, { orderId });
  if (!result.data) throw new Error(result.message);
  return result.data;
};

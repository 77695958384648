const key = process.env["REACT_APP_GOOGLE_API_KEY"];

const getLocationPosition = () => {
  if (!"geolocation" in navigator)
    throw new Error("瀏覽器不支援定位，請使用Chrome或Safari");

  return new Promise((res, rej) => {
    const success = (position) => {
      let lat = position.coords.latitude;

      let lng = position.coords.longitude;

      // localStorage.setItem("isAvailablePosition", "true");

      res({
        data: { lat, lng },
        message: "成功定位",
      });
    };

    const fail = (error) => {
      let message = "";

      switch (error.code) {
        case error.PERMISSION_DENIED:
          message = "請開啟GPS及授權瀏覽器定位";
          break;
        case error.POSITION_UNAVAILABLE:
          message = "位置錯誤";
          break;
        case error.TIMEOUT:
          message = "定位超時";
          break;
        case error.UNKNOWN_ERROR:
          message = "未知錯誤";
          break;
        default:
          message = "定位失敗，請確認權限或改為手動定位";
      }

      localStorage.removeItem("geolocation_permission");
      res({ message });
    };
    navigator.geolocation.getCurrentPosition(success, fail);
  });
};

const getLocationByName = (address) => {
  let positionUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=+${address}&key=${key}`;
  return fetch(positionUrl)
    .then((res) => res.json())
    .then((res) => {
      return res.results;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getLocationByLatLng = (lat, lng) => {
  let parameter = `${lat},${lng}`;

  let positionUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${parameter}&key=${key}`;

  return fetch(positionUrl)
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      return res.results;
    })
    .catch((err) => {
      console.log(err);
    });
};

const googleGeolocation = () => {
  let key = "AIzaSyD89C2N-oV13S-QtRdp3zV945BxFJN-YyY";
  let url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${key}`;
  return fetch(url, { method: "POST" });
};

const convertDistanceUnit = (distance) => {
  if (!distance) return null;

  //greater then one kilometer
  if (distance >= 1000) {
    return `${Math.round(distance/1000)}公里`;
  }

  distance = Math.round(distance);

  return `${distance}公尺`;
};

export const isGrantedLocation = async () => {
  let isGranted = false;

  //chrome firefox
  if (navigator.permissions) {
    let result = await navigator.permissions.query({ name: "geolocation" });

    if (result.state === "granted") isGranted = true;
  } else {
    //Safari
    let permissionStatus = localStorage.getItem("geolocation_permission");

    if (permissionStatus === "granted") isGranted = true;
  }

  if (!isGranted) localStorage.removeItem("geolocation_permission");

  return isGranted;
};

export {
  getLocationPosition,
  getLocationByName,
  googleGeolocation,
  getLocationByLatLng,
  convertDistanceUnit,
};

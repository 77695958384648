import React, { useState, useEffect, forwardRef, useMemo } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Spinner,
  ButtonGroup,
  ButtonToolbar,
  Stack,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useUser } from "../../Provider/UserProvider";
import { isInRange } from "../../Service/timeUtils";
import "./Order.css";
import { OrderRecord } from "Components/Order/ScrapeOrder";
import { GiftReqModal } from "User/Gift/GiftPage";

const DatePickerTemplate = ({
  handleStart,
  handleEnd,
  handleIsAll,
  handleFilter,
}) => {
  const { startDate, setStartDate } = handleStart;
  const { endDate, setEndDate } = handleEnd;
  const { isAll, setIsAll } = handleIsAll;
  const { filter, setFilter } = handleFilter;

  const currentDateContent = useMemo(() => {
    if (isAll) return "全部";

    if (!startDate || !endDate) return "未設定";

    return `${new Date(startDate).toLocaleDateString()} - ${new Date(
      endDate
    ).toLocaleDateString()}`;
  }, [startDate, endDate, isAll]);

  const ref = React.createRef();

  const PikerTemplate = forwardRef(({ value, onClick }, ref) => (
    <Button variant="outline-secondary" onClick={onClick} ref={ref}>
      自訂
    </Button>
  ));
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const dayFromNow = (day = 5) => {
    day = parseInt(day);
    let current = new Date();
    let start = new Date(new Date().setDate(current.getDate() - day));
    setStartDate(start);
    setEndDate(current);
  };

  return (
    <div>
      <Row className="justify-content-between">
        <Col>
          <h3 className="text-muted">
            <b>訂單列表</b>
          </h3>
        </Col>
        <Col xs="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button variant="outline-secondary" onClick={() => dayFromNow(0)}>
                今天
              </Button>
              <Button variant="outline-secondary" onClick={() => dayFromNow(7)}>
                7天
              </Button>

              <Button
                variant="outline-secondary"
                onClick={() => setIsAll(true)}
              >
                全部
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                selectsRange
                customInput={<PikerTemplate ref={ref} />}
                popperPlacement="top-end"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <small>{currentDateContent}</small>
        </Col>
        <Col xs="auto">
          <Form.Check
            type="switch"
            onChange={(e) => {
              e.target.checked
                ? setFilter(process.env["REACT_APP_COUPON_STATUS_CHECK"])
                : setFilter("all");
            }}
            checked={filter === process.env["REACT_APP_COUPON_STATUS_CHECK"]}
            label="限已核銷"
          />
        </Col>
      </Row>
    </div>
  );
};

const CheckedOrder = ({ records = [] }) => {
  const [combineOrder, setCombineOrder] = useState(records);
  const [giftReqShow, setGiftReqShow] = useState(false);
  const [giftReqOrder, setGiftReqOrder] = useState(null);

  useEffect(() => {
    let redup = [];

    for (let order of records) {
      if (!order?.gift?.baseOn) continue;

      redup.push(order.gift.baseOn._id);
    }

    setCombineOrder(
      records.filter(
        (e) =>
          !redup.includes(e._id) ||
          e.status !== process.env["REACT_APP_COUPON_STATUS_CHANGEGIFT"]
      )
    );
  }, [records]);

  return (
    <div>
      {records.length === 0 && (
        <h4 className="text-center text-muted">
          <b>此時段沒有訂單</b>
        </h4>
      )}
      {combineOrder.map((record) => {
        // const gift = gifts?.find(
        //   (e) =>
        //     e.orderIDs.includes(record?.gift?.baseOn?._id) ||
        //     e.orderIDs.includes(record?._id)
        // );
        return (
          <OrderRecord
            record={record}
            key={`${record._id}-${record.status}`}
            handleGiftReq={(record) => {
              setGiftReqShow(true);
              setGiftReqOrder(record);
            }}
          />
        );
      })}
      {giftReqShow && (
        <GiftReqModal
          show={giftReqShow}
          onHide={() => setGiftReqShow(false)}
          order={giftReqOrder}
        />
      )}
    </div>
  );
};

const Order = () => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isAll, setIsAll] = useState(true);
  const [filter, setFilter] = useState("all");
  const { user, ownOrder, fetchOrder } = useUser();
  const selectCoupon = useMemo(() => {
    if (!Array.isArray(ownOrder)) {
      return [];
    }

    let newCR = ownOrder.filter(
      (e) => e.status !== process.env["REACT_APP_COUPON_STATUS_REQ"]
    );

    if (!isAll && startDate && endDate) {
      newCR = newCR.filter((e) =>
        isInRange(new Date(e.createdAt), startDate, endDate)
      );
    }

    if (filter === process.env["REACT_APP_COUPON_STATUS_CHECK"]) {
      newCR = newCR.filter(
        (order) => order.status === process.env["REACT_APP_COUPON_STATUS_CHECK"]
      );
    }

    newCR = newCR.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return newCR;
  }, [startDate, endDate, isAll, filter, ownOrder]);

  //Fatch Data
  useEffect(() => {
    setLoading(true);
    fetchOrder().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) return;
    setIsAll(false);
  }, [startDate, endDate]);

  if (!user?._id) {
    return <div></div>;
  }

  return (
    <Stack className="col-md-8 mx-auto">
      <DatePickerTemplate
        handleStart={{ startDate, setStartDate }}
        handleEnd={{ endDate, setEndDate }}
        handleIsAll={{ isAll, setIsAll }}
        handleFilter={{ filter, setFilter }}
      />

      <hr />
      {loading && (
        <Stack>
          <Spinner className="mx-auto" animation="border" />
        </Stack>
      )}
      <CheckedOrder records={selectCoupon} />
    </Stack>
  );
};

export { Order };

import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  ListGroup,
  Badge,
  Alert,
  Accordion,
  Stack,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faStar,
  faHeart,
  faPlusCircle,
  faShareAlt,
  faChevronDown,
  faChevronUp,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { useToast } from "Provider/ToastProvider";
import { useTimer } from "react-timer-hook";

import { useUser } from "../../Provider/UserProvider";
import { getSurplusDay, isClose, passDay } from "../../Service/timeUtils";
import { ReactComponent as HeartUnCollection } from "../../svg/shop_card/heart-uncollection.svg";
import { ReactComponent as HeartCollection } from "../../svg/shop_card/heart-collection.svg";
import { ReactComponent as Share } from "../../svg/shop_card/share-alt-solid.svg";
import {
  CouponCheckModal,
  CouponTicket,
  CouponTicketInvalid,
  CouponCatchModal,
  CashCheckModal,
} from "../Coupon/Coupon_template";
import { ScrollController } from "../ScrollController";
import { convertDistanceUnit } from "../Location";
import {
  qrcodeCheckin,
  productPopupTag,
  isValidSold,
  isSoldOut,
  fetchStoreCredit,
  fetchProductReview,
  isOverCredit,
  collectionStore,
  loadImage,
} from "./Shop_cardUtils";
import { copyShare } from "../../Service/CopyBoardService";
import { OverflowText } from "Components/Text/OverflowText";
import { useGeoLocation } from "Provider/LocationProvider";
import "./Shop_card.css";
import { useIntersectionObserver } from "Hook/useIntersectionObs";

const ProductReviewModal = ({ show, onHide, reviews, productID }) => {
  const ScoreTemp = ({ color }) => {
    return (
      <span>
        <FontAwesomeIcon icon={faStar} style={{ color }} />
      </span>
    );
  };

  return (
    <Modal show={show} onHide={onHide} id={`productReview-${productID}`}>
      <Modal.Header closeButton>
        <h6 className="text-muted">評論</h6>
      </Modal.Header>
      <Modal.Body>
        {reviews.length === 0 && (
          <h5 className="text-center text-muted">目前沒有評論</h5>
        )}
        <ListGroup variant="flush">
          {reviews.map((e, idx) => (
            <ListGroup.Item key={idx}>
              <Stack direction="horizontal" gap={2}>
                {e.order?.customerData?.name || "**"}
                {[1, 2, 3, 4, 5].map((star) => (
                  <ScoreTemp
                    color={star <= (e?.score || 3) ? "gold" : "gray"}
                    key={star}
                  />
                ))}
                <div className="ms-auto">
                  {new Date(e.createdAt).toLocaleDateString()}
                </div>
              </Stack>
              <small className="text-muted">{e?.message}</small>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

const ProductPayByPointModal = ({
  product,
  show,
  onHide,
  shop,
  bookingWays,
  isExample = false,
}) => {
  const [amount, setAmount] = useState(1);
  const [specification, setSpecification] = useState(null);
  const [validAmount, setValidAmount] = useState(0);
  const [validStatus, setValidStatus] = useState({ status: "ok", msg: "" });
  const { addToast } = useToast();
  const history = useHistory();

  const { noNeedSpecification, specList, noNeedInventory, productInventory } =
    product.specification;
  const { images, productName } = product.basic;
  const { needBooking } = product.options;

  useEffect(() => {
    // init
    if (
      !noNeedSpecification &&
      !specList.find((e) => e.title == specification)
    ) {
      setValidStatus({ status: "noSpec", msg: "請選取規格" });
      return;
    }

    if (amount > validAmount) {
      setValidStatus({ status: "overAmount", msg: "庫存不足" });
      return;
    }

    setValidStatus({ status: "ok", msg: "" });
  }, [specification, amount, validAmount]);

  useEffect(() => {
    if (noNeedInventory) {
      setValidAmount(99);
      return;
    }
    if (noNeedSpecification) {
      setValidAmount(productInventory);
      return;
    }
    let target = specList.find((e) => e.title == specification);

    setValidAmount(target?.productInventory);
  }, [specification]);

  const handlePointPay = (e) => {
    e.preventDefault();

    if (isExample) return;

    if (validStatus.status !== "ok") return;

    let paymentParameter = {
      orderType: "product",
      productID: product._id,
      amount: amount,
    };

    if (!noNeedSpecification) paymentParameter.specification = specification;

    let queryParameter = new URLSearchParams(paymentParameter).toString();

    onHide();

    history.push(`/payment/order/request?${queryParameter}`);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      onClick={(e) => e.stopPropagation()}
    >
      <Modal.Header closeButton>預訂</Modal.Header>
      <Modal.Body>
        {isExample && <Alert variant="danger">範例區無法操作</Alert>}
        {validStatus.status !== "ok" && (
          <Alert variant="danger">{validStatus.msg}</Alert>
        )}
        <Form onSubmit={handlePointPay}>
          <Row xs={1} lg={2} className="justify-content-center">
            <Col xs="auto">
              <Image
                src={images[0]}
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "20px",
                }}
              />
            </Col>
            <Col>
              <Stack gap={3}>
                <h4 className="text-primary text-center pt-2">
                  <strong>{productName}</strong>
                </h4>

                {needBooking && (
                  <Alert>
                    <strong className="text-danger">
                      !!此商品需要提前預約
                    </strong>
                    {shop.shopData.bookingWays?.custom?.content}
                  </Alert>
                )}
                {noNeedSpecification || (
                  <Form.Control
                    as="select"
                    onChange={(e) => setSpecification(e.target.value)}
                    defaultValue="請選擇規格"
                  >
                    <option disabled>請選擇規格</option>
                    {specList.map((e) => (
                      <option key={e.title}>{e.title}</option>
                    ))}
                  </Form.Control>
                )}
                <Row className="justify-content-around align-items-center">
                  <Col xs="auto">
                    <Button
                      className="rounded-circle"
                      variant="outline-secondary"
                      onClick={() => setAmount(parseInt(amount) - 1)}
                      style={{ height: "38px" }}
                      disabled={amount <= 1}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </Col>
                  <Col className="text-center">
                    {/* <Form.Control
                      type="number"
                      value={amount}
                      min="1"
                      max={validAmount || 5}
                      onChange={(e) => setAmount(e.target.value)}
                    /> */}
                    <strong>{amount}</strong>
                  </Col>
                  <Col xs="auto">
                    <Button
                      className="rounded-circle"
                      variant="outline-secondary"
                      onClick={() => setAmount(parseInt(amount) + 1)}
                      style={{ height: "38px" }}
                      disabled={amount >= validAmount}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                </Row>

                {noNeedInventory || (
                  <Form.Text>庫存: {validAmount ?? "請選取規格"}</Form.Text>
                )}
                <Button type="submit" disabled={validStatus.status !== "ok"}>
                  確認
                </Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const OwnOrder = ({ data }) => {
  const [show, setShow] = useState(false);
  const [surplusDay, setSurplusDay] = useState("");
  const product = data.products[0];

  useEffect(() => {
    let result = getSurplusDay(product.expiredDate);
    result === null
      ? setSurplusDay("無法顯示")
      : result === -1
      ? setSurplusDay("已過期")
      : result === 0
      ? setSurplusDay("今天到期")
      : setSurplusDay(`剩${result}天`);
  }, [product]);
  return (
    <Col
      xs="auto"
      className="scroller-item mx-1 p-2"
      onClick={() => setShow(true)}
    >
      <Stack direction="horizontal">
        <Image src={product.images[0]} className="ownCoupon-image" />
        <div className="ownCoupon-content">
          <Row>
            <Col>
              <Button size="sm" variant="outline-primary">
                {surplusDay}
              </Button>
              <h5 className="my-1">
                <strong>{product.productName}</strong>
              </h5>
            </Col>
          </Row>
        </div>
      </Stack>
      <CouponCheckModal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        data={data}
      />
    </Col>
  );
};

const PopupTag = ({ popup }) => {
  const popupTimer = useTimer({
    expiryTimestamp: new Date(popup.end),
  });

  return (
    <div className="shop_popup_tag_container">
      {popupTimer.isRunning ? (
        <span className="text-white">
          快閃 {productPopupTag(popup.periodType, popupTimer)}
        </span>
      ) : (
        <span className="text-light">快閃結束</span>
      )}
    </div>
  );
};

const ReviewButton = ({ productID }) => {
  const reviewRef = useRef(null);
  const [reviewRecord, setReviewRecord] = useState([]);
  const [reviewModalShow, setReviewModalShow] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchProductReview(productID)
          .then((res) => setReviewRecord(res))
          .catch((_) => {});

        if (reviewRef.current) observer.unobserve(reviewRef.current);
      }
    });

    observer.observe(reviewRef.current);

    return () => {
      if (reviewRef.current) observer.unobserve(reviewRef.current);
    };
  }, []);

  return (
    <div>
      <Button
        variant="outline-primary"
        className="rounded-pill w-100"
        size="sm"
        onClick={() => setReviewModalShow(true)}
        ref={reviewRef}
      >
        {`評論(${reviewRecord.length})`}
      </Button>
      <ProductReviewModal
        show={reviewModalShow}
        onHide={() => setReviewModalShow(false)}
        reviews={reviewRecord}
        productID={productID}
      />
    </div>
  );
};

const ShopInfo = ({ item }) => {
  return (
    <Col
      style={{ scrollSnapAlign: "start", scrollSnapStop: "always" }}
      className="p-0 mx-1"
    >
      <div style={{ position: "relative" }}>
        <Card.Img
          variant="top"
          data-src={item.image}
          className="productImage"
        />
      </div>
      <OverflowText text={item.description} max={46} />
    </Col>
  );
};

const Product = ({
  product,
  pInp,
  shop,
  itemPositionString,
  paymentCreditSurplus,
  isExample = false,
}) => {
  const [show, setShow] = useState(false);
  const cardEle = useRef(null);
  const history = useHistory();
  const { user } = useUser();
  let { originalPrice, specialPrice } = product?.price;

  let overCredit = useMemo(() => {
    return isOverCredit(originalPrice, specialPrice, paymentCreditSurplus);
  }, [paymentCreditSurplus]);

  product.description = product?.description?.replace(/<br>/g, "\n");

  const PriceLabel = () => {
    if (specialPrice) {
      return (
        <Stack direction="horizontal" gap={2}>
          <h6>
            <del>{originalPrice}元</del>
          </h6>
          <h6>
            <b>{specialPrice}元</b>
          </h6>
        </Stack>
      );
    }
    return (
      <h6>
        <b>{originalPrice}元</b>
      </h6>
    );
  };

  return (
    <Col
      style={{ scrollSnapAlign: "start", scrollSnapStop: "always" }}
      className="mx-1"
      xs="auto"
    >
      <div style={{ width: "414px" }}>
        {/* <Button
        onClick={() => setReviewModalShow(true)}
      >{`評論(${reviewRecord.length})`}</Button> */}
        <div style={{ position: "relative" }}>
          <Card.Img
            ref={cardEle}
            variant="top"
            data-src={product.basic.images[0]}
            className="productImage"
          />
          {pInp.popup?.start && pInp.popup?.end && (
            <PopupTag popup={pInp.popup} />
          )}
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "40%",
              width: "50px",
            }}
          >
            <Badge bg="dark">
              <strong className="text-center">{itemPositionString}</strong>
            </Badge>
          </div>
          <Card.ImgOverlay className="d-flex justify-content-end">
            <Row>
              <Col>
                <Share
                  className="p-2"
                  style={{
                    transform: "scale(1.5)",
                    color: "white",
                    zIndex: "1000",
                  }}
                />
              </Col>
            </Row>
          </Card.ImgOverlay>
        </div>

        <Card.Body className="py-0" style={{ width: "414px" }}>
          {/* product data & price */}
          <Row className="mt-3">
            <Col>
              <h5>
                <strong>{product.basic.productName}</strong>
              </h5>
              <PriceLabel />
            </Col>
            <Col xs={3}>
              {isValidSold(product) && (
                <Button
                  className="w-100"
                  variant="primary rounded-pill mb-1"
                  onClick={() => {
                    if (!user?._id) {
                      history.push(
                        `/login?redirect=/search?shopID=${shop._id}`
                      );
                      return;
                    }

                    setShow(true);
                  }}
                  size="sm"
                  disabled={isSoldOut(product)}
                >
                  {isSoldOut(product) ? "完售" : "預訂"}
                </Button>
              )}
              <ReviewButton productID={product._id} />
            </Col>
          </Row>

          <OverflowText text={product.basic.description} max={46} />
        </Card.Body>

        <ProductPayByPointModal
          show={show}
          onHide={() => setShow(false)}
          product={product}
          shop={shop}
          isExample={isExample}
        />
        {/* <CouponCatchModal
        product={product}
        shop={shop}
        show={catchShow}
        onHide={() => setCatchShow(false)}
        isExample={isExample}
      /> */}
      </div>
    </Col>
  );
};

const PaymentModal = ({ product, show, onHide }) => {
  const handlePaymentRequest = async () => {};

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      onClick={(e) => e.stopPropagation()}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Button onClick={handlePaymentRequest}>確認</Button>
      </Modal.Body>
    </Modal>
  );
};

const ShopCard = ({
  shop,
  productProposal,
  isShowCheckin = false,
  isExample = false,
}) => {
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [cashModalShow, setCashModalShow] = useState(false);
  const [isCollection, setIsCollection] = useState(false);
  const [paymentCreditSurplus, setPaymentCreditSurplus] = useState(0);
  const [storeInfoExpand, setStoreInfoExpand] = useState(false);
  const [collectionPending, setCollectionPending] = useState(false);
  const { currentLocation } = useGeoLocation();
  const { user, setUser, ownOrder } = useUser();
  const history = useHistory();
  const cardRef = useRef();
  const existCoupon = ownOrder
    .filter((e) => e.orderType === "product")
    .filter((e) => e.sellerData.seller === shop._id && e.status !== "get");
  const entry = useIntersectionObserver(cardRef, {});

  // const productProposal = shop.proposal;

  //計算營業狀態 null: 未設定, false: 營業中, true: 已打烊
  const { openTime, closeTime, closeDays } = shop.shopData;
  const { city, district, road } = shop.shopData.address;

  const closed = isClose(openTime, closeTime, closeDays);

  // In view callback
  useEffect(() => {
    const isVisiable = entry?.isIntersecting;

    if (!isVisiable) return;
    // image load
    loadImage(entry);
    // store review

    // store credit
  }, [entry]);

  useEffect(() => {
    if (!user?._id) return;

    setIsCollection(user?.storeCollections?.find((e) => e === shop._id));
  }, [user]);

  const handleCollectionProcess = async () => {
    setCollectionPending(true);

    let result = await collectionStore(shop._id);

    setUser(result);

    setCollectionPending(false);
  };

  return (
    // <Col className="shopCard" ref={cardRef}>
    <Card
      className="exposure-observerd shopCard"
      ref={cardRef}
      shop-id={shop._id}
      proposal-id={productProposal._id}
    >
      {/* Store Info */}
      <Card.Title>
        <Row className="mx-0">
          <Col xs={12}>
            <details className="storeInfo-details">
              <summary>
                {/* 店名 */}
                <Row className="justify-content-between align-items-center">
                  <Col
                    xs="auto"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/search?shopID=${shop._id}`);
                    }}
                  >
                    <h4>
                      <strong>{shop.shopData.storeName}</strong>
                    </h4>
                  </Col>
                  {isShowCheckin && (
                    <Col xs="auto">
                      {/* <Button variant="outline-primary rounded-pill">報到</Button> */}
                      <ShopQrcodeCheckin user={user} shop={shop} />
                    </Col>
                  )}
                  <Col xs="auto">
                    <FontAwesomeIcon
                      icon={storeInfoExpand ? faChevronUp : faChevronDown}
                      onClick={() => setStoreInfoExpand((pre) => !pre)}
                      className="text-muted"
                    />
                  </Col>
                </Row>
                <Row className="justify-content-around text-muted">
                  <Col>
                    <Stack direction="horizontal" gap={2}>
                      {/* 距離 */}
                      <span>
                        <small className="pr-2">
                          {!currentLocation?.lat || !currentLocation?.lng
                            ? "未定位"
                            : convertDistanceUnit(shop.distance)}
                        </small>
                      </span>
                      <span>
                        <small className="pr-2">
                          {shop.score || "無"}
                          <FontAwesomeIcon
                            icon={faStar}
                            style={{ color: "gold" }}
                          />
                        </small>
                      </span>
                      <span>
                        <i
                          onClick={(e) => {
                            //prevent open details
                            e.preventDefault();
                            if (collectionPending) return;
                            handleCollectionProcess();
                          }}
                        >
                          {isCollection ? (
                            <HeartCollection className="collection-icon" />
                          ) : (
                            <HeartUnCollection className="collection-icon" />
                          )}
                        </i>
                        {collectionPending && <Spinner animation="border" />}
                      </span>
                    </Stack>
                  </Col>

                  {/* <Col xs="auto">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => setCashModalShow(true)}
                    >
                      使用現金券
                    </Button>
                  </Col> */}
                </Row>
              </summary>
              <div className="text-muted">
                <Row>
                  <small>{city + district + road}</small>
                </Row>
                <Row>
                  <Col>
                    <small>
                      營業時間：
                      {closed === null ? (
                        <small>未知</small>
                      ) : closed ? (
                        <small>
                          {`${openTime} - ${closeTime}`}{" "}
                          <small className="text-danger">已打烊</small>
                        </small>
                      ) : (
                        <small>
                          {`${openTime} - ${closeTime}`}{" "}
                          <small className="text-success">營業中</small>
                        </small>
                      )}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <small>
                    電話：<small>{shop.shopData?.storePhone || "無"}</small>
                  </small>
                </Row>
                <Row>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() =>
                      copyShare({
                        title: shop.shopData.storeName,
                        text: `NearMe-${shop.shopData.storeName}頁面`,
                        url: `${process.env["REACT_APP_DOMAIN"]}/search?shopID=${shop._id}`,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faShareAlt} />
                    分享
                  </Button>
                </Row>
              </div>
            </details>
          </Col>
          {/* <Col className={`m-0 p-0 text-center align-self-center`} xs={4}>
            {isExample ? (
              <Button
                as="a"
                href={`https://blog.nearmespecials.com/${shop.shopData.storeName}行銷活動範例`}
                target="_blank"
              >
                提案解析
              </Button>
            ) : (
              <Link
                to={`/payment/request/${shop._id}`}
                className={`${shop.shopData.isPayment ? "" : "d-none"}`}
              >
                <Button variant="outline-primary rounded-pill">到店結帳</Button>
              </Link>
            )}
          </Col> */}
        </Row>
      </Card.Title>
      <ScrollController>
        {productProposal.products.map((product, idx) => {
          return (
            <Product
              pInp={product}
              product={product.product}
              shop={shop}
              itemPositionString={`${idx + 1}/${
                productProposal.products.length
              }`}
              paymentCreditSurplus={paymentCreditSurplus}
              key={product.product._id}
              isExample={isExample}
            />
          );
        })}
        {shop.gallery
          ?.filter((e) => e.active)
          ?.map((e) => (
            <ShopInfo item={e} key={e._id} />
          ))}
      </ScrollController>
      <br />
      <Card.Body>
        {/* Manage message& own coupon */}
        <ScrollController align="center">
          <Col xs="auto" className="ownCoupon-container scroller-item p-2">
            <strong>店長的話</strong>
            <OverflowText text={productProposal.managerMessage} max="12" />
          </Col>
          {/* {existCoupon.length > 0 && (
            <Col className="mx-1" xs="auto">
              <b style={{ opacity: "0.5" }}>已領取</b>
            </Col>
          )} */}
          {existCoupon.map((e) => (
            <OwnOrder data={e} key={e._id} />
          ))}
        </ScrollController>
      </Card.Body>
      <PaymentModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
      <CashCheckModal
        show={cashModalShow}
        onHide={() => setCashModalShow(false)}
        pickStore={shop}
      />
    </Card>
    // </Col>
  );
};

export const ShopCardWithoutProposal = ({ shop }) => {
  const { currentLocation } = useGeoLocation();
  //計算營業狀態 null: 未設定, false: 營業中, true: 已打烊
  const { openTime, closeTime, closeDays } = shop.shopData;
  const { city, district, road } = shop.shopData.address;
  const closed = isClose(openTime, closeTime, closeDays);
  return (
    <Card className="border-0 p-0 exposure-observerd shopCard shadow-sm">
      {/* Store Info */}
      <Card.Title>
        <Row className="mx-0">
          <Col xs={12}>
            <Accordion flush>
              <Accordion.Item eventKey="store-info">
                <Accordion.Header>
                  <div>
                    {/* 店名 */}
                    <Row className="justify-content-between align-items-center">
                      <Col xs="auto">
                        <h4>
                          <strong>{shop.shopData.storeName}</strong>
                        </h4>
                      </Col>
                    </Row>
                    <Row className="justify-content-around text-muted">
                      <Col>
                        {/* 距離 */}
                        {/* <small className="pr-2">
                        {!currentLocation?.lat || !currentLocation?.lng
                          ? "距離未知"
                          : convertDistanceUnit(shop.distance)}
                      </small> */}
                      </Col>
                      {/* 評分 */}
                      <Col xs="auto">
                        <small className="pr-2">
                          {shop.score || "無"}
                          <FontAwesomeIcon
                            icon={faStar}
                            style={{ color: "gold" }}
                          />
                        </small>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="text-muted">
                    <Row>
                      <small>{city + district + road}</small>
                    </Row>
                    <Row>
                      <Col>
                        <small>
                          營業時間：
                          {closed === null ? (
                            <small>未知</small>
                          ) : closed ? (
                            <small>
                              {`${openTime} - ${closeTime}`}{" "}
                              <small className="text-danger">已打烊</small>
                            </small>
                          ) : (
                            <small>
                              {`${openTime} - ${closeTime}`}{" "}
                              <small className="text-success">營業中</small>
                            </small>
                          )}
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <small>
                        電話：<small>{shop.shopData?.storePhone || "無"}</small>
                      </small>
                    </Row>
                    <Row>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        // onClick={() =>
                        //   copyShare({
                        //     title: shop.shopData.storeName,
                        //     text: `NearMe-${shop.shopData.storeName}頁面`,
                        //     url: `${process.env["REACT_APP_DOMAIN"]}/search?shopID=${shop._id}`,
                        //   })
                        // }
                      >
                        <FontAwesomeIcon icon={faShareAlt} />
                        分享
                      </Button>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Card.Title>
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "500px" }}
      >
        <Col xs="auto">
          <h5 className="text-muted">目前沒有提案可以使用</h5>
        </Col>
      </Row>
    </Card>
  );
};

const ShopThumbnail = ({ proposal }) => {
  const history = useHistory();
  let { shop, products } = proposal;

  let pInp = products[0];

  return (
    <article
      className="scroller-item shop-Thumbnail"
      onClick={() => history.push(`/search?shopID=${shop._id}`)}
    >
      <Col className="px-1">
        <div className="img-container">
          <Image rounded src={pInp.product.basic.images[0]} />
        </div>
        <Row className="p-1">
          <Col className="">
            <b>
              <strong>{shop.shopData.storeName}</strong>
            </b>
          </Col>
        </Row>
      </Col>
    </article>
  );
};

const ShopQrcodeCheckin = ({ user, shop }) => {
  const [surplusTime, setSurplusTime] = useState(null);
  const { addToast } = useToast();
  const { setUser } = useUser();

  useEffect(() => {
    if (!user._id || !shop._id) return;

    let result = user.shopPointCardRecords.find(
      (e) => e._id === shop._id && e.point > 0 && passDay(e.date) < 1
    );

    if (!result) {
      setSurplusTime(0);
      return;
    }

    let nextTime = new Date(result.date).getTime() + 1000 * 60 * 60 * 24;

    let currentTime = new Date().getTime();

    setSurplusTime(nextTime - currentTime || 0);
  }, [user, shop]);

  const handleCheckin = async () => {
    let result = await qrcodeCheckin(shop._id);

    if (!result.data) addToast(result.message, { appearance: "error" });

    setUser(result.data);
  };

  const Count = ({ seconds, onTimeUp }) => {
    const [remainSecond, setRemainSecond] = useState(seconds);

    useEffect(() => {
      let countTimer = setInterval(() => {
        setRemainSecond((pre) => pre - 1);
      }, 1000);

      if (remainSecond <= 0) {
        onTimeUp();
        clearInterval(countTimer);
      }

      return () => clearInterval(countTimer);
    }, [seconds, onTimeUp, remainSecond]);

    return (
      <h6>
        {Math.floor((remainSecond / (60 * 60)) % 60)}:
        {Math.floor((remainSecond / 60) % 60)}:{Math.floor(remainSecond % 60)}
      </h6>
    );
  };

  if (surplusTime === null) return <Spinner animation="border" />;

  return (
    <Button
      variant="outline-primary rounded-pill"
      disabled={surplusTime > 0}
      onClick={handleCheckin}
    >
      {surplusTime > 0 ? (
        <Count
          seconds={surplusTime / 1000}
          onTimeUp={() => setSurplusTime(0)}
        />
      ) : (
        "報到"
      )}
    </Button>
  );
};

export { ShopCard, ShopThumbnail };

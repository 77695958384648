import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form, Card, Stack } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useToast } from "Provider/ToastProvider";
import { useHistory } from "react-router-dom";

import { Http_request_post, Http_request_get } from "Service/HttpService";
import { useUser, useLoading } from "Provider/UserProvider";
import {
  createRecord,
  fetchRecord,
  closeRecord,
  reply,
  sendCustomerServiceEmail,
} from "./CustomerServiceUtils";
import "./CustomerService.css";

const Create = () => {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("店家註冊無登入反應");
  const { addToast } = useToast();
  const { user, setUser } = useUser();

  const sendContent = async () => {
    let result = await Http_request_post(`user/customerService/create`, {
      content,
      title,
    });

    if (result.data) {
      setUser(result.data);
      setContent("");
    }

    addToast(result.message, { appearance: result.toast });
  };
  return (
    <section>
      <h2 className="text-primary m-2">客服</h2>
      <b className="text-secondary">歡迎告訴我們問題</b>
      <br />
      <Row>
        <Col>
          <Form.Control
            as="select"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            <option value="註冊無登入反應">註冊無登入反應</option>
            <option value="投訴店家">投訴店家</option>
            <option value="領券與核銷">領券與核銷</option>
            <option value="送禮&收禮券">送禮&收禮券</option>
            <option value="其他">其他</option>
          </Form.Control>
          <br />
          <Form.Control
            as="textarea"
            value={content || ""}
            onChange={(e) => setContent(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <div className="text-center">
        <Button variant="primary rounded-pill" onClick={() => sendContent()}>
          送出
        </Button>
      </div>
    </section>
  );
};
const Exist = ({ targetRecord }) => {
  const [record, setRecord] = useState(targetRecord);
  const [content, setContent] = useState("");
  const messageBoxRef = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    fetchRecord()
      .then((records) => setRecord(records))
      .catch((_) => {});

    const interval = setInterval(() => {
      fetchRecord()
        .then((records) => setRecord(records))
        .catch((_) => {});
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleReply = async (content) => {
    try {
      let result = await reply(content);
      setRecord(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleClose = async () => {
    try {
      await closeRecord();

      history.push("/user/userInfo");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const RecordTemp = ({ message }) => {
    let isSelf = message.from === "user";

    return (
      <Row
        className={`${
          isSelf ? "justify-content-end" : "justify-content-start"
        } m-0`}
      >
        <Col
          xs={8}
          lg={6}
          className={isSelf ? "message-self" : "message-other"}
        >
          <p>{message.content}</p>
          <small>{new Date(message.date).toLocaleString()}</small>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Row className="my-2 justify-content-end">
        <Col>
          <h5>
            <b>{record?.title}</b>
          </h5>
        </Col>
        <Col xs="auto">
          <Button size="sm" onClick={handleClose}>
            問題已解決
          </Button>
        </Col>
      </Row>
      <hr />
      <section
        ref={messageBoxRef}
        style={{ height: "50vh", overflow: "scroll" }}
      >
        {record?.messages?.map((e, idx) => (
          <RecordTemp message={e} key={idx} />
        ))}
      </section>
      <Row className="align-items-center">
        <Col>
          <Form.Control
            as="textarea"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Col>
        <Col xs="auto">
          <Button
            onClick={async () => {
              await handleReply(content);
              setContent("");
            }}
          >
            回覆
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let result = await sendCustomerServiceEmail({
        email,
        name,
        content,
        phone,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Control
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        placeholder="Email"
        className="my-2"
      />
      <Form.Control
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="姓名"
        className="my-2"
      />
      <Form.Control
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder="手機"
        className="my-2"
      />
      <Form.Control
        value={content}
        onChange={(e) => setContent(e.target.value)}
        as="textarea"
        rows={5}
        placeholder="簡單描述問題，我們將盡快與您聯繫"
      />
      <br />
      <Stack>
        <Button className="mx-auto" type="submit">
          送出
        </Button>
      </Stack>
    </Form>
  );
};

export const CustomerServiceEmail = () => {
  return (
    <section>
      <h3>客服中心</h3>
      <hr />
      <EmailForm />
      <hr />
      <Row className="text-center text-secondary">
        <Col>
          <h5>聯絡資訊</h5>
          <p>史基農生技股份有限公司</p>
          <p>service@nearmespecials.com</p>
          <p>Tel: 03-3572561</p>
          <p>客服時間</p>
          <p>週一~週五 09:00~17:00</p>
        </Col>
      </Row>
    </section>
  );
};

const Customer_Service = () => {
  const [record, setRecord] = useState(null);
  const { user } = useUser();
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);

    fetchRecord()
      .then((result) => setRecord(result))
      .catch((_) => {})
      .finally(() => setLoading(false));
  }, [user]);

  return (
    <section>
      <EmailForm />
      {record ? <Exist targetRecord={record} /> : <Create />}
      <hr />
      <Row className="text-center text-secondary">
        <Col>
          <h5>聯絡資訊</h5>
          <p>史基農生技股份有限公司</p>
          <p>service@nearmespecials.com</p>
          <p>Tel: 03-3572561</p>
          <p>客服時間</p>
          <p>週一~週五 09:00~17:00</p>
        </Col>
      </Row>
    </section>
  );
};

export { Customer_Service };

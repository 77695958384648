import React, { useState, useEffect, Suspense } from "react";
import { ListGroup, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";

import { useUser } from "../../Provider/UserProvider";
import { EmptyPage } from "Components/EmptyPage";
import { fetchNotifi, readNotifi } from "./NotificationUtils";

const NotificationPage = () => {
  const [records, setRecords] = useState([]);
  const { user } = useUser();
  const history = useHistory();

  const unReadStyle = {
    backgroundColor: "#FCFCFC",
  };

  useEffect(() => {
    //當離開頁面，更改已讀
    fetchNotifi()
      .then((res) => setRecords(res))
      .catch((err) => console.log(err));

    return () => {
      if (!user._id) return;
      readNotifi();
    };
  }, []);

  const NotificationTemplate = ({ record }) => {
    return (
      <ListGroup.Item
        style={record.isRead ? {} : unReadStyle}
        onClick={() =>
          record.link &&
          history.push(record.link.replace(process.env["REACT_APP_DOMAIN"], ""))
        }
      >
        <strong>{record.title}</strong>
        <p>{record.message}</p>
        <small>{new Date(record.createdAt).toLocaleDateString()}</small>
      </ListGroup.Item>
    );
  };

  const notifications = records?.map((record) => {
    return <NotificationTemplate record={record} key={record._id} />;
  });

  if (records?.length === 0) return <EmptyPage title="目前沒有通知" />;

  return (
    <Suspense fallback={<Spinner animation="border" />}>
      <ListGroup variant="flush">{notifications}</ListGroup>
    </Suspense>
  );
};

export { NotificationPage };

import React, { useContext, useEffect, useState, createContext } from "react";

import {
  isGrantedLocation,
  getLocationPosition,
  getLocationByLatLng,
} from "Service/LocationService";
import { useToast } from "Provider/ToastProvider";

const GeoLocationContext = createContext();

export const useGeoLocation = () => useContext(GeoLocationContext);

export const GeoLocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState({
    address: null,
    lat: null,
    lng: null,
  });

  const { addToast } = useToast();

  const autoLocation = async () => {
    try {
      let { lat, lng, message } = await getLocationPosition();

      //fail
      if (!lat || !lng) return;

      //success
      let result = await getLocationByLatLng(lat, lng);

      let newAddress = {
        address: result[0].formatted_address,
        lat,
        lng,
      };

      setCurrentLocation(newAddress);

      return newAddress;
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  //auto location
  useEffect(() => {
    isGrantedLocation()
      .then((res) => {
        if (res) autoLocation();
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <GeoLocationContext.Provider
      value={{
        currentLocation,
        setCurrentLocation,
        autoLocation,
      }}
    >
      {children}
    </GeoLocationContext.Provider>
  );
};

import React, { Component } from "react";
import logo from "static/logo/logo_en.svg"
import { Container, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ErrorTemplate = ({ msg }) => {
  return (
    <Container className="d-flex justify-content-center">
      <Card style={{ width: "20rem" }}>
        <Card.Img variant="top" src={logo} />
        <Card.Body>
          <Card.Title>糟糕!發生錯誤</Card.Title>
          <Card.Text>發生錯誤!請告知客服或稍後再試</Card.Text>
          <Card.Text>{msg}</Card.Text>
          <Button
            variant="primary"
            onClick={() => (window.location.href = "/")}
          >
            回首頁
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      message: "",
    };
  }
  static getDerivedStateFromError(error) {
    console.log("catch error");
    return { hasError: true, message: error.message };
  }

  render() {
    const { hasError, message } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorTemplate msg={message} /> : children;
  }
}

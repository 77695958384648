import React from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";

export const TemporaryFixRoute = () => {
  const location = useLocation();
  const history = useHistory();

  if (location.pathname.startsWith("/index")) {
    let newPathName = location.pathname.replace("/index", "");

    newPathName = newPathName.replace("/home", "");

    if (!newPathName.startsWith("/")) newPathName = `/${newPathName}`;

    history.replace(newPathName + location.search);
  }

  return <div></div>;
};

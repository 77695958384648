import "./Progress.css";

export const StepProgress = ({steps, currentStep }) => {
  
    return (
      <div className="step-progress">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index < currentStep ? "completed" : ""} ${
              index === currentStep ? "active" : ""
            }`}
          >
            <div className="step-indicator">{index + 1}</div>
            <div className="step-label">{step}</div>
          </div>
        ))}
      </div>
    );
  };
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Form, Image, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useUser, useLoading } from "../Provider/UserProvider";
import { getLocationByName } from "../Components/Location";
import CityList from "../static/city-list.json";
import logo from "../static/logo/logo_en.svg";

export const RequestLocationPage = () => {
  const { currentPosition, getCurrentLocation, setCurrentPosition } = useUser();
  const [inputAddress, setInputAddress] = useState({
    city: "臺北市",
    district: "萬華區",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldShow, setFieldShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { setLoading } = useLoading();
  const redirectPath = new URLSearchParams(location.search).get("redirect");

  useEffect(() => {
    if (currentPosition?.lat) history.replace(redirectPath || "/");
  }, [currentPosition]);

  const handleAutoLocation = async () => {
    setLoading(true);

    let result = await getCurrentLocation();

    setLoading(false);

    if (result) {
      setErrorMsg(result);
    }
  };

  const handleAddressInput = async (e) => {
    e.preventDefault();
    if (!inputAddress.road) return;

    let completeAddress = `${inputAddress.city}${inputAddress.district}${inputAddress.road}`;

    let address = await getLocationByName(completeAddress);

    address = address[0];

    console.log(address);

    let newAddress = {
      address: address.formatted_address,
      lat: address.geometry.location.lat,
      lng: address.geometry.location.lng,
    };

    setCurrentPosition(newAddress);
  };

  return (
    <Row className="justify-content-center">
      <Col>
        <Row className="justify-content-center">
          <Image src={logo} style={{ width: "20rem" }} />
        </Row>
        {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
        <Row className="w-100 justify-content-center align-items-center">
          <Col xs="auto" lg={2}>
            <Button block onClick={() => setFieldShow(!fieldShow)}>
              手動定位
            </Button>
          </Col>
          <Col xs="auto" lg={2}>
            <Button
              variant="outline-primary"
              block
              onClick={handleAutoLocation}
            >
              自動定位
            </Button>
          </Col>
        </Row>
        <Row className={`${fieldShow ? "" : "d-none"} justify-content-center`}>
          <Form onSubmit={handleAddressInput}>
            <Col xs={10} lg={6} className="my-2">
              <Form.Control
                as="select"
                name="city"
                onChange={(e) =>
                  setInputAddress({ ...inputAddress, city: e.target.value })
                }
                className="my-1"
              >
                {Object.keys(CityList).map((city) => {
                  return (
                    <option value={city} key={city}>
                      {city}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control
                as="select"
                name="district"
                onChange={(e) =>
                  setInputAddress({ ...inputAddress, district: e.target.value })
                }
                className="my-1"
              >
                {CityList[inputAddress.city].map((district) => (
                  <option value={district} key={district}>
                    {district}
                  </option>
                ))}
              </Form.Control>
              <Form.Control
                type="text"
                name="road"
                onChange={(e) =>
                  setInputAddress({ ...inputAddress, road: e.target.value })
                }
                className="my-1"
                placeholder="地址"
                required
              />
              <Row className="justify-content-center">
                <Button className="text-center" type="submit">
                  搜尋
                </Button>
              </Row>
            </Col>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

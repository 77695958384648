// ----------------------------------------------- Refresh Process Start---------------------------------------------------------------------------------------------
let refreshPromise;

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  let [resource, config] = args;

  let response = await originalFetch(resource, config);

  if (!response.ok && response.status === 401) {
    console.log(`into 401`);
    try {
      // Check token exist
      const existToken = document.cookie.match(/userToken=/);

      if (!existToken) throw new Error("redirect to login");

      // If no other request create refresh token process
      if (!refreshPromise) {
        refreshPromise = fetch(`${domain}/login/refreshToken`, {
          method: "POST",
          headers: getHeaderJson("json"),
        });
      }

      // Wait same refresh make sure consistent new token
      const refreshRes = await refreshPromise;

      if (!refreshRes?.ok) throw new Error("refresh fail");

      // Retry original request with new token
      response = await originalFetch(resource, config);
    } catch (err) {
      console.log(err.message);
      document.cookie = `userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=.${
        window.location.hostname.match(/localhost/)?.input ??
        "nearmespecials.com"
      }`;

      if (window.location.pathname !== "/login") {
        const redirectPath = encodeURIComponent(
          window.location.pathname + window.location.search
        );
        window.location.href = `/login?redirect=${redirectPath}`;
      }
    } finally {
      // Clear refresh process
      refreshPromise = null;
    }
  }
  return response;
};
// ----------------------------------------------- Refresh Process End---------------------------------------------------------------------------------------------

// const domain = "https://d2e8c39e1e7d.ngrok.io/";
// const domain = process.env["REACT_APP_REQUEST_DOMAIN"];
const domain = window.location.origin.startsWith("http://localhost")
  ? process.env["REACT_APP_REQUEST_DOMAIN"]
  : `${window.location.origin}/api`;
const versionNum = Date.now();

const getHeaderJson = (type) => {
  let token = localStorage.getItem("token");

  let content = { Authorization: token, versionNum: versionNum };

  switch (type) {
    case "json":
      content["Content-Type"] = "application/json";
      break;

    case "formdata":
      break;

    case "text":
      content["Content-Type"] = "text/html";
      break;

    default:
      break;
  }
  return content;
};

export const Http_request_get = async (url) => {
  let res = fetch(domain + url, { headers: getHeaderJson("json") });

  return Http_callback(res);
};

export const Http_request_post = async (url, body) => {
  let res = fetch(domain + url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeaderJson("json"),
    credentials: "include",
  });

  return Http_callback(res);
};

export const Http_request_put = async (url, body) => {
  let res = await fetch(domain + url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: getHeaderJson("json"),
  });

  return Http_callback(res);
};

export const Http_request_delete = async (url, body) => {
  let headers = getHeaderJson("json");

  let res = await fetch(domain + url, {
    method: "DELETE",
    body: JSON.stringify(body),
    headers,
  });

  return Http_callback(res);
};

export const Http_request_post_form_data = async (url, body) => {
  let res = await fetch(domain + url, {
    method: "POST",
    body: body,
    headers: getHeaderJson("formdata"),
  });

  return Http_callback(res);
};

export const Http_callback = async (resPromise) => {
  try {
    let response = await resPromise;

    let resultJson = await response.json();

    // if (response.status === 401) {
    //   localStorage.removeItem("token");

    //   document.cookie =
    //     "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=.nearmespecials.com";

    //   if (window.location.pathname !== "/login")
    //     window.location.href = "/login";

    //   return;
    // }

    if (response.status < 200 || response.status >= 400) {
      throw new Error(resultJson.message);
    }

    resultJson.toast = "success";

    return resultJson;
  } catch (err) {
    return { message: err.message, toast: "error" };
  }
};

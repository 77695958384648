import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useToast } from "./ToastProvider";
import { fetchGiftInfoV2, check, changeGift } from "../User/Gift/utils";
import { useECPay } from "../Hook/useECPay";
import { useLocation, Redirect } from "react-router-dom";
import { Spinner, Stack } from "react-bootstrap";
import { EmptyPage } from "Components/EmptyPage";
import { useUrlQuery } from "Hook/useUrlQuery";
import { GiftResultStep } from "User/Gift/GiftPage";

const GiftContext = createContext();

export const useGift = () => useContext(GiftContext);

export const GiftProvider = ({ children }) => {
  const [giftInfo, setGiftInfo] = useState({
    sender: {
      id: "",
      displayName: "",
    },
    orderIDs: [],
    isHidePrice: false,
    message: "",
    pw: "",
    // Old format,
    from: {},
    gifts: [],
    changedGift: null,
  });
  const [pending, setPending] = useState(false);
  const [pickerData, setPickerData] = useState({
    name: "",
    phone: "",
    email: "",
    address: {
      city: "",
      district: "",
      road: "",
    },
    message: "",
  });
  const [paymentData, setPaymentData] = useState({
    provider: "ecpay",
  });
  const [newOrder, setNewOrder] = useState({
    products: [],
    payment: {
      provider: "ecpay",
    },
  });
  const [pickGift, setPickGift] = useState(null);
  const { addToast } = useToast();
  const { insertHTMLAndSubmit } = useECPay();
  const location = useLocation();
  const giftToken = new URLSearchParams(location.search).get("e");
  const giftSpreadDiscount = useMemo(() => {
    if (!pickGift) return null;
    return pickGift.totalProductPrice;
  }, [pickGift]);

  useEffect(() => {
    fetchGiftInfo(giftToken);
  }, [giftToken]);

  useEffect(() => {
    // pickGift
    const pickOrder = new URLSearchParams(location.search).get("pickOrder");
    if (pickOrder) {
      const pickGift = giftInfo.gifts.find((e) => e._id === pickOrder);
      setPickGift(pickGift);
    }
  }, [location.search]);

  const fetchGiftInfo = useCallback(async (giftToken) => {
    try {
      setPending(true);
      const res = await fetchGiftInfoV2(giftToken);
      let newGiftInfo = res;
      newGiftInfo.gifts = res.orderIDs;
      let { status, msg } = handleRes(newGiftInfo);
      newGiftInfo.status = status;
      newGiftInfo.msg = msg;
      setGiftInfo(newGiftInfo);
    } catch (err) {
      setGiftInfo({ status: "err", msg: err.message });
    } finally {
      setPending(false);
    }
  }, []);

  const handleCheck = useCallback(
    async (pickerData) => {
      try {
        if (giftInfo.status !== process.env["REACT_APP_COUPON_STATUS_CATCH"])
          throw new Error("禮物無法更改");
        let checkData = {
          ...pickGift,
          gift: { picker: pickerData },
        };
        let result = await check(
          giftToken,
          { pickTradeNo: pickGift.tradeNo, pw: "" },
          checkData
        );
        let newGiftInfo = { ...giftInfo };
        newGiftInfo.gifts = [result];
        const { status, msg } = handleRes(newGiftInfo);
        newGiftInfo.status = status;
        newGiftInfo.msg = msg;
        setGiftInfo(newGiftInfo);
      } catch (err) {
        addToast(err.message, { appearance: "error" });
      }
    },
    [giftInfo, pickGift, addToast]
  );

  const handleChangeGift = useCallback(
    async (pickTradeNo, changeGiftData) => {
      try {
        if (giftInfo.status !== process.env["REACT_APP_COUPON_STATUS_CATCH"])
          throw new Error("禮物無法更改");
        const payload = {
          giftToken: giftToken,
          giftParam: { pickTradeNo, pw: "" },
          changeGift: {
            ...changeGiftData,
            gift: { picker: pickerData },
            payment: paymentData,
          },
        };
        let result = await changeGift(payload);
        if (result?._id) {
          let newGiftInfo = { ...giftInfo };
          newGiftInfo.changedGift = result;
          newGiftInfo.status = "change";
          setGiftInfo(newGiftInfo);
        } else {
          insertHTMLAndSubmit(result);
        }
      } catch (err) {
        addToast(err.message, { appearance: "error" });
      }
    },
    [giftInfo, pickerData, paymentData, addToast, insertHTMLAndSubmit]
  );

  const handleRes = useCallback((res) => {
    let allStatus = res.gifts.map((e) => e.status);
    if (allStatus.includes(process.env["REACT_APP_COUPON_STATUS_CHECK"]))
      return { status: "checked", msg: "禮物已被兌換" };
    if (allStatus.includes(process.env["REACT_APP_COUPON_STATUS_EXPIRED"]))
      return { status: "expired", msg: "禮物已過期" };
    if (allStatus.includes(process.env["REACT_APP_COUPON_STATUS_REFUND"]))
      return { status: "refund", msg: "禮物失效" };
    if (allStatus.includes(process.env["REACT_APP_COUPON_STATUS_CHANGEGIFT"]))
      return { status: "change", msg: "禮物已被更改" };
    if (
      !allStatus.every(
        (e) => e === process.env["REACT_APP_COUPON_STATUS_CATCH"]
      )
    )
      return { status: "err", msg: "未知錯誤" };
    return { status: "get", msg: "可兌換" };
  }, []);

  const value = {
    giftInfo,
    setGiftInfo,
    pending,
    pickerData,
    setPickerData,
    paymentData,
    setPaymentData,
    newOrder,
    setNewOrder,
    pickGift,
    setPickGift,
    fetchGiftInfo,
    handleCheck,
    handleChangeGift,
    giftSpreadDiscount,
  };

  if (pending) {
    return (
      <Stack className="mx-auto col-2">
        <Spinner animation="border" role="status" />
      </Stack>
    );
  }

  if (giftInfo.status == "err") return <EmptyPage title={giftInfo.msg} />;

  if (!giftInfo?._id) {
    return <EmptyPage title={"找不到禮物"} />;
  }

  if (giftInfo.status == "checked") {
    return <GiftResultStep order={giftInfo.gifts[0]} isHidePrice={giftInfo.isHidePrice} />;
  }

  if (giftInfo.status == "change") {
    return <GiftResultStep order={giftInfo.changedGift} isHidePrice={giftInfo.isHidePrice} />;
  }

  return <GiftContext.Provider value={value}>{children}</GiftContext.Provider>;
};

import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Spinner, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/ToastProvider";

import { Http_request_post } from "../../Service/HttpService";
import { useUser } from "../../Provider/UserProvider";
import { EmptyPage } from "../../Components/EmptyPage";
import { gradeStore } from "./ReviewUtils";
import "./Review.css";

const reviewMessageRecommend = ["太棒了！", "體驗極佳", "有待加強", "整體不錯"];

export const ScoreTemp = ({ color, callback }) => {
  return (
    <span>
      <FontAwesomeIcon
        size="2x"
        icon={faStar}
        style={{ color }}
        onClick={callback}
      />
    </span>
  );
};

export const ReviewProduct = () => {
  const [data, setData] = useState({ score: 0, message: "" });
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const { addToast } = useToast();
  const { couponID } = useParams();
  const { ownOrder } = useUser();
  const history = useHistory();

  useEffect(() => {
    let target = ownOrder.find((e) => e._id == couponID);
    setOrder(target);
  }, [ownOrder]);

  const handleGrade = async () => {
    setLoading(true);

    try {
      let result = await gradeStore(couponID, data);

      setOrder(result);

    } catch (err) {      
      addToast(err.message, { appearance: "error" });
    } finally {
      setLoading(false);
    }
  };

  if (!order) return <EmptyPage title="找不到紀錄" />;

  return (
    <div className="text-center">
      <Row className="my-2">
        <Col>
          <Image src={order.products[0].images[0]} className="product-image" />
          <h5>
            <strong>{order.products[0].productName}</strong>
          </h5>
        </Col>
      </Row>
      {order.reviewID ? (
        <Row
          style={{ minHeight: "20vh" }}
          className="justify-content-center align-items-center"
        >
          <Col xs="auto">
            <h4 className="text-muted">
              <strong>感謝評分😊</strong>
            </h4>
            <Button onClick={() => history.replace("/")}>確認</Button>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <Row className="justify-content-around my-2">
              {[1, 2, 3, 4, 5].map((e, idx) => {
                let color = idx >= data.score ? "gray" : "gold";
                return (
                  <Col xs="auto" key={e}>
                    <ScoreTemp
                      color={color}
                      callback={() => setData({ ...data, score: parseInt(e) })}
                    />
                  </Col>
                );
              })}
            </Row>
            <br />
            <Form.Control
              type="text"
              value={data.message || ""}
              onChange={(e) => setData({ ...data, message: e.target.value })}
              placeholder="我想給建議"
            />
            <br />
            <Row className="justify-content-center">
              {reviewMessageRecommend.map((e) => (
                <Col key={e}>
                  <Button
                    variant="outline-primary"
                    className="rounded-pill mx-1"
                    onClick={() => setData({ ...data, message: e })}
                  >
                    {e}
                  </Button>
                </Col>
              ))}
            </Row>
            <br />
            <Row className="justify-content-center">
              <Col xs="auto">
                <Button onClick={handleGrade}>
                  {loading ? <Spinner animation="border" /> : "提交"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

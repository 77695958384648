export const copyToClipboard = async (content) => {
  try {
    const clipObject = navigator.clipboard;

    if (!clipObject) throw new Error("此瀏覽器不支援自動複製");

    await clipObject.writeText(content);

    return true;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const copyShare = async ({ title, text, url, files = [] }) => {
  if (!navigator.share) {
    return copyToClipboard(url);
  }

  await navigator.share({ title, text, url, files });

  return true;
};

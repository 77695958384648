import { APIGetRecords, APIReply, APIClose } from "API/customerservice";
import { Http_request_get, Http_request_post } from "Service/HttpService";

export const createRecord = async () => {};

export const fetchRecord = async () => {
  let result = await Http_request_get(APIGetRecords);

  if (!result.data) throw new Error("取得客服資料失敗");

  return result.data?.[0];
};

export const reply = async (content) => {
  let result = await Http_request_post(APIReply, { content });

  if (!result.data) throw new Error("回覆失敗");

  return result.data;
};

export const closeRecord = async () => {
  let result = await Http_request_post(APIClose);
  if (!result.data) throw new Error("關閉客服失敗");
};

export const sendCustomerServiceEmail = async (data) => {
  const response = await Http_request_post(
    `/notification/customerService`,
    data
  );

  if (!response.ok) throw new Error("發生錯誤");
  return;
};

import React, { useState, useMemo ,useRef,useEffect} from "react";
import "./OverflowText.css";

export const OverflowText = ({
  text = "",
  max = "25",
  size = "16px",
  maxLine = 3,
}) => {
  const [readMore, setReadMore] = useState(false);
  const line = useMemo(() => text.split("\n").length, [text]);
  const char = useMemo(() => text.length, [text]);
  const isOverflow = useMemo(() => {
    if (line > maxLine) return true;
  }, [line, char]);

  return (
    <p
      className="text-muted"
      style={{ fontSize: size, whiteSpace: "pre-line" }}
      onClick={(e) => {
        // e.stopPropagation();
        setReadMore(!readMore);
      }}
    >
      {readMore || text.length <= max ? text : text.substring(0, max) + "..."}
      <br />
      {text.length > max && (
        <small className="text-primary">
          {readMore ? "隱藏內容" : "了解更多"}
        </small>
      )}
    </p>
  );
};

export const OverflowTextLine = ({ text = "", maxLine = 2 }) => {
  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [displayText, setDisplayText] = useState(text);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const element = containerRef.current;
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
        const maxHeight = lineHeight * maxLine;
        
        if (element.scrollHeight > maxHeight) {
          setIsOverflowing(true);
          if (!isExpanded) {
            let truncatedText = text;
            while (element.scrollHeight > maxHeight && truncatedText.length > 0) {
              truncatedText = truncatedText.slice(0, -1);
              element.textContent = truncatedText + '...';
            }
            setDisplayText(truncatedText + '...');
          } else {
            setDisplayText(text);
          }
        } else {
          setIsOverflowing(false);
          setDisplayText(text);
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [text, maxLine, isExpanded]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div 
        ref={containerRef} 
        style={{ 
          overflow: 'hidden', 
          maxHeight: isExpanded ? 'none' : `${maxLine * 1.2}em`, 
          lineHeight: '1.2em' 
        }}
      >
        {displayText}
      </div>
      {isOverflowing && (
        <button onClick={toggleExpand} className="btn btn-link p-0">
          {isExpanded ? '收起' : '展開全文'}
        </button>
      )}
    </div>
  );
};



export const OverflowE = ({ text = "", fontSize = 18, width = "auto" }) => {
  return (
    <p className="over-ellipsis" style={{ fontSize, width }}>
      {text}
    </p>
  );
};

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const deviceType = (ua) => {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const BrowserDetectProvider = ({ children }) => {
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);

  const targetPath =
    window.location.protocol +
    window.location.host +
    location.pathname +
    location.search;

  const agent = navigator.userAgent;

  let device = deviceType(agent);

  if (device !== "desktop" || true) {
    let isSafari = /Safari/.test(agent) && !/Chrome/.test(agent);

    let isChrome = /Chrome/.test(agent);

    let isIOS = /iPhone/.test(agent);

    let isAndroid = /Linux|Android /.test(agent);

    let isLineApp = /Line/.test(agent); // Line 內建瀏覽器

    let isFbApp = /FBAV/.test(agent); // FB App 內建瀏覽器

    let isIG = /Instagram/.test(agent); //IG App 內建瀏覽器

    //If not Safari && not Chrome, then redirect to Safari or Chrome
    //在LINE裡面redirecct，即使代入任何參數都只能導向首頁！！！！
    if (isLineApp && location.pathname === "/") {
      let lineSearchParams = new URLSearchParams();

      let lineRP =
        window.location.protocol +
        window.location.host +
        location.pathname +
        "?";

      lineSearchParams.append("openExternalBrowser", "1");

      for (let [k, v] of searchParams.entries()) lineSearchParams.append(k, v);

      lineRP += lineSearchParams.toString();

      window.location.href = lineRP;

      setTimeout(() => window.close(), 100);
    }

    // if ((isFbApp || isIG) && isAndroid) {
    //   // intent:https://nearmespecials.com#Intent;end
    //   let redirectPath = `intent:${targetPath}#Intent;end`;

    //   window.location = redirectPath;

    //   window.close();
    // }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

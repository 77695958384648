import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Spinner,
  Badge,
  Table,
  Button,
  Accordion,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { useUser } from "../../Provider/UserProvider";
import { fetchShopPointCardData } from "./ShopPointCardUtils";

export const PointCard = ({ card, records }) => {
  return (
    <Card className="my-2">
      <Card.Body>
        <Row className="justify-content-between">
          <Col>
            <h5>
              {card._id.shopData.storeName || <Spinner animation="border" />}
            </h5>
          </Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col>
                <Badge variant="primary">{card.point}</Badge>
              </Col>
              <Col>
                <Accordion.Toggle
                  eventKey={card._id._id}
                  as={Button}
                  variant="link"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </Accordion.Toggle>
              </Col>
            </Row>
          </Col>
        </Row>
        <Accordion.Collapse
          eventKey={card._id._id}
          style={{ maxHeight: "50vh", overflow: "auto" }}
        >
          <Table responsive className="text-muted">
            <thead>
              <tr>
                <td>時間</td>
                <td>點數</td>
              </tr>
            </thead>
            <tbody>
              {records.map((e) => {
                return (
                  <tr key={e.date}>
                    <td>{new Date(e.date).toLocaleString()}</td>
                    <td>{e.point}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Accordion.Collapse>
      </Card.Body>
    </Card>
  );
};

export const ShopPointCardPage = () => {
  //{_id: string, shopPointCard: object, shopPointCardRecords: object}
  const [data, setData] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    fetchShopPointCardData().then((result) => setData(result.data));
  }, [user]);

  if (data === null)
    return (
      <Row className="justify-content-center">
        <Col xs="auto">
          <Spinner animation="border" />
        </Col>
      </Row>
    );
  if(data.shopPointCard.length <= 0)
  return(
    <Row className="text-muted text-center my-5">
      <Col>
        <h4>目前沒有集點卡喔</h4>
      </Col>
    </Row>
  )

  return (
    <div>
      <Accordion>
        {data.shopPointCard.map((e) => {
          let shopID = e._id._id;
          let records = data.shopPointCardRecords
            .filter((e) => e._id._id === shopID)
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            );
          return <PointCard card={e} records={records} key={shopID} />;
        })}
      </Accordion>
    </div>
  );
};

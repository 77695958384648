import React from "react";
import { Carousel, Card, Image, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

export const IPAreaSmall = ({ ipArea }) => {
  const WithoutLogo = () => {
    return (
      <div
        style={{
          backgroundColor: "#E6E6E6",
          height: "10em",
          width: "10em",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <h4 style={{ color: "black" }}>{ipArea.IPAreaData.name}</h4>
      </div>
    );
  };

  return (
    <Link to={`/ipArea?id=${ipArea._id}`}>
      <Col>
        {ipArea.logo ? (
          <Card>
            <Card.Img
              src={ipArea.logo}
              alt="logo"
              style={{
                filter: "brightness(.5)",
                width: "10rem",
                height: "10rem",
              }}
            />
            <Card.ImgOverlay className="text-white d-flex">
              <h4 className="align-self-center mx-auto">
                {ipArea.IPAreaData.name}
              </h4>
            </Card.ImgOverlay>
          </Card>
        ) : (
          <WithoutLogo />
        )}
      </Col>
    </Link>
  );
};

export const IPAreaBanners = ({ banners }) => {
  const bannersList = banners.map((e) => {
    return (
      <Carousel.Item key={e.description}>
        <Image src={e.image} alt={e.description} className="d-block w-100" />
        <Carousel.Caption>{e.description}</Carousel.Caption>
      </Carousel.Item>
    );
  });

  return <Carousel>{bannersList}</Carousel>;
};

import { APIOwnNotification, APIReadNotification } from "API/notification";
import { Http_request_get, Http_request_post } from "Service/HttpService";

export const fetchNotifi = async (pageNo = 0) => {
  let result = await Http_request_get(`${APIOwnNotification}?pageNo=${pageNo}`);
  return result.data;
};

export const readNotifi = async () => {
  await Http_request_post(APIReadNotification);
};

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";

import { IPAreaBanners } from "../../Components/IPArea/IPAreaTemplate";
import { Http_request_get } from "../../Service/HttpService";
import { ShopCard } from "../../Components/ShopCard/Shop_card";

export const IPAreaPage = () => {
  const [ipArea, setIPArea] = useState({});
  const [stores, setStores] = useState([]);
  const location = useLocation();

  useEffect(() => {
    let ipAreaID = new URLSearchParams(location.search).get("id");

    let api = `area/searchIPArea?ipAreaID=${ipAreaID}`;

    Http_request_get(api).then((result) => {
      if (!result.data) return;
      setIPArea(result.data.ipArea);
      setStores(result.data.stores);
    });
  }, []);

  const shopList = stores?.map((store) => {
    return <ShopCard shop={store} key={store._id} />;
  });

  return (
    <div>
      <Row className="justify-content-center">
        <Col lg={10}>
          <IPAreaBanners banners={ipArea.banners || []} />
        </Col>
      </Row>
      <hr />
      {ipArea?.stores?.length > 0 ? shopList : ""}
    </div>
  );
};

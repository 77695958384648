const getExpireDay = (aliveDay) => {
  try {
    let date = new Date();

    date.setDate(date.getDate() + aliveDay);

    return date.toISOString();
  } catch (err) {
    throw new Error(err);
  }
};

const passDay = (date) => {
  try {
    let lastDate = new Date(date).getTime();

    let passTime = new Date().getTime() - lastDate;

    return passTime / (1000 * 60 * 60 * 24);
  } catch (err) {
    throw new Error(err);
  }
};

//expiredDate: ISOTime
const isExpired = (expiredDate) => {
  try {
    let currentDate = new Date(Date.now()).toLocaleDateString();

    expiredDate = new Date(expiredDate).toLocaleDateString();

    if (new Date(currentDate) <= new Date(expiredDate)) return false;

    return true;
  } catch (err) {
    throw new Error(err);
  }
};

const getSurplusDay = (expiredDate) => {
  try {
    let currentTime = new Date().getTime();

    let expired = new Date(expiredDate).getTime();

    let surplusTime = expired - currentTime;

    if (surplusTime < 0) return -1;

    let surplusDay = parseInt(surplusTime / (60 * 60 * 24 * 1000));

    return surplusDay;
  } catch (err) {
    throw new Error(err);
  }
};

const isInRange = (date, startDate, endDate) => {
  try {
    date = new Date(date);

    startDate = new Date(startDate);

    endDate = new Date(endDate);

    let dateTime = new Date(date.toLocaleDateString()).getTime();

    let startTime = new Date(startDate.toLocaleDateString()).getTime();

    let endTime = new Date(endDate.toLocaleDateString()).getTime();

    return startTime <= dateTime && dateTime <= endTime;
  } catch (err) {
    throw new Error(err);
  }
};

const isClose = (openTime, closeTime, closeDays = []) => {
  if (!openTime || !closeTime) return null;

  let current = new Date();

  if (
    closeDays.includes(current.toLocaleDateString("zh-TW", { weekday: "long" }))
  )
    return true;

  let [openHour, openMinute] = openTime.split(":");

  let [closeHour, closeMinute] = closeTime.split(":");

  let openDate = new Date();
  openDate.setHours(openHour);
  openDate.setMinutes(openMinute);

  let closeDate = new Date();
  closeDate.setHours(closeHour);
  closeDate.setMinutes(closeMinute);

  return !(current > openDate && current < closeDate);
};

export { getExpireDay, isExpired, getSurplusDay, isInRange, isClose, passDay };

export const APIUserInfo = `/user`;
export const APIUpdateUserInfo = `/user/basicInfo`
export const APILogout = `/user/logout`;
export const APIUpdateUserInvoiceSetting = `/user/invoiceSetting`;
export const APILogin = `/login`;
export const APIRegister = `/login/register`;
export const APIRegisterCallback = `/login/register/callback`
export const APIStoreCollec = `/user/storeCollec`;
export const APIPaymentInfoAdd = `/user/paymentInfo`;
export const APIPaymentInfodel = `/user/paymentInfo`;
export const APIFetchOwnStores = `/shop/user/ownStores`
import {
  ScrollController,
  ScrollControllerItem,
} from "Components/ScrollController";
import { useUser } from "Provider/UserProvider";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Card, Modal, Button, Spinner, Stack } from "react-bootstrap";
import { addPaymentInfo, delPaymentInfo } from "./UserInfoUtils";
import { useToast } from "Provider/ToastProvider";
import "./UserInfo.css";

export const UserDataCards = ({
  name,
  phone,
  email,
  handleInitInsert = () => {},
  cb,
}) => {
  const { ownOrder } = useUser();

  const oRList = useMemo(() => {
    if (ownOrder.length === 0) return [];

    let cData = ownOrder
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map((e) =>
        JSON.stringify({
          name: e.customerData.name,
          email: e.customerData.email,
          phone: e.customerData?.phone,
        })
      );
    return Array.from(new Set(cData), JSON.parse).filter((e) => e.name);
  }, [ownOrder]);

  useEffect(() => {
    if (oRList.length === 0) return;

    handleInitInsert(oRList[0]);
  }, [ownOrder]);

  const UserInfoCard = ({ info }) => {
    const isPick = useMemo(
      () => JSON.stringify({ name, email, phone }) === JSON.stringify(info),
      [name, email, phone]
    );

    const handleClick = () => {
      cb({ name: info.name, email: info.email, phone: info.phone });
    };

    return (
      <Card
        className="shadow-sm"
        onClick={handleClick}
        style={{ width: "15rem", borderWidth: "3px" }}
        border={isPick && "success"}
      >
        <Card.Body>
          <Card.Title>
            <Stack direction="horizontal">{info.name}</Stack>
          </Card.Title>
          <hr />
          <Stack className="text-muted">
            <small>{info.email}</small>
            <small>{info.phone}</small>
          </Stack>
        </Card.Body>
      </Card>
    );
  };
  return (
    <ScrollController>
      {oRList.map((e) => {
        return (
          <ScrollControllerItem key={JSON.stringify(e)}>
            <UserInfoCard info={e} />
          </ScrollControllerItem>
        );
      })}
    </ScrollController>
  );
};

export const AddUserInfoModal = ({ show, onHide, cb = () => {} }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pending, setPending] = useState(false);
  const { user, setUser } = useUser();
  const { addToast } = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPending(true);
    try {
      let exist = user?.paymentInfo?.find((e) => {
        delete e._id;
        return JSON.stringify(e) === JSON.stringify({ name, email, phone });
      });

      if (exist) throw new Error("重複資料");

      let result = await addPaymentInfo({ name, email, phone });

      setUser({ ...user, paymentInfo: result });

      cb(result[result.length - 1]);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
      onHide();
    }
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!name) setName(user.name || "");
    if (!email) setEmail(user.email || "");
    if (!phone) setPhone(user.phone || "");
  }, [user]);

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <h4 className="text-muted">新增個人資料</h4>
        </Modal.Header>
        <Modal.Body>
          <Form.FloatingLabel controlId="name" label="姓名" className="my-2">
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel controlId="name" label="E-mail" className="my-2">
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              inputMode="email"
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel controlId="name" label="手機" className="my-2">
            <Form.Control
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="phone"
              inputMode="tel"
            />
          </Form.FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={pending}>
            {pending ? <Spinner animation="border" /> : "儲存"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

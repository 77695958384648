import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Table,
  ListGroup,
  Form,
  Image,
  Row,
  Col,
  Card,
  Spinner,
  Stack,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/ToastProvider";
import { useLocation, Redirect } from "react-router-dom";

import { useUser, useLoading } from "../../Provider/UserProvider";
import { getExpireDay } from "../../Service/timeUtils";
import { Http_request_post } from "../../Service/HttpService";
import {
  handleCustomerDataChanged,
  isValid,
  fetchProduct,
  ecpayReq,
  scrapeOrderEcpayReq,
} from "./PaymentUtils";
import { UserDataCards } from "Components/UserInfo/UserInfo";
import { EmptyPage } from "Components/EmptyPage";
import {
  InvoiceForm,
  PayAmountDetail,
  UserDataForm,
  DiscountCodeForm,
} from "Components/Payment/component";
import { OrderCardWithOption } from "Components/Order/ScrapeOrder";
import { useECPay } from "Hook/useECPay";
import { LoadingPage } from "Components/LoaingPage";
import { calLimitAmt, calPrice } from "Components/Product/utils";
import { PaymentNote } from "Components/Note/PaymentNote";
import { calFee } from "Components/Payment/utils";
import { useUrlQuery } from "Hook/useUrlQuery";
import { useDebounce } from "Hook/useDebounce";

export const ScrapeProductPaymentPage = () => {
  const [newOrder, setNewOrder] = useState({
    payment: {
      provider: "ecpay",
    },
  });
  const [reCalPending, setReCalPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchPending, setFetchPending] = useState(false);
  const [discountCodeErrMsg, setDiscountCodeErrMsg] = useState("");
  const { addToast } = useToast();
  const { user } = useUser();
  const { insertHTMLAndSubmit } = useECPay();
  const location = useLocation();
  const pickInfo = useMemo(() => {
    let us = new URLSearchParams(location.search);
    const pId = us.get("productId");
    const amount = parseInt(us.get("amount"));
    const spec = us.get("spec");
    return { pId, spec, amount };
  }, [location]);

  const { updateQueryParams } = useUrlQuery();

  const handleReCal = useCallback(
    async ({
      productId,
      specIndex,
      amount,
      isFreeServiceFee,
      discountCode,
    }) => {
      const calParam = {
        productId,
        specIndex,
        amount,
        isFreeServiceFee,
        discountCode,
      };
      return calFee(calParam);
    },
    []
  );

  const isValidPay = useMemo(() => true, []);
  const debounceAmount = useDebounce(pickInfo.amount);
  const debounceSpecIndex = useDebounce(pickInfo.spec);
  const debounceDiscountCode = useDebounce(newOrder.discountCode);

  const handleChangeAmount = (amt) => {
    let newProducts = [...newOrder.products];
    newProducts[0].amount = amt;
    setNewOrder((pre) => {
      return { ...pre, products: newProducts };
    });
    updateQueryParams({ amount: amt });
  };

  const handleChangeSpec = (specIndex) => {
    let newProducts = [...newOrder.products];
    newProducts[0].specIndex = parseInt(specIndex);
    setNewOrder((pre) => {
      return { ...pre, products: newProducts };
    });
    updateQueryParams({ spec: specIndex });
  };

  const handlePayRequest = async () => {
    if (!user?._id) addToast("尚未登入", { appearance: "error" });

    setLoading(true);

    try {
      if (newOrder.payment.provider === "ecpay") {
        let result = await scrapeOrderEcpayReq({
          customerData: newOrder.customerData,
          products: [
            {
              productId: newOrder.products[0].productId._id,
              specIndex: newOrder.products[0].specIndex,
              amount: newOrder.products[0].amount,
            },
          ],
          payment: newOrder.payment,
          discountCode: newOrder.discountCode,
        });

        insertHTMLAndSubmit(result);
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
      setLoading(false);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    }
  };

  // re-cal new order
  useEffect(() => {
    if (fetchPending || reCalPending || !newOrder?.products?.[0]?.productId)
      return;
    if (
      debounceAmount === newOrder.products[0].amount &&
      debounceSpecIndex === newOrder.products[0].specIndex
    )
      return;

    setReCalPending(true);

    handleReCal({
      productId: pickInfo.pId,
      specIndex: pickInfo.spec,
      amount: pickInfo.amount,
      isFreeServiceFee: false,
      discountCode: debounceDiscountCode,
    })
      .then((res) => {
        setNewOrder((pre) => {
          let newData = res;
          newData.payment = pre?.payment;
          newData.customerData = pre?.customerData;
          return newData;
        });
        // setProducts(res.products);
        // setSellerData(res.sellerData);
      })
      .catch((err) => addToast(err.message, { appearance: "error" }))
      .finally(() => setReCalPending(false));
  }, [debounceAmount, debounceSpecIndex]);

  useEffect(() => {
    setDiscountCodeErrMsg("");

    if (!debounceDiscountCode) return;

    setReCalPending(true);
    handleReCal({
      productId: pickInfo.pId,
      specIndex: pickInfo.spec,
      amount: pickInfo.amount,
      isFreeServiceFee: false,
      discountCode: debounceDiscountCode,
    })
      .then((res) => {
        setNewOrder((pre) => {
          let newData = res;
          newData.payment = pre?.payment;
          newData.customerData = pre?.customerData;
          return newData;
        });
      })
      .catch((err) => setDiscountCodeErrMsg(err.message))
      .finally(() => setReCalPending(false));
  }, [debounceDiscountCode]);

  // Init fetch new order
  useEffect(() => {
    setFetchPending(true);
    handleReCal({
      productId: pickInfo.pId,
      specIndex: pickInfo.spec,
      amount: pickInfo.amount,
      isFreeServiceFee: false,
    })
      .then((res) =>
        setNewOrder((pre) => {
          let newData = res;
          newData.payment = pre?.payment;
          newData.customerData = pre?.customerData;
          return newData;
        })
      )
      .catch((err) => addToast(err.message, { appearance: "error" }))
      .finally(() => setFetchPending(false));
  }, []);

  // init payment loading avoid back to this page and re-try payment
  useEffect(() => setLoading(false), []);

  if (fetchPending) return <LoadingPage />;

  if (!newOrder?.products?.[0]?.productId)
    return <EmptyPage title={"找不到此商品"} />;

  return (
    <div>
      <Row className="justify-content-center">
        <Col xs={12} lg={10}>
          <section id="ecpay-form"></section>
          <section className="product-detail">
            <OrderCardWithOption
              order={newOrder}
              productData={newOrder.products[0].productId}
              specCb={handleChangeSpec}
              amountCb={handleChangeAmount}
            />
            <br />
            <PaymentNote />
            <br />
            <PayAmountDetail
              pending={reCalPending}
              order={newOrder}
              // productId={products[0].productId._id}
              // amount={products[0].amount}
              // specIndex={products[0].specIndex}
            />
          </section>
          <br />
          <h3>優惠</h3>
          <hr />
          {discountCodeErrMsg && (
            <Form.Text className="text-danger">{discountCodeErrMsg}</Form.Text>
          )}
          <DiscountCodeForm
            cancelCB={() =>
              handleReCal({
                productId: pickInfo.pId,
                specIndex: pickInfo.spec,
                amount: pickInfo.amount,
                isFreeServiceFee: false,
              })
                .then((res) => {
                  setNewOrder((pre) => {
                    let newData = res;
                    newData.payment = pre?.payment;
                    newData.customerData = pre?.customerData;
                    return newData;
                  });
                })
                .catch((err) => setDiscountCodeErrMsg(err.message))
                .finally(() => setReCalPending(false))
            }
            isConfirm={newOrder._discountCode}
            cb={(data) =>
              setNewOrder((pre) => {
                return { ...pre, discountCode: data };
              })
            }
          />

          <br />
          <h3>個人資料</h3>
          <Stack>
            <small className="text-muted">
              建議輸入真實資料，避免影響權益，姓名僅予收禮者做為通知顯示
            </small>
            <small className="text-muted">非必要時，僅以E-Mail聯繫</small>
          </Stack>
          <hr />
          <UserDataForm
            // data={customerData}
            // cb={(data) => setCustomerData(data)}
            data={newOrder.customerData}
            cb={(data) =>
              setNewOrder((pre) => {
                return { ...pre, customerData: data };
              })
            }
          />

          <strong>最近使用</strong>
          <UserDataCards
            handleInitInsert={(data) => {
              let { name, phone, email } = newOrder?.customerData || {};

              if (name || phone || email) return;

              setNewOrder((pre) => {
                return { ...pre, customerData: data };
              });
            }}
            cb={(data) =>
              setNewOrder((pre) => {
                return { ...pre, customerData: data };
              })
            }
            name={newOrder.customerData?.name}
            phone={newOrder.customerData?.phone}
            email={newOrder.customerData?.email}
          />

          <br />

          <h3>發票</h3>
          <small className="text-muted">如需統一編號請選擇雲端發票</small>
          <hr />
          <InvoiceForm
            cb={(data) =>
              setNewOrder((pre) => {
                return {
                  ...pre,
                  payment: { ...pre.payment, invoiceSetting: data },
                };
              })
            }
          />
          <br />

          <h3>付款方式</h3>
          <hr />
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <ListGroup>
                <ListGroup.Item
                  onClick={() =>
                    setNewOrder((pre) => {
                      return {
                        ...pre,
                        payment: { ...pre.payment, provider: "ecpay" },
                      };
                    })
                  }
                  className="d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={faCreditCard} />
                  信用卡
                  {newOrder.payment.provider === "ecpay" ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    ""
                  )}
                </ListGroup.Item>
              </ListGroup>
              <br />
              <Button
                className="w-100 payment-btn"
                id="payment-btn"
                disabled={!isValidPay || loading}
                onClick={handlePayRequest}
              >
                前往結帳
                {loading && <Spinner animation="border" />}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const ProductOrder = ({ paymentInfo, set }) => {
  const [product, setProduct] = useState(null);
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();
  let { productID, specification, amount } = paymentInfo;

  const [totalPrice, refundPrice] = useMemo(() => {
    if (!product) return [0, 0];
    let { specialPrice, originalPrice, deposit } = product.price;
    let finalPrice = specialPrice
      ? specialPrice * amount
      : originalPrice * amount;
    let refundPrice = finalPrice - (deposit ?? 0) * amount;
    return [finalPrice, refundPrice];
  }, [product]);

  //fetch product data
  useEffect(() => {
    setPending(true);
    fetchProduct(productID)
      .then((res) => setProduct(res))
      .catch((err) => addToast(err.message, { appearance: "error" }))
      .finally(() => setPending(false));
  }, [productID]);

  if (pending) {
    return (
      <Row className="justify-content-center">
        <Col xs="auto">
          <Spinner animation="border" />
        </Col>
      </Row>
    );
  }

  if (!product) return <EmptyPage title="找不到商品" />;

  return (
    <div>
      <section id="ecpay-form"></section>
      <Row xs={1} lg={2}>
        <Col>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Image
                src={product.basic.images[0]}
                width="300rem"
                height="300rem"
                rounded
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <br />
          <Alert>
            <h6>
              兌換日期到期前可以<strong>主動退款</strong>
            </h6>
            <h6>
              超過兌換日期而未兌換則<strong>自動退款</strong>
            </h6>
          </Alert>
          <Table>
            <thead style={{ borderStyle: "hidden" }}>
              <tr>
                <th style={{ width: "30%" }}></th>
                <th style={{ width: "70%" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>商品名稱</td>
                <td>{product.basic.productName}</td>
              </tr>
              <tr>
                <td>描述</td>
                <td>{product.basic.description?.replace(/<br>/g, "\n")}</td>
              </tr>
              {product.specification.noNeedSpecification || (
                <tr>
                  <td>規格</td>
                  <td>{specification}</td>
                </tr>
              )}
              <tr>
                <td>特價</td>
                <td>
                  {product.price.specialPrice || product.price.originalPrice}
                </td>
              </tr>
              <tr>
                <td>原價</td>
                <td>{product.price.originalPrice}</td>
              </tr>
              <tr>
                <td>訂金</td>
                <td>{product.price.deposit * amount || 0}</td>
              </tr>
              <tr>
                <td>數量</td>
                <td>{paymentInfo.amount}</td>
              </tr>
              <tr>
                <td>兌換日期</td>
                <td>
                  {new Date(
                    getExpireDay(product?.options.enableAfterDay || 0)
                  )?.toLocaleDateString()}
                  ~
                  {new Date(
                    getExpireDay(
                      product.options.aliveDay +
                        (product.options.enableAfterDay || 0)
                    )
                  ).toLocaleDateString()}
                </td>
              </tr>
              <tr>
                <td>注意事項</td>
                <td>
                  {product.needBooking && (
                    <p className="text-danger">
                      !!此商品需要提前預約，請於結帳完成後，藉由憑證票劵上的預約方式進行預約
                      。
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <Row className="justify-content-between text-danger">
            <Col>
              <h5>
                <strong>可退款</strong>
              </h5>
            </Col>
            <Col xs="auto">
              <h5>
                <strong>NT${refundPrice}</strong>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-between text-primary">
            <Col>
              <h4>
                <strong>總共金額</strong>
              </h4>
            </Col>
            <Col xs="auto">
              <h5>
                <strong>NT${totalPrice}</strong>
              </h5>
            </Col>
          </Row>
          {/* <div className="text-center">
            <h4 className="text-danger">可退款金額:{refundPrice}元</h4>
            <h4 className="text-primary">總共金額:{totalPrice}元</h4>
          </div> */}
        </Col>
      </Row>
    </div>
  );
};

export const CashOrder = ({ paymentInfo }) => {
  const { credit } = paymentInfo;
  return (
    <div>
      <Row xs={1} md={2} className="justify-content-center">
        <Col>
          <Card className="shadow-sm">
            <Card.Body>
              <Row>
                <Col xs="auto" className="pl-1">
                  <Image
                    style={{ borderRadius: "15px" }}
                    width="80px"
                    height="80px"
                    src="https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/cashOrder/cashOrder.svg"
                  />
                </Col>
                <Col className="p-0">
                  <b>現金券-{credit}</b>
                  <div>
                    <small className="text-muted">
                      可當作現金使用，超過期限而未使用時，則自動退款
                    </small>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <hr />
          <Stack direction="horizontal" className="text-primary">
            <strong>合計</strong>
            <strong className="ms-auto text-black">
              $<del className="large-total-price ">{credit}</del>
            </strong>
            <strong className="mx-2">
              $<span className="large-total-price">{credit * 0.85}</span>
            </strong>
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

import { useEffect, useState } from "react";
import { Stack, Spinner, Button } from "react-bootstrap";
import { ScrapeProductSearchResult } from "Components/Product/component";
import { scrapeProduct } from "Components/Product/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useHistory } from "react-router-dom";
import {
  faExclamationTriangle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useErrorMsg } from "Components/ErrorMsg/ErrorMsg";
import { EmptyPage } from "Components/EmptyPage";
import { ScrapeLoading } from "Components/LoaingPage";

export const ProductPage = () => {
  const [pending, setPending] = useState(false);
  const [product, setProduct] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const errMsg = useErrorMsg();

  useEffect(() => {
    if (!id) return;
    errMsg.setMsg("");
    setPending(true);
    scrapeProduct(id)
      .then((data) => setProduct(data))
      .catch((err) => errMsg.setMsg(err.message))
      .finally(() => setPending(false));
  }, [id]);

  if (pending) return <ScrapeLoading />;

  if (errMsg.msg)
    return (
      <Stack className="text-center" gap={2}>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="2x"
          className="text-primary mx-auto"
        />
        <strong>{errMsg.msg}</strong>
        <Stack className="mx-auto col-md-4">
          <Button onClick={() => history.goBack()}>返回</Button>
        </Stack>
      </Stack>
    );

  if (!product) return <EmptyPage title={"找不到商品"} />;

  return (
    <Stack className="col-md-8 mx-auto">
      <ScrapeProductSearchResult
        data={product}
        handlePay={(info) => {
          const { productData, pickSpec, amount } = info;
          const qObj = {
            productId: productData._id,
            spec: pickSpec,
            amount,
          };
          let q = new URLSearchParams(qObj);

          history.push(`/user/payment/scrapeOrder/request?${q.toString()}`);
        }}
      />
    </Stack>
  );
};

import {
  Http_request_post,
  Http_request_get,
  Http_request_put,
} from "../../Service/HttpService";
import { APICouponCatch, APICouponCheck } from "API/coupon";
import {
  APICheck,
  APICheckCash,
  APIRefund,
  APITransferCheck,
  APITransferInfo,
  APITransferReq,
} from "API/order";
import { APISearchCOStore } from "API/recommend";

export const checkOrder = async (tradeNo) => {
  let result = await Http_request_put(APICheck, {
    tradeNo,
    newStatus: process.env["REACT_APP_COUPON_STATUS_CHECK"],
  });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const checkCash = async (tradeNo, newStatus, seller) => {
  let result = await Http_request_put(APICheckCash, {
    tradeNo,
    newStatus,
    seller,
  });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const refundCash = async (tradeNo, newStatus) => {
  let result = await Http_request_put(APICheckCash, {
    tradeNo,
    newStatus,
  });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const catchCoupon = async (pw, pointDistribute) => {
  try {
    let res = await Http_request_post(APICouponCatch, { pw, pointDistribute });

    return res;
  } catch (err) {}
};

export const avaliableShopCard = (user, shop) => {
  if (!user) return [];

  let coBrandedList = shop.coBrandedData.stores || [];

  let ownCard = user.shopPointCard.filter((e) => {
    let shopID = e._id._id;
    return coBrandedList.find((j) => j.store == shopID) || shopID == shop._id;
  });

  return ownCard;
};

export const isValidPointDistribute = (reqPoint, pointDistribute) => {
  let totalPoint = 0;

  pointDistribute.forEach((e) => (totalPoint += e.point));

  if (totalPoint < reqPoint) return false;

  return true;
};

export const refundCoupon = async (ID) => {
  let payload = { tradeNo: ID, newStatus: "refund" };

  let result = await Http_request_put(APIRefund, payload);

  if (!result.data) throw new Error("退款錯誤");

  return result.data;
};

export const fetchPaymentRecords = async (ID) => {
  let api = `payment/query/${ID}`;

  let result = await Http_request_get(api);

  if (!result.data) throw new Error("訂單查詢失敗");

  return result.data;
};

export const fetchShopDataByshopID = async (shopID) => {
  let api = `area/searchStore/shopData?shopID=${shopID}`;

  let result = await Http_request_get(api);

  if (!result.data) throw new Error("無法取得店家資訊");

  return result.data;
};

export const getCatchStatusMessage = (catchStatus) => {
  let result = { title: "", variant: "", disabled: true };

  switch (catchStatus) {
    case "enable":
      result.title = "領取";
      result.variant = "primary";
      result.disabled = false;
      break;
    case "draw":
      result.title = "未中獎";
      result.variant = "secondary";
      break;

    case "checked":
      result.title = "查看券";
      result.variant = "primary";
      break;
  }

  return result;
};

export const getCouponStatus = (couponStatus) => {
  let result = { title: "", variant: "" };

  switch (couponStatus) {
    case process.env["REACT_APP_COUPON_STATUS_CATCH"]:
      result.title = "可使用";
      result.variant = "success";
      break;
    case process.env["REACT_APP_COUPON_STATUS_EXPIRED"]:
      result.title = "已過期";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_DRAW"]:
      result.title = "未中獎";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_CHECK"]:
      result.title = "已核銷";
      result.variant = "primary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_REFUND"]:
      result.title = "已退款";
      result.variant = "danger";
      break;
  }
  return result;
};

export const requestGiftUrl = async (orderID) => {
  let result = await Http_request_post(APITransferReq, { orderID });

  if (!result.data) throw new Error(result.message);

  let url = `${process.env["REACT_APP_DOMAIN"]}?giftToken=${result.data}&openExternalBrowser=1`;

  return url;
};

export const queryCOstore = async (q) => {
  let result = await Http_request_get(`${APISearchCOStore}?q=${q}`);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchGiftInfo = async (giftToken) => {
  let result = await Http_request_post(APITransferInfo, { giftToken });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const checkGift = async (token, paramter) => {
  let result = await Http_request_post(APITransferCheck, { token, paramter });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

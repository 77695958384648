import { Order } from "../Order/Order_page";
import { OrderStorage } from "../OrderStorage/OrderStoragePage";
import { NotificationPage } from "../Notification/NotificationPage";
import { User_info } from "../User_info/User_info";
import { Point } from "../User_info/Point_page";
import { AccountInfo } from "../User_info/Account/Account.Info";
import { Registration_Form } from "../User_info/Registration_Form_Page";
import { Customer_Service } from "../User_info/CustoemrService/Customer_Service";
import { ShopPointCardPage } from "../ShopPointCard/ShopPointCardPage";
import { ReviewProduct } from "../Review/Review";
import { GiftReqPage, GiftResPage } from "User/Gift/GiftPage";
import { ScrapeProductPaymentPage } from "User/Payment/PaymentPage";
import { PaymentResultPage } from "User/Payment/PaymentResult";

export const routes = [
  {
    path: "/user/userInfo",
    component: User_info,
    breadcrumbName: "使用者資訊",
    routes: [
      {
        path: "/user/userInfo/order",
        component: Order,
        breadcrumbName: "order",
        exact: true,
      },
      {
        path: "/user/userInfo/point",
        component: Point,
        exact: true,
      },
      {
        path: "/user/userInfo/registration_form",
        component: Registration_Form,
        exact: true,
      },
      {
        path: "/user/userInfo/customer_service",
        component: Customer_Service,
        exact: true,
      },
      {
        path: "/user/userInfo/account_information",
        component: AccountInfo,
        exact: true,
      },
      {
        path: "/user/userInfo/shop_pointCard",
        component: ShopPointCardPage,
        exact: true,
      },
    ],
  },
  {
    path: "/user/order",
    component: Order,
    breadcrumbName: "訂單",
    exact: true,
  },
  {
    path: "/user/orderStorage",
    component: OrderStorage,
    breadcrumbName: "持有劵",
    exact: true,
  },
  {
    path: "/user/notification",
    component: NotificationPage,
    breadcrumbName: "通知",
    exact: true,
  },
  {
    path: "/user/review/product/:couponID",
    component: ReviewProduct,
    breadcrumbName: "評價",
    exact: true,
  },
  {
    path: "/user/gift/req",
    component: GiftReqPage,
    breadcrumbName: "發送禮物",
    exact: true,
  },
  {
    path: "/user/gift/res",
    component: GiftResPage,
    breadcrumbName: "接收禮物",
    exact: true,
  },
  {
    path: "/user/payment/scrapeOrder/request",
    component: ScrapeProductPaymentPage,
    breadcrumbName: "payment",
  },
  {
    path: "/user/payment/result/order/:paymentID",
    component: PaymentResultPage,
    breadcrumbName: "PaymentResult",
  },
];

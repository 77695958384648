import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Http_request_post } from "../../Service/HttpService";
import { useLoading } from "../../Provider/UserProvider";
import { APILogin } from "API/user";

export const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSendEmail, setIsSendEmail] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let result = await Http_request_post(APILogin, { email });

    setMessage(result.message);

    if (result.data) setIsSendEmail(true);
  };

  return (
    <Card className="shadow-sm" as={Col} lg={8} xs={12}>
      {message.trim() === "" ? "" : <Alert variant="warning">{message}</Alert>}
      <Card.Body>
        <Card.Text className="text-primary">
          <h4>忘記密碼</h4>
        </Card.Text>
        <Form onSubmit={handleSubmit}>
          <Form.Label>請輸入您註冊的信箱</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required="true"
          />
          <Row className="justify-content-center mt-3">
            {isSendEmail ? (
              <h4 className="text-primary">已發送</h4>
            ) : (
              <Button type="submit">重設密碼</Button>
            )}
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const ForgetPasswordCallback = () => {
  const [errMessage, setErrMessage] = useState("發生錯誤");
  const [isFail, setIsFail] = useState(null);
  const [isPwShow, setIsPwShow] = useState(false);
  const [newPw, setNewPw] = useState("");
  const location = useLocation();
  const { setLoading } = useLoading();

  useEffect(() => {
    let encrypted = location.search.split("?token=")[1];

    if (!encrypted) return;

    setLoading(true);

    Http_request_post("register/forgetPassword/callback", { encrypted }).then(
      (result) => {
        setLoading(false);

        if (!result.data) {
          setErrMessage(result.message);
          return;
        }
      }
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPw.trim() === "") return;

    let encrypted = location.search.split("?token=")[1];

    if (!encrypted) return;

    setLoading(true);

    let result = await Http_request_post("register/forgetPassword/callback", {
      encrypted,
      newPassword: newPw,
    });

    setLoading(false);

    if (!result.data) {
      setErrMessage(result.message);
      setIsFail(true);
      return;
    }

    setIsFail(false);
  };

  const SuccessTemp = () => {
    return (
      <section className="text-center">
        <p className="text-primary">已成功更改密碼，請重新登入。</p>
        <br />
        <Link to="/login">
          <Button variant="outline-primary">返回登入</Button>
        </Link>
      </section>
    );
  };

  const FailTemp = () => {
    return (
      <section className="text-center">
        <p className="text-primary">{errMessage}</p>
        <small className="text-muted">如果持續失敗，請聯絡客服</small>
        <br />
        <Link to="/login/forgetpassword">
          <Button>返回忘記密碼</Button>
        </Link>
      </section>
    );
  };

  return (
    <Card className="shadow-sm" as={Col} lg={8} xs={12}>
      <Card.Body>
        {isFail === false ? <SuccessTemp /> : ""}
        {isFail === true ? <FailTemp /> : ""}
        <Form
          onSubmit={handleSubmit}
          className={isFail === null ? "" : "d-none"}
        >
          <Form.Label>新密碼</Form.Label>
          <Row className="m-0">
            <Form.Control
              type={isPwShow ? "text" : "password"}
              value={newPw}
              onChange={(e) => setNewPw(e.target.value)}
              required="true"
            />
            <FontAwesomeIcon
              className="ml-n4 mt-3"
              icon={isPwShow ? faEye : faEyeSlash}
              onClick={() => setIsPwShow(!isPwShow)}
            />
          </Row>
          <Row className="justify-content-center mt-3">
            <Button type="submit">確定</Button>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
